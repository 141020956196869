import React from 'react';
import { NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next'

function SidebarNavLink({ exact, to, tag, icon, name }) {
  const { t } = useTranslation();

  return (
    <NavLink exact={exact} to={to} tag={tag}>
      <i className={icon} />
      {t(name)}
    </NavLink>
  );
}

export default SidebarNavLink;