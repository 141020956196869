function stateColor(state) {
  switch (state) {
    case 'NEW':
      return 'info'
    case 'INSUFFICIENT':
      return 'default'
    case 'ANALYZED':
      return 'warning'
    case 'REPORTED':
      return 'success'
    default:
      return 'secondary'
  }
}

export default stateColor;