const routes = [
  {
    path: '/',
    name: 'home',
    icon: 'fas fa-tachometer-alt',
    allowed: ['admin'],
  },
  {
    path: '/cases',
    name: 'cases',
    icon: 'far fa-folder-open',
    allowed: [],
  },
  {
    path: '/detection',
    name: 'detection',
    icon: 'fas fa-crosshairs',
    allowed: [],
  },
  {
    path: '/finder',
    name: 'finder',
    icon: 'fas fa-search',
    allowed: ['admin'],
  },
  {
    path: '/tasks',
    name: 'tasks',
    icon: 'fas fa-tasks',
    allowed: ['admin'],
  },
  {
    path: '/jobs',
    name: 'jobs',
    icon: 'fas fa-briefcase',
    allowed: ['admin'],
  }
];
export default routes;
