import React from 'react';
import PropTypes from 'prop-types';
import { CardTitle, Input } from 'reactstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const GET_STATES = gql`
  query {
    getEnum(enumName: "states") {
      enumName
      items {
        key
        displayName
        value
      }
    }
  }
`;

const GET_SUSPICIOUS_CASE_STATE = gql`
  query suspiciousCaseState($id: ID!) {
    suspiciousCase(id: $id) {
      id
      state
    }
  }
`;

const UPDATE_STATE_MUTATION = gql`
  mutation updateState($suspiciousCaseId: ID!, $state: String!) {
    updateState(id: $suspiciousCaseId, state: $state) {
      id
      state
    }
  }
`;

function EditableSuspiciousCaseState({ suspiciousCaseId }) {
  const { t } = useTranslation();
  const { data: statesData } = useQuery(GET_STATES);
  const states = statesData?.getEnum?.items;
  const { data, loading } = useQuery(GET_SUSPICIOUS_CASE_STATE, {
    variables: {
      id: suspiciousCaseId
    }
  });
  const [updateState, { loading: updateStateLoading }] = useMutation(
    UPDATE_STATE_MUTATION
  );

  const handleStateChange = async e => {
    try {
      await updateState({
        variables: {
          suspiciousCaseId,
          state: e.target.value
        }
      });
      toast.success(t('Case updated'));
    } catch (err) {
      toast.error(t('Case update failed'));
    }
  };

  return (
    <div>
      <CardTitle tag="h5" className=" text-muted">
        <span>{t('state')}</span>
      </CardTitle>
      <div className="d-flex align-items-center">
        <Input
          className="form-control-alternative"
          type="select"
          name="state"
          id="state"
          onChange={handleStateChange}
          value={data?.suspiciousCase?.state}
          disabled={updateStateLoading || loading}
        >
          <option key="" value="" />
          {states &&
            states.map(s => (
              <option key={s.key} value={s.key}>
                {t(`suspicious_case_state_${s.displayName.toLowerCase()}`)}
              </option>
            ))}
        </Input>
        {updateStateLoading && (
          <div className="ml-2">
            <i className="fas fa-spinner fa-spin" />
          </div>
        )}
      </div>
    </div>
  );
}

EditableSuspiciousCaseState.propTypes = {
  suspiciousCaseId: PropTypes.string.isRequired
};

export default EditableSuspiciousCaseState;
