export const passwordStrength = (password) => {
  //if textBox is empty
  if(!password || password.length==0){
      return "weak";
  }

  //Regular Expressions
  var regex = new Array();
  regex.push("[A-Z]"); //For Uppercase Alphabet
  regex.push("[a-z]"); //For Lowercase Alphabet
  regex.push("[0-9]"); //For Numeric Digits
  regex.push("[.$@$!%*#?&_-]"); //For Special Characters

  var passed = 0;

  //Validation for each Regular Expression
  for (var i = 0; i < regex.length; i++) {
      if((new RegExp (regex[i])).test(password)){
          passed++;
      }
  }

  //Validation for Length of Password
  if(passed > 2 && password.length > 8){
      passed++;
  }

  //Display of Status
  var passwordStrength = "";
  switch(passed){
      case 0:
          passwordStrength = "weak";
          break;
      case 1:
          passwordStrength = "weak";
          break;
      case 2:
          passwordStrength = "good";
          break;
      case 3:
          passwordStrength = "good";
          break;
      case 4:
          passwordStrength = "better";
          break;
      case 5:
          passwordStrength = "strong";
          break;
  }

  return passwordStrength;
}

export const isPasswordStrong = (password) => {
  const strength = passwordStrength(password)
  if (strength === "strong") {
    return true;
  }
  return false;
}