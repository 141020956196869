import React from 'react';
import Chatbox from './Chatbox';
import { useLocation } from 'react-router-dom';


function isNumeric(str) {
  if (typeof str !== 'string') return false; // we only process strings!
  return (
    // eslint-disable-next-line no-restricted-globals
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

function parsePath(path) {
  const tokens = path.split('/');
  if (tokens.length !== 3) {
    return null;
  }
  if (tokens[0] !== '') {
    return null;
  }
  if (!['detection'].includes(tokens[1])) {
    return null;
  }
  if (!isNumeric(tokens[2])) {
    return null;
  }
  return {
    entity: tokens[1],
    id: tokens[2]
  };
}

function ChatboxWrapper(props) {
  const { pathname } = useLocation();
  const parsedPath = parsePath(pathname);

  if (!parsedPath) {
    return null;
  }

  return (
    <Chatbox />
  );
}

export default ChatboxWrapper;