import React from 'react';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  Label
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

function TasksHeader() {
  const { t } = useTranslation();

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="12">
                <Card className="card-stats mb-4 mb-xl-0 bg-secondary">
                  <CardBody>
                    <Row>
                      <Col lg="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label"
                            htmlFor="input-username"
                          >
                            {t('task id')}
                          </Label>
                          <Input
                            id="taskId"
                            name="taskId"
                            className="form-control-alternative"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label"
                            htmlFor="input-username"
                          >
                            {t('job id')}
                          </Label>
                          <Input
                            id="jobId"
                            name="jobId"
                            className="form-control-alternative"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label"
                            htmlFor="input-username"
                          >
                            {t('status')}
                          </Label>
                          <Input
                            id="status"
                            name="status"
                            className="form-control-alternative"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label"
                            htmlFor="input-username"
                          >
                            {t('next group')}
                          </Label>
                          <Input
                            id="nextGroupId"
                            name="nextGroupId"
                            className="form-control-alternative"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        lg="2"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <UncontrolledDropdown>
                          <DropdownToggle
                            caret
                            role="button"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            {t('more')}
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={e => e.preventDefault()}
                            >
                              {t('save filter as')}
                            </DropdownItem>
                            <DropdownItem
                              onClick={e => e.preventDefault()}
                            >
                              {t('load filter')}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <Button
                          color="primary"
                          type='submit'
                          onClick={e => e.preventDefault()}
                          className="text-uppercase"
                        >
                          {t('filter', { context: 'verb' })}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default TasksHeader;
