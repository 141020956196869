import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useAccount } from 'utils/useAccount';
import Comment from 'components/Comment';

function CommentList({ comments }) {
  const endOfComments = useRef(null);
  const { account } = useAccount();

  React.useEffect(() => {
    if (endOfComments.current) {
      endOfComments.current.scrollIntoView();
    }
  }, [comments]);

  return (
    <ul className="list-group list-group-flush">
      {comments &&
        comments.map(comment => (
          <Comment
            comment={comment}
            orientation={account?.id === comment?.author?.id ? 'right' : 'left'}
          />
        ))}
      <div ref={endOfComments} />
    </ul>
  );
}

CommentList.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      author: PropTypes.shape({
        avatarUrl: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired
      }).isRequired,
      updatedAt: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired
};

export default CommentList;
