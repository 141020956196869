import React from 'react';
import { Card, Container, Row, Col } from 'reactstrap';
import DetectionDetailHeader from 'components/Headers/DetectionDetailHeader';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import findBestMatch from 'utils/findBestMatch';
import * as _ from 'lodash';
import CaseMedallion from 'components/CaseMedallion';
import { useTranslation } from 'react-i18next';
import MatchGallery from 'components/MatchGallery';
import CaseImageGallery from 'components/CaseImageGallery';
import NotFound from 'components/NotFound';
import isNotFoundError from 'utils/isNotFoundError';

function getSubjectId(extraAttributes = []) {
  const found = extraAttributes.find(i => i?.name === 'VIN');
  if (found) {
    return found.value;
  }
  return 'n/a';
}

function getInsuranceType(extraAttributes = []) {
  const found = extraAttributes.find(i => i?.name === 'Risk Type');
  if (found) {
    return found.value;
  }
  return 'n/a';
}

const GET_SUSPICIOUS_CASE_QUERY = gql`
  query suspiciousCase($id: ID!) {
    suspiciousCase(id: $id) {
      id
      similarity
      reportUrl
      scenario {
        id
        title
        description
      }
      comments {
        id
        text
        author {
          id
          username
          name
          avatarUrl
        }
      }
      queryCase {
        id
        claimId
        productFamily
        damageAmount
        createdAt
        reportedAt
        accidentAt
        insuranceCompany {
          id
          name
          thumbnailUrl
        }
        extraAttributes {
          name
          value
        }
        metadata {
          name
          value
        }
      }
      matchCase {
        id
        claimId
        damageAmount
        createdAt
        reportedAt
        accidentAt
        insuranceCompany {
          id
          name
          thumbnailUrl
        }
        extraAttributes {
          name
          value
        }
        metadata {
          name
          value
        }
      }
      matches {
        id
        queryImage {
          id
          filename
          thumbnailUrl
        }
        matchImage {
          id
          filename
          thumbnailUrl
        }
        similarity
        distance
      }
    }
  }
`;

const CREATE_COMMENT_MUTATION = gql`
  mutation createComment(
    $commentedEntityId: ID!
    $entityType: String!
    $text: String!
  ) {
    comment(
      commentedEntityId: $commentedEntityId
      entityType: $entityType
      text: $text
    ) {
      id
      text
      author {
        id
        username
        name
      }
      updatedAt
      edited
    }
  }
`;

const GENERATE_REPORT = gql`
  mutation generatereport($entity: String!, $id: String!) {
    generateReport(input: { entity: $entity, id: $id }) {
      result
    }
  }
`;

function DetectionDetail() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { loading, error, data, refetch } = useQuery(
    GET_SUSPICIOUS_CASE_QUERY,
    {
      variables: { id }
    }
  );

  const [createCommentMutation] = useMutation(CREATE_COMMENT_MUTATION);
  const [
    generateReportMutation,
    { loading: generateReportLoading, error: generateReportError }
  ] = useMutation(GENERATE_REPORT, {
    variables: {
      entity: 'suspiciousCase',
      id
    }
  });

  const bestMatch = data?.suspiciousCase?.matches
    ? findBestMatch(data.suspiciousCase.matches)
    : null;
  let { matches } = data?.suspiciousCase || [];
  const { queryCase } = data?.suspiciousCase || {};
  const { matchCase } = data?.suspiciousCase || {};
  matches = _.orderBy(matches, ['similarity'], ['desc']);
  const { scenario } = data?.suspiciousCase || {};
  const suspiciousCaseComments = data?.suspiciousCase?.comments;
  const reportUrl = data?.suspiciousCase?.reportUrl;

  const handlerGenerateReport = async () => {
    try {
      await generateReportMutation();
      toast.success(t('report_generated'));
      refetch();
    } catch {
      toast.error(t('failed_to_generate_report'));
    }
  };

  const handleCommentSubmit = async (commentedEntityId, entityType, text) => {
    try {
      await createCommentMutation({
        variables: {
          commentedEntityId,
          entityType,
          text
        }
      });
      toast.success(t('Commented'));
      refetch();
    } catch (err) {
      toast.error(t('Failed to comment'));
      throw new Error(`failed to create comment`);
    }
  };

  if (error && isNotFoundError(error)) {
    return <NotFound showGoBackButton />;
  }

  return (
    <>
      <DetectionDetailHeader
        id={id}
        loading={loading}
        error={error}
        similarity={data?.suspiciousCase?.similarity}
        bestMatch={bestMatch?.similarity}
        matches={matches.length}
        scenario={scenario}
        suspiciousCaseComments={suspiciousCaseComments}
        onCommentSubmit={handleCommentSubmit}
        matchCaseId={matchCase?.id}
        generateReport={handlerGenerateReport}
        generateReportLoading={generateReportLoading}
        generateReportError={generateReportError}
        reportUrl={reportUrl}
        claimId={data?.suspiciousCase?.queryCase?.claimId}
        productFamily={data?.suspiciousCase?.queryCase?.productFamily}
      />
      <Container className="mt--7" fluid>
        <Row className="detection-detail__sticky-medaillon-wrapper">
          <Col>
            <CaseMedallion
              id={queryCase?.id}
              loading={loading}
              error={error}
              claimId={queryCase?.claimId}
              damageAmount={queryCase?.damageAmount}
              reportedAt={queryCase?.reportedAt}
              accidentAt={queryCase?.accidentAt}
              logoUrl={queryCase?.insuranceCompany?.thumbnailUrl}
              metadata={queryCase?.metadata}
              subjectId={getSubjectId(queryCase?.extraAttributes)}
              insuranceType={getInsuranceType(queryCase?.extraAttributes)}
            />
          </Col>
          <Col>
            <CaseMedallion
              id={matchCase?.id}
              loading={loading}
              error={error}
              claimId={matchCase?.claimId}
              damageAmount={matchCase?.damageAmount}
              reportedAt={matchCase?.reportedAt}
              accidentAt={matchCase?.accidentAt}
              logoUrl={matchCase?.insuranceCompany?.thumbnailUrl}
              metadata={matchCase?.metadata}
              subjectId={getSubjectId(matchCase?.extraAttributes)}
              insuranceType={getInsuranceType(matchCase?.extraAttributes)}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <h1 className="text-center text-capitalize">{t('matches')}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <MatchGallery
              loading={loading}
              error={error}
              matches={matches}
              nOfColumns={1}
              onCommentSubmit={handleCommentSubmit}
              insurerName={queryCase?.insuranceCompany?.name}
              counterpartyInsurerName={matchCase?.insuranceCompany?.name}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <h1 className="text-center text-capitalize">{t('gallery')}</h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Card className="d-flex p-3 flex-row flex-wrap justify-content-center">
              {queryCase?.id && <CaseImageGallery caseId={queryCase?.id} />}
            </Card>
          </Col>
          <Col>
            <Card className="d-flex p-3 flex-row flex-wrap justify-content-center">
              {matchCase?.id && <CaseImageGallery caseId={matchCase?.id} />}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DetectionDetail;
