import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function DimmerArea({ onAreaClick, children, noPadding }) {
  return (
    <div
      className={classnames('dimmer fade-in', {
        'p-0': noPadding
      })}
      onClick={onAreaClick}
    >
      {children}
    </div>
  );
}

DimmerArea.propTypes = {
  onAreaClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  noPadding: PropTypes.bool
};

DimmerArea.defaultProps = {
  noPadding: false
};

export default DimmerArea;
