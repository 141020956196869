import React, { useState } from 'react';
import { Table, Badge, Progress, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import numberToPercentage from 'utils/numberToPercentage';
import FormattedTimestamp from 'components/FormattedTimestamp';
import badgeColorByTaskStatus from 'utils/badgeColorByTaskStatus';
import { useTranslation } from 'react-i18next';
import stringToColor from 'utils/stringToColor';
import FormattedPercentage from 'components/FormattedPercentage';
import TaskDetailModal from 'components/TaskDetailModal';

function TasksTable({ items }) {
  const { t } = useTranslation();
  const [focusedTask, setFocusedTask] = useState(null);

  return (
    <>
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">{t('id')}</th>
          <th scope="col">{t('job id')}</th>
          <th scope="col">{t('service name')}</th>
          <th scope="col">{t('status')}</th>
          <th scope="col">{t('progress')}</th>
          <th scope="col">{t('updated at')}</th>
          <th scope="col">{t('group')}</th>
          <th scope="col">{t('next group')}</th>
        </tr>
      </thead>
      <tbody>
        {items && items.length === 0 && (
          <tr>
            <td colSpan="7" className="text-center">
              <h4>{t('nothing to display')}</h4>
            </td>
          </tr>
        )}
        {items.map(item => (
          <tr
            key={item.id}
            style={{
              backgroundColor: item.groupId
                ? `rgba(${stringToColor(item.groupId.toString())}, 0.7)`
                : null
            }}
          >
            <td>
                <Button color="info" onClick={() => setFocusedTask(item)}>
                  <i className="fas fa-search text-white" />
                </Button>
            </td>
            <td>
              <Link to={`/jobs/${item.jobId}`}>{item.jobId}</Link>
            </td>
            <td>{item.serviceName}</td>
            <td>
              <Badge color="" className="badge-dot mr-4">
                <i className={badgeColorByTaskStatus(item.status)} />
                {item.status}
              </Badge>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <span className="mr-2" style={{ width: '3rem' }}>
                  <FormattedPercentage value={item.progress} />
                </span>
                <div>
                  <Progress
                    max="100"
                    value={numberToPercentage(item.progress)}
                    barClassName="bg-gradient-info"
                  />
                </div>
              </div>
            </td>
            <td>
              <FormattedTimestamp
                timestamp={item.updatedAt}
                format="YYYY-MM-DD hh:mm"
              />
            </td>
            <td>{item.groupId}</td>
            <td>{item.nextGroupId ? item.nextGroupId : '-'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    {
      focusedTask && (<TaskDetailModal isOpen={focusedTask ? true : false} toggle={() => setFocusedTask(null)} task={focusedTask}/>)
    }
    </>
  );
}

TasksTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      jobId: PropTypes.string.isRequired,
      serviceName: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      updatedAt: PropTypes.string.isRequired,
      groupId: PropTypes.string.isRequired,
      nextGroupId: PropTypes.string
    })
  ).isRequired
};

export default TasksTable;
