import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';

function Feedback({ feedback: { falsePositive, toInvestigate, isFraud } }) {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-row">
      <Badge color={falsePositive ? 'info' : 'secondary'}>{t('false positive')}</Badge>
      <Badge color={toInvestigate ? 'info' : 'secondary'}>{t('to investigate')}</Badge>
      <Badge color={isFraud ? 'info' : 'secondary'}>{t('is fraud')}</Badge>
    </div>
  );
}

Feedback.propTypes = {
  feedback: PropTypes.shape({
    falsePositive: PropTypes.bool.isRequired,
    toInvestigate: PropTypes.bool.isRequired,
    isFraud: PropTypes.bool.isRequired
  })
}

export default Feedback;