import React, { useEffect, useState, useCallback } from 'react';
import { Card, CardHeader, CardFooter, Container, Row } from 'reactstrap';
import CasesHeader from 'components/Headers/CasesHeader';
import CaseTable from 'components/Tables/CaseTable';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loader from 'components/Loader';
import paginationOptions from 'utils/paginationOptions';
import Pagination from 'components/Pagination';
import { useFilters } from 'utils/useFilters';
import { useTranslation } from 'react-i18next';
import timezoneOffset from 'utils/timezoneOffset';
import { getQueryStringParams, setQueryStringParams } from 'utils/queryString';
import isEmpty from 'utils/isEmpty';
import filterChanged from 'utils/filterChanged';
import resetPaginationTokens from 'utils/resetPaginationTokens';
import tokensDefined from 'utils/tokensDefined';

const GET_CASES = gql`
  query cases(
    $productFamily: String!
    $filter: CaseFilter
    $pagination: Pagination
  ) {
    cases(
      productFamily: $productFamily
      filter: $filter
      pagination: $pagination
    ) {
      items {
        id
        claimId
        productFamily
        createdAt
        images {
          id
        }
        insuranceCompany {
          id
          name
          thumbnailUrl
        }
      }
      paginationInfo {
        nextToken
        previousToken
      }
    }
  }
`;

const GET_INSURANCE_COMPANIES = gql`
  query getInsuranceCompanies {
    insuranceCompanies {
      items {
        id
        name
        thumbnailUrl
      }
    }
  }
`;

function createQueryVars(filterValues = {}) {
  return {
    productFamily: filterValues.productFamily || 'cars',
    filter: {
      from: filterValues.from
        ? `${filterValues.from}T:00:00:000${timezoneOffset(new Date())}`
        : undefined,
      to: filterValues.to
        ? `${filterValues.to}T:23:59:000${timezoneOffset(new Date())}`
        : undefined,
      claimId: filterValues.claimId || undefined,
      insurerId: filterValues.insurerId || undefined
    }
  };
}

function Cases() {
  const { t } = useTranslation();
  const {
    cachePagination,
    getCachedPagination,
    cacheFilter,
    getCachedFilter
  } = useFilters();
  const [pagination, setPagination] = useState(
    getCachedPagination('cases') || {
      limit: paginationOptions()[0].value
    }
  );
  const { data, loading, error, refetch } = useQuery(GET_CASES, {
    variables: {
      ...createQueryVars(getQueryStringParams()),
      pagination
    }
  });
  const { data: insuranceCompaniesData } = useQuery(GET_INSURANCE_COMPANIES);
  const insuranceCompanies = insuranceCompaniesData?.insuranceCompanies?.items;

  const cleanRefetch = useCallback(() => {
    const queryVars = createQueryVars(getQueryStringParams());
    // this checks if filter has changed, and if yes, remove tokens from pagination to assure list will start from beginning
    if (
      filterChanged(getCachedFilter('cases'), getQueryStringParams()) &&
      tokensDefined(pagination)
    ) {
      setPagination(resetPaginationTokens(pagination));
      return;
    }
    refetch({
      ...queryVars,
      pagination
    });
    cacheFilter('cases', getQueryStringParams());
    // eslint-disable-next-line
  }, [cacheFilter, pagination, refetch]);

  const handleFilterClick = () => {
    cleanRefetch();
  };

  useEffect(() => {
    cleanRefetch();
  }, [cleanRefetch, pagination]);

  useEffect(() => {
    if (isEmpty(getQueryStringParams()) && !isEmpty(getCachedFilter('cases'))) {
      setQueryStringParams(getCachedFilter('cases'));
      cleanRefetch();
    }
  });

  const handlePaginationChange = ({ name, value }) => {
    const newPagination = {
      limit: pagination.limit,
      [name]: value
    };
    cachePagination('cases', newPagination);
    setPagination(newPagination);
  };

  const { nextToken, previousToken } = data?.cases?.paginationInfo || {};
  const { limit } = pagination;

  return (
    <>
      <CasesHeader
        handleFilterClick={handleFilterClick}
        insuranceCompanies={insuranceCompanies}
        loading={loading}
      />
      <Container className="mt--7" fluid>
        <Row className="mb-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  <span className="text-capitalize">{t('cases')}</span>
                </h3>
              </CardHeader>
              {error && (
                <div className="dimmer--loader">
                  <div className="dimmer--loader__content">
                    <h5>{t('failed to load data')}</h5>
                  </div>
                </div>
              )}
              {loading && <Loader title={t('loading data')} />}
              {data && (
                <CaseTable
                  items={
                    data && data.cases && data.cases.items
                      ? data.cases.items
                      : []
                  }
                  loading={loading}
                  error={error}
                />
              )}
              <CardFooter>
                <Pagination
                  limit={limit}
                  nextToken={nextToken}
                  previousToken={previousToken}
                  limitOptions={paginationOptions()}
                  onChange={handlePaginationChange}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Cases;
