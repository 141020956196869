import React, { useState } from 'react';
import { Modal, Button, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CREATE_FINDER_QUERY = gql`
  mutation createFinderQuery($name: String!) {
    createFinderQuery(input: { name: $name }) {
      id
      name
    }
  }
`;

function AddFinderQueryButton() {
  // modal controls
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  // input controls
  const defaultValues = {
    finderQueryName: ''
  };

  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [, setCreateQueryError] = useState(null);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const resetValues = () => {
    setValues(defaultValues);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    resetValues();
  };

  const [createFinderQuery] = useMutation(CREATE_FINDER_QUERY);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    const { finderQueryName } = values;

    setIsLoading(true);

    try {
      const { data } = await createFinderQuery({
        variables: { name: finderQueryName }
      });
      setIsLoading(false);
      setCreateQueryError(null);
      setModalOpen(false);
      history.push(`/finder/${data.createFinderQuery.id}`);
    } catch (error) {
      setIsLoading(false);
      setCreateQueryError(error);
      resetValues();
    }
  };

  return (
    <>
      <Button color="success" onClick={toggleModal}>
        <span>{t('new finder query')}</span>
      </Button>
      {modalOpen && (
        <Modal
          className="modal-dialog-centered"
          isOpen={modalOpen}
          toggle={toggleModal}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t('create new finder query')}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <Form onSubmit={handleOnSubmit}>
            <div className="modal-body">
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      id="finderQueryName"
                      name="finderQueryName"
                      className="form-control-alternative"
                      placeholder={t('my new finder query name')}
                      type="text"
                      onChange={handleInputChange}
                      value={values.finderQueryName}
                      autoFocus
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
              >
                {t('cancel')}
              </Button>
              <Button
                color="success"
                disabled={isLoading}
                type="button"
                onClick={handleOnSubmit}
              >
                {!isLoading ? t('create query') : `${t('creating')}...`}
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
}

export default AddFinderQueryButton;
