/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'react';
import { Card, Container, Row, Col, Button } from 'reactstrap';
import CaseDetailHeader from 'components/Headers/CaseDetailHeader';
import { useParams, useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import ImagePreview from 'components/ImagePreview';
import ModelResultsTable from 'components/ModelResultsTable';
import { useTranslation } from 'react-i18next';
import CaseDetailImageGallery from 'components/CaseDetailImageGallery';
import ModalWithNavigation from 'components/ModalWithNavigation';
import previousIndex from 'utils/previousIndex';
import nextIndex from 'utils/nextIndex';
import modelResultsToTableWithCategory from 'utils/modelResultsToTableWithCategory';
import modelResultsToLabeledBoundingBoxes from 'utils/modelResultsToLabeledBoundingBoxes';
import ImageWithHighlightedArea from 'components/ImageWithHighlightedArea';
import stringToColor from 'utils/stringToColor';
import FormattedPercentage from 'components/FormattedPercentage';
import extractFilename from 'utils/extractFilename';
import i18next from 'i18next';
import NotFound from 'components/NotFound';
import isNotFoundError from 'utils/isNotFoundError';
import qs from 'query-string';

const GET_CASE = gql`
  query case($id: ID!) {
    case(id: $id) {
      id
      claimId
      productFamily
      damageAmount
      createdAt
      insuranceCompany {
        id
        name
      }
      extraAttributes {
        name
        value
      }
      metadata {
        name
        value
      }
      images {
        id
        imageUrl
        dimensions {
          width
          height
        }
        filename
        thumbnailUrl
        container {
          id
          url
          type
        }
      }
    }
  }
`;

const GET_IMAGE_MODEL_RESULTS = gql`
  query getImageModelResults($id: ID!) {
    image(id: $id) {
      id
      modelResults {
        name
        results {
          label
          score
          box {
            x1
            x2
            y1
            y2
          }
        }
      }
    }
  }
`;

function CaseDetail() {
  const { id } = useParams();
  const history = useHistory();
  const { photoIdx } = qs.parse(history.location.search);
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_CASE, {
    variables: { id }
  });

  const focusedImageIndex = !isNaN(parseInt(photoIdx, 10))
    ? parseInt(photoIdx, 10)
    : null;
  const setFocusedImageIndex = useCallback(
    idx => {
      history.replace({
        pathname: history.location.pathname,
        search: `photoIdx=${idx}`,
        state: history.location.state
      });
    },
    [history]
  );

  const [displayFocusedImageInModal, setDisplayFocusedImageInModal] = useState(
    false
  );
  const [highlightedAreas, setHighlightedAreas] = useState([]);
  const [getImageModelResults, { data: modelResultsData }] = useLazyQuery(
    GET_IMAGE_MODEL_RESULTS
  );

  const c = data ? data.case : null;
  const images = c ? c.images : [];
  const claimId = data?.case?.claimId;
  const aggregation = c?.extraAttributes;
  const insurerName = c?.insuranceCompany?.name;
  const metadata = c?.metadata;

  // after every gql query, set focusedPictureId
  useEffect(() => {
    if (c && images.length > 0 && focusedImageIndex === null) {
      setFocusedImageIndex(0);
    }
  }, [c, data, focusedImageIndex, images.length, setFocusedImageIndex]);

  useEffect(() => {
    if (focusedImageIndex !== null && images.length > 0) {
      getImageModelResults({
        variables: {
          id: images[focusedImageIndex].id
        }
      });
    }
  }, [focusedImageIndex, getImageModelResults, images]);

  useEffect(() => {
    if (!isNaN(focusedImageIndex) && modelResultsData?.image?.modelResults) {
      const bboxes = modelResultsToLabeledBoundingBoxes(
        modelResultsData?.image?.modelResults
      );
      const newHighlightedAreas = bboxes
        ? bboxes.map(bbox => ({
            id: `${bbox.model}:${bbox.label}`,
            name: `${bbox.label}`,
            percentage: bbox.score,
            shape: 'rect',
            relativeCoords:
              bbox && bbox.box
                ? [bbox.box.x1, bbox.box.y1, bbox.box.x2, bbox.box.y2]
                : null,
            preFillColor: `rgba(${stringToColor(
              `${bbox.model}:${bbox.label}`
            )}, 0.5)`,
            fillColor: `rgba(${stringToColor(
              `${bbox.model}:${bbox.label}`
            )}, 0.75)`,
            strokeColor: `rgba(${stringToColor(
              `${bbox.model}:${bbox.label}`
            )})`,
            lineWidth: 1,
            enabled: false
          }))
        : [];
      setHighlightedAreas(newHighlightedAreas);
    }
  }, [focusedImageIndex, data, modelResultsData]);

  const handleThumbnailClick = idx => {
    setFocusedImageIndex(idx);
  };

  const handleHighlightedAreaToggle = areaId => {
    const updatedAreas = highlightedAreas.map(area => ({
      ...area,
      enabled: area.id === areaId ? !area.enabled : area.enabled
    }));
    setHighlightedAreas(updatedAreas);
  };

  const handleShowAllToggle = () => {
    const visibleAreas = highlightedAreas.filter(area => area.enabled === true);
    if (visibleAreas.length === highlightedAreas.length) {
      const allAreas = highlightedAreas.map(area => ({
        ...area,
        enabled: false
      }));
      setHighlightedAreas(allAreas);
    } else {
      const allAreas = highlightedAreas.map(area => ({
        ...area,
        enabled: true
      }));
      setHighlightedAreas(allAreas);
    }
  };

  const damageAmount = c?.damageAmount;
  const createdAt = c?.createdAt;
  const focusedImage = !isNaN(focusedImageIndex)
    ? images[focusedImageIndex]
    : null;
  const focusedImageUrl = focusedImage ? focusedImage.imageUrl : null;
  const focusedImageFilename = focusedImage ? focusedImage?.filename : null;
  const previousImageIndex = previousIndex(focusedImageIndex);
  const nextImageIndex = nextIndex(focusedImageIndex, images.length);
  const tableizedModelResults = modelResultsData?.image?.modelResults
    ? modelResultsToTableWithCategory(modelResultsData?.image?.modelResults)
    : [];
  const visibleAreas = highlightedAreas.filter(area => area.enabled === true);

  if (error && isNotFoundError(error)) {
    return <NotFound showGoBackButton />;
  }

  return (
    <>
      <CaseDetailHeader
        error={error}
        loading={loading}
        claimId={claimId}
        damageAmount={damageAmount}
        createdAt={createdAt}
        isChecked={false}
        isModelError={false}
        isExifError={false}
        aggregation={aggregation}
        insurerName={insurerName}
        metadata={metadata}
        productFamily={data?.case?.productFamily}
      />
      <Container className="mt--6" fluid>
        {!isNaN(focusedImageIndex) && (
          <Row>
            <Col>
              <Row>
                <Col className="d-flex flex-column mt-4" sm="12" lg="6" xl="6">
                  {focusedImage && (
                    <ImagePreview
                      imageUrl={focusedImage.thumbnailUrl}
                      loading={loading}
                      error={error}
                      onClick={() => setDisplayFocusedImageInModal(true)}
                      showNavigation
                      onLeftClick={
                        previousImageIndex !== null
                          ? () => setFocusedImageIndex(previousImageIndex)
                          : null
                      }
                      onRightClick={
                        nextImageIndex !== null
                          ? () => setFocusedImageIndex(nextImageIndex)
                          : null
                      }
                    />
                  )}
                </Col>
                <Col
                  className="d-flex flex-column mt-4 overflow-hidden position-relative"
                  sm="12"
                  lg="6"
                  xl="6"
                >
                  {!tableizedModelResults ||
                  tableizedModelResults.length === 0 ? null : (
                    <ModelResultsTable
                      loading={loading}
                      error={error}
                      modelFamilyName="model_results"
                      icon="far fa-eye"
                      iconColor="bg-info"
                      modelResults={tableizedModelResults}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Row className="mt-4">
          <Col>
            <Row>
              <Col>
                <Card className="p-3">
                  <Row>
                    <Col className="d-flex flex-row flex-wrap justify-content-center">
                      <CaseDetailImageGallery
                        loading={loading}
                        images={images}
                        onThumbnailClick={handleThumbnailClick}
                        maxDisplayed={5}
                        error={error}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {displayFocusedImageInModal && (
        <ModalWithNavigation
          showNavigation
          onLeftClick={
            previousImageIndex !== null
              ? () => setFocusedImageIndex(previousImageIndex)
              : null
          }
          onRightClick={
            nextImageIndex !== null
              ? () => setFocusedImageIndex(nextImageIndex)
              : null
          }
          onClose={() => setDisplayFocusedImageInModal(false)}
          title={`${focusedImageFilename} (${focusedImageIndex + 1}/${
            images.length
          })`}
          postTitle={
            focusedImage?.container
              ? `${i18next.t('found in')} ${extractFilename(
                  focusedImage?.container?.url
                )}`
              : ''
          }
        >
          <div className="modal-body modal-max-width modal-80-width pl-5 pr-5">
            <Row>
              <Col>
                <div className="d-flex justify-content-between">
                  <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                    <input
                      className="custom-control-input"
                      id="customCheck5"
                      type="checkbox"
                      checked={
                        visibleAreas.length === highlightedAreas.length &&
                        highlightedAreas.length > 0
                      }
                      disabled={highlightedAreas.length < 1}
                      onClick={() => handleShowAllToggle()}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck5"
                    >
                      {t('show bounding boxes')}
                    </label>
                  </div>
                  <div>
                    <a
                      href={`${focusedImageUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="link">{t('open in new tab')}</span>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {highlightedAreas.map(area => (
                  <Button
                    type="button"
                    className="mb-2 ml-0"
                    onClick={() => handleHighlightedAreaToggle(area.id)}
                    style={{
                      backgroundColor: area.enabled && area.strokeColor
                    }}
                  >
                    {area.name} <FormattedPercentage value={area.percentage} />
                  </Button>
                ))}
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card className="shadow">
                  <ImageWithHighlightedArea
                    imageUrl={focusedImageUrl}
                    originalImageWidth={focusedImage.dimensions.width}
                    originalImageHeight={focusedImage.dimensions.height}
                    highlightedAreas={visibleAreas}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </ModalWithNavigation>
      )}
    </>
  );
}

export default CaseDetail;
