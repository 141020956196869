import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ImageGallery from 'components/ImageGallery';
import { useAccount } from 'utils/useAccount';

const DEFAULT_PAGINATION = 6;

const GET_CASE_IMAGES = gql`
  query getCaseImages($caseId: ID!, $limit: Int, $devMode: Boolean!) {
    case(id: $caseId) {
      id
      images(limit: $limit) {
        id
        filename
        imageUrl
        thumbnailUrl
        dimensions {
          width
          height
        }
        modelResults @include(if: $devMode) {
          name
          results {
            label
            score
            box {
              x1
              y1
              x2
              y2
            }
          }
        }
      }
    }
  }
`;

function CaseImageGallery({ caseId }) {
  const { devMode } = useAccount();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const { data, loading, error } = useQuery(GET_CASE_IMAGES, {
    variables: {
      caseId,
      limit: pagination,
      devMode: devMode() || false
    }
  });
  return (
    <ImageGallery
      loading={loading}
      error={error}
      items={data?.case?.images || []}
      className="d-flex p-3 flex-row flex-wrap justify-content-center"
      pagination={pagination}
      onShowMoreClick={() =>
        setPagination(prevState =>
          prevState === null ? DEFAULT_PAGINATION : null
        )
      }
    />
  );
}

CaseImageGallery.propTypes = {
  caseId: PropTypes.string.isRequired
};

export default React.memo(CaseImageGallery);
