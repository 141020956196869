import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge } from 'reactstrap';
import stateColor from 'utils/stateColor';

function SuspiciousCaseState({ state }) {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-row">
      {state ? (
        <Badge color={stateColor(state)}>
          {t(`suspicious_case_state_${state.toLowerCase()}`)}
        </Badge>
      ) : (
        '-'
      )}
    </div>
  );
}

SuspiciousCaseState.propTypes = {
  state: PropTypes.string.isRequired
};

export default SuspiciousCaseState;
