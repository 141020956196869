import React, { useState } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Table,
  Modal
} from 'reactstrap';
import FinderDetailHeader from 'components/Headers/FinderDetailHeader';
import { useQuery } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import findBestMatch from 'utils/findBestMatch';
import CroppedImage from 'components/CroppedImage';
import ImageWithHighlightedArea from 'components/ImageWithHighlightedArea';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import labelScoreToHighlightedArea from 'utils/labelScoreToHighlightedArea';
import FormattedPercentage from 'components/FormattedPercentage';

const GET_FINDER_QUERY_QUERY = gql`
  query finderQuery($id: ID!) {
    finderQuery(id: $id) {
      id
      name
      status
      definition {
        id
        image {
          imageUrl
          dimensions {
            width
            height
          }
        }
        queryBox {
          label
          score
          box {
            x1
            x2
            y1
            y2
          }
        }
      }
      results {
        id
        matchCase {
          claimId
        }
        matchResult {
          label
          score
          box {
            x1
            y1
            x2
            y2
          }
        }
        similarity
        matchImage {
          imageUrl
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const FinderDetail = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_FINDER_QUERY_QUERY, {
    variables: { id }
  });
  const [focusedResult, setFocusedResult] = useState(null);

  const finderQuery = data?.finderQuery;
  const status = finderQuery?.status;
  if (status === 'DRAFT') {
    history.replace(`/finder/${id}/edit`);
  }

  const name = data?.finderQuery?.name;
  const results = data?.finderQuery?.results;
  const definition = data?.finderQuery?.definition;
  const queryBox = data?.finderQuery?.definition?.queryBox;
  const noOfMatches = results ? results.length : 0;
  const noOfCases = data?.finderQuery.results.length;
  const bestMatch = results && findBestMatch(results);
  const bestMatchSimilarity = bestMatch && bestMatch.similarity;
  const queryImageHighlightedArea = queryBox
    ? labelScoreToHighlightedArea(queryBox)
    : null;
  const matchImageHighlightedArea = focusedResult?.matchResult
    ? labelScoreToHighlightedArea(focusedResult.matchResult)
    : null;

  return (
    <>
      <FinderDetailHeader />
      <Container className="mt--5" fluid>
        <Row>
          <Col className="col-xl-8 offset-xl-2">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="mt-2">
                  {definition && definition.image && (
                    <CroppedImage
                      maxWidth={300}
                      maxHeight={300}
                      imageUrl={definition.image.imageUrl}
                      originalImageWidth={definition.image.dimensions.width}
                      originalImageHeight={definition.image.dimensions.height}
                      croppedArea={{
                        x1: definition.queryBox.box.x1,
                        y1: definition.queryBox.box.y1,
                        x2: definition.queryBox.box.x2,
                        y2: definition.queryBox.box.y2
                      }}
                      center
                      shadow
                    />
                  )}
                </Col>
              </Row>
              <CardBody className="pt-0 pt-md-4">
                <Row className="mt-7">
                  <div className="col">
                    <div className="text-center">
                      <h3>{name}</h3>
                    </div>
                    <div className="card-profile-stats d-flex justify-content-center">
                      <div>
                        <span className="heading">{noOfMatches}</span>
                        <span className="description">{t('matches')}</span>
                      </div>
                      <div>
                        <span className="heading">{noOfCases}</span>
                        <span className="description">{t('cases')}</span>
                      </div>
                      <div>
                        <span className="heading">
                          <FormattedPercentage value={bestMatchSimilarity} />
                        </span>
                        <span className="description">{t('best match')}</span>
                      </div>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <h1 className="text-center">{t('found results')}</h1>
          </Col>
        </Row>
        <Row className="mb-4">
          <div className="col">
            <Card className="shadow">
              {loading && <Loader title={t('loading data')} />}
              {error && (
                <Loader title={t(error.message.replace(':', ''))} error />
              )}
              <CardHeader className="border-0">
                <h3 className="mb-0 text-capitalize">{t('finder')}</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t('id')}</th>
                    <th scope="col">{t('match case id')}</th>
                    <th scope="col">{t('similarity')}</th>
                    <th scope="col">{t('picture')}</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {results &&
                    results.map(result => {
                      const { matchImage, matchResult } = result;

                      return (
                        <tr key={result.id}>
                          <td>{result.id}</td>
                          <td>{result.matchCase.claimId}</td>
                          <td>
                            <FormattedPercentage value={result.similarity} />
                          </td>
                          <td>
                            <div className="avatar-group">
                              <a
                                id="tooltip226319315"
                                onClick={() => setFocusedResult(result)}
                              >
                                <CroppedImage
                                  maxWidth={100}
                                  imageUrl={matchImage.imageUrl}
                                  originalImageWidth={
                                    matchImage.dimensions.width
                                  }
                                  originalImageHeight={
                                    matchImage.dimensions.height
                                  }
                                  croppedArea={{
                                    x1: matchResult.box.x1,
                                    y1: matchResult.box.y1,
                                    x2: matchResult.box.x2,
                                    y2: matchResult.box.y2
                                  }}
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      {focusedResult && (
        <Modal
          className="modal-dialog-centered modal--fullscreen max-width-80vw"
          isOpen={focusedResult}
          toggle={() => setFocusedResult(null)}
        >
          <div className="modal-header">
            <h5 className="modal-title text-capitalize" id="exampleModalLabel">
              {t('found match')}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFocusedResult(null)}
            >
              <span aria-hidden>×</span>
            </button>
          </div>
          <div className="modal-body modal-max-width">
            <Row>
              <Col xs="6">
                <Card>
                  <ImageWithHighlightedArea
                    imageUrl={definition.image.imageUrl}
                    originalImageWidth={definition.image.dimensions.width}
                    originalImageHeight={definition.image.dimensions.height}
                    highlightedAreas={
                      queryImageHighlightedArea
                        ? [queryImageHighlightedArea]
                        : null
                    }
                  />
                </Card>
              </Col>
              <Col xs="6">
                <Card>
                  <ImageWithHighlightedArea
                    imageUrl={focusedResult.matchImage.imageUrl}
                    originalImageWidth={
                      focusedResult.matchImage.dimensions.width
                    }
                    originalImageHeight={
                      focusedResult.matchImage.dimensions.height
                    }
                    highlightedAreas={
                      matchImageHighlightedArea
                        ? [matchImageHighlightedArea]
                        : null
                    }
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" />
        </Modal>
      )}
    </>
  );
};

export default FinderDetail;
