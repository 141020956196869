function findBestMatch(matches) {
  if (!matches || matches.length === 0) {
    return null;
  }
  return matches.reduce((prev, current) => {
    return prev.similarity > current.similarity ? prev : current;
  });
}

export default findBestMatch;
