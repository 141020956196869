import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import CaseNavigation from 'components/CaseNavigation';
import { useTranslation } from 'react-i18next';
import AggregationModal from 'components/AggregationModal';
import MetadataModal from 'components/MetadataModal';
import Skeleton from 'react-loading-skeleton';
import Loader from 'components/Loader';
import FormattedTimestamp from 'components/FormattedTimestamp';
import { useAccount } from 'utils/useAccount';
import FoundInDetection from 'components/FoundInDetection';

function getCaseIdFromUrl(url) {
  const tokens = url.split('/');
  return tokens[2];
}

function CaseDetailHeader({
  claimId,
  damageAmount,
  createdAt,
  aggregation,
  loading,
  error,
  insurerName,
  metadata,
  productFamily
}) {
  const location = useLocation();
  const { account } = useAccount();
  const { t } = useTranslation();
  const [showAggregation, setShowAggregation] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);
  const currentCaseId = getCaseIdFromUrl(location.pathname);
  const filteredCaseIds =
    location.state && location.state.filteredCaseIds
      ? location.state.filteredCaseIds
      : [];

  return (
    <>
      <div className="header bg-gradient-info pb-6 pt-5 pt-md-8">
        <Container fluid>
          <CaseNavigation
            currentCaseId={currentCaseId}
            filteredCaseIds={filteredCaseIds}
          />
          <div className="header-body">
            {error ? (
              <Row>
                <Col xs="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Loader
                        title={t(error.message.replace(':', ''))}
                        error
                        position="relative"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row className="mt-4">
                <Col lg="4" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardHeader className="d-flex flex-row justify-content-between align-items-center case-detail-header__card">
                      <div>
                        <h6 className="mb-0 text-muted text-lowercase">
                          {t('claim id')}
                        </h6>
                        <h2 className="mb-0 lh-100">
                          {loading ? <Skeleton /> : claimId}
                        </h2>
                        <h6>
                          {insurerName && account?.settings?.devMode
                            ? `${insurerName}`
                            : null}
                        </h6>
                      </div>
                      <div className="icon icon-shape bg-info text-white rounded-circle shadow mb-0">
                        <i className="far fa-file-alt" />
                      </div>
                    </CardHeader>
                    <CardBody className="pt-0_1 h-6">
                      <table>
                        <tr>
                          <td>
                            <span className="h5 text-muted text-lowercase">
                              {t('createdAt')}:
                            </span>
                          </td>
                          <td>
                            <span className="h5 ml-1">
                              {loading ? (
                                <Skeleton />
                              ) : (
                                <FormattedTimestamp timestamp={createdAt} />
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="h5 text-muted text-lowercase">
                              {t('damage')}:
                            </span>
                          </td>
                          <td>
                            <span className="h5 ml-1 text-lowercase">
                              {loading ? (
                                <Skeleton />
                              ) : (
                                damageAmount || t('n/a')
                              )}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardHeader className="d-flex flex-row justify-content-between align-items-center case-detail-header__card">
                      <div>
                        <h2 className="mb-0 text-capitalize">
                          {t('analysis')}
                        </h2>
                      </div>
                      <div className="icon icon-shape bg-yellow text-white rounded-circle shadow mb-0">
                        <i className="fas fa-search" />
                      </div>
                    </CardHeader>
                    <CardBody className="pt-0_1 h-6">
                      <table>
                        <tr>
                          <td>
                            <span className="h5 text-muted text-lowercase">
                              {t('aggregation')}:
                            </span>
                          </td>
                          <td className="pl-2">
                            {loading ? (
                              <Skeleton />
                            ) : !aggregation ? (
                              <span
                                id="scenario"
                                className="h3 font-weight-bold mb-0"
                              >
                                {t('aggregation not calculated yet')}
                              </span>
                            ) : (
                              <span
                                className="show-comments-text text-lowercase"
                                onClick={() => setShowAggregation(true)}
                              >
                                {t('show aggregations')}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="h5 text-muted text-lowercase">
                              {t('found in detection')}:
                            </span>
                          </td>
                          <td className="pl-2">
                            <FoundInDetection
                              claimId={claimId}
                              productFamily={productFamily}
                              parentLoading={loading}
                            />
                          </td>
                        </tr>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardHeader className="d-flex flex-row justify-content-between align-items-center case-detail-header__card">
                      <div>
                        <h2 className="mb-0 d-inline">{t('details')}</h2>
                        {metadata && metadata.length > 3 && (
                          <span
                            className="show-comments-text ml-2 text-lowercase"
                            onClick={() => setShowMetadata(true)}
                          >
                            {t('show all')}
                          </span>
                        )}
                      </div>
                      <div className="icon icon-shape bg-default text-white rounded-circle shadow mb-0">
                        <i className="fa fa-info" />
                      </div>
                    </CardHeader>
                    <CardBody className="pt-0_1 h-6">
                      {(!metadata || metadata.length) == 0 && (
                        <h5>{t('nothing to display')}</h5>
                      )}
                      <table>
                        {metadata &&
                          metadata.map((m, i) =>
                            i < 3 ? (
                              <tr>
                                <td>
                                  <span className="h5 text-muted text-lowercase">
                                    {m.name}:
                                  </span>
                                </td>
                                <td>
                                  <span className="h5">{m.value}</span>
                                </td>
                              </tr>
                            ) : null
                          )}
                      </table>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <i className="fas fa-chart-pie" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col> */}
                {/* <Col lg="6" xl="3">
                  <FoundInDetectionCard
                    claimId={claimId}
                    productFamily={productFamily}
                    parentLoading={loading}
                  />
                </Col> */}
              </Row>
            )}
          </div>
        </Container>
      </div>
      {showAggregation && (
        <AggregationModal
          aggregation={aggregation}
          onClose={() => setShowAggregation(false)}
        />
      )}
      {showMetadata && (
        <MetadataModal
          metadata={metadata}
          onClose={() => setShowMetadata(false)}
        />
      )}
    </>
  );
}

CaseDetailHeader.propTypes = {
  claimId: PropTypes.string.isRequired,
  damageAmount: PropTypes.string.isRequired,
  createdAt: PropTypes.object.isRequired,
  aggregation: PropTypes.object.isRequired,
  insurerName: PropTypes.string,
  productFamily: PropTypes.string.isRequired
};

CaseDetailHeader.defaultProps = {
  insurerName: null
};

export default CaseDetailHeader;
