import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import CardHeader from 'reactstrap/lib/CardHeader';
import CommentList from 'components/CommentList';
import { useLocation } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { MentionsInput, Mention } from 'react-mentions';
import { useChatbox } from 'utils/useChatbox';

const COMMENT_ADDED_SUBSCRIPTION = gql`
  subscription subscribeToCommentAdded(
    $commentedEntityId: ID!
    $entityType: String!
  ) {
    commentAdded(
      commentedEntityId: $commentedEntityId
      entityType: $entityType
    ) {
      id
      text
      author {
        id
        username
        name
        surname
        thumbnailUrl
        avatarUrl
      }
      updatedAt
    }
  }
`;

const GET_SUSPICIOUS_CASE_MATCHES_AND_COMMENTS = gql`
  query suspiciousCaseMatchesAndComments($id: ID!) {
    suspiciousCase(id: $id) {
      id
      comments {
        id
        text
        author {
          id
          username
          name
          surname
          thumbnailUrl
          avatarUrl
        }
        updatedAt
      }
      matches {
        id
        similarity
      }
    }
  }
`;

const CREATE_COMMENT_MUTATION = gql`
  mutation createComment(
    $commentedEntityId: ID!
    $entityType: String!
    $text: String!
  ) {
    comment(
      commentedEntityId: $commentedEntityId
      entityType: $entityType
      text: $text
    ) {
      id
      text
      author {
        id
        username
        name
      }
      updatedAt
      edited
    }
  }
`;

function isNumeric(str) {
  if (typeof str !== 'string') return false; // we only process strings!
  return (
    // eslint-disable-next-line no-restricted-globals
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

function parsePath(path) {
  const tokens = path.split('/');
  if (tokens.length !== 3) {
    return {};
  }
  if (tokens[0] !== '') {
    return {};
  }
  if (!['detection'].includes(tokens[1])) {
    return {};
  }
  if (!isNumeric(tokens[2])) {
    return {};
  }
  return {
    entity: tokens[1],
    id: tokens[2]
  };
}

function entityTypeFromParsedPath(parsedPath) {
  switch (parsedPath.entity) {
    case 'detection':
      return 'SUSPCASE';
    default:
      throw new Error(`unable to find entityType for ${parsedPath} `);
  }
}

function Chatbox() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [comments, setComments] = useState([]);
  const { id, entity } = parsePath(pathname);
  const { isOpened, setIsOpened, inputValue, setInputValue } = useChatbox();

  const commentAddedHandler = useCallback(
    d => {
      setComments(preComments => [
        ...preComments,
        d.subscriptionData.data.commentAdded
      ]);
    },
    [setComments]
  );

  useSubscription(COMMENT_ADDED_SUBSCRIPTION, {
    variables: {
      commentedEntityId: id,
      entityType: entityTypeFromParsedPath({ entity })
    },
    onSubscriptionData: commentAddedHandler
  });

  const { data, refetch, error } = useQuery(
    GET_SUSPICIOUS_CASE_MATCHES_AND_COMMENTS,
    {
      variables: {
        id
      }
    }
  );

  useEffect(() => {
    if (data) {
      setComments(data.suspiciousCase.comments);
    }
  }, [data]);

  useEffect(() => {
    setInputValue(''); // reset input when screen changed
  }, [id, setInputValue]);

  const [createCommentMutation] = useMutation(CREATE_COMMENT_MUTATION);

  const createComment = useCallback(
    async (commentedEntityId, entityType, text) => {
      try {
        await createCommentMutation({
          variables: {
            commentedEntityId,
            entityType,
            text
          }
        });
        toast.success(t('Commented'));
        refetch();
      } catch (err) {
        toast.error(t('Failed to comment'));
        throw new Error(`failed to create comment`);
      }
    },
    [createCommentMutation, refetch, t]
  );

  const inputChangeHandler = e => {
    setInputValue(e.target.value);
  };

  const inputKeyPressedHandler = async e => {
    if (e.which === 13 && !e.shiftKey) {
      await createComment(id, 'SUSPCASE', inputValue);
      setInputValue('');
      e.preventDefault();
    }
  };

  const toggle = () => {
    setIsOpened(s => setIsOpened(!s));
  };

  const matches = data?.suspiciousCase?.matches?.map(m => ({
    id: m.id,
    display: `#${m.id}-${(m.similarity * 100).toFixed(2)}%`
  }));

  if (error) return null;

  return (
    <div className="chatbox">
      <Card className="shadow mr-3 border-none">
        <CardHeader className="chatbox__header" onClick={toggle}>
          <h4 className="mb-0 text-white">
            <i className="far fa-comments" /> Comments ({comments.length})
          </h4>
        </CardHeader>
        {isOpened ? (
          <CardBody className="chatbox__body d-flex flex-column p-0">
            <div className="chatbox__body__comments">
              <CommentList comments={comments} />
            </div>
            <div className="chatbox__body__input">
              <MentionsInput
                id="chatbox-input"
                className="chatbox__input"
                placeholder="Write a comment here ..."
                rows="2"
                type="textarea"
                value={inputValue}
                onChange={inputChangeHandler}
                onKeyPress={inputKeyPressedHandler}
                style={{
                  flex: 1
                }}
                allowSuggestionsAboveCursor
              >
                <Mention
                  trigger="#"
                  data={matches}
                  markup="#__id__"
                  displayTransform={mentionId => {
                    return `#${mentionId}`;
                  }}
                />
              </MentionsInput>
            </div>
          </CardBody>
        ) : null}
      </Card>
    </div>
  );
}

export default Chatbox;
