import React from 'react';
import { Button, Container, Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function NotFound({ showGoBackButton }) {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
        <div className="header-body">
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col className="text-center">
                      <h1>{t('not found')}</h1>
                      {showGoBackButton && (
                        <Button
                          className="btn-icon btn-3"
                          color="default"
                          type="button"
                          onClick={goBack}
                        >
                          <span className="btn-inner--text">
                            {t('go back')}
                          </span>
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

NotFound.propTypes = {
  showGoBackButton: PropTypes.bool
};

NotFound.defaultProps = {
  showGoBackButton: false
};

export default NotFound;
