import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import createApolloClient from 'utils/apolloClient';
import PrivateRoute from 'utils/privateRoute';
import { FiltersProvider } from 'utils/useFilters';
import { AccountProvider } from 'utils/useAccount';

import PublicLayout from 'layouts/PublicLayout';
import AdminLayout from 'layouts/AdminLayout';
import TermsAndConditions from 'views/TermsAndConditions';

import ScrollToTop from 'components/ScrollToTop';

import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import FixedContainer from 'components/FixedContainer';
import ChatboxWrapper from 'components/ChatboxWrapper';
import { ChatboxProvider } from 'utils/useChatbox';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export default function App() {
  const client = createApolloClient({
    uri: process.env.REACT_APP_API_URL,
    cache: true
  });

  return (
    <>
      <ToastContainer hideProgressBar />
      <ApolloProvider client={client}>
        <AccountProvider>
          <FiltersProvider>
            <ChatboxProvider>
              <BrowserRouter>
                <FixedContainer>
                  <ChatboxWrapper />
                </FixedContainer>
                <ScrollToTop />
                <Switch>
                  {/* PUBLIC ROUTES */}
                  <Route exact path="/login" component={PublicLayout} />
                  <Route exact path="/logout" component={PublicLayout} />
                  <Route exact path="/register" component={PublicLayout} />
                  <Route
                    exact
                    path="/forgotten-password"
                    component={PublicLayout}
                  />
                  <Route
                    exact
                    path="/terms-and-conditions"
                    component={TermsAndConditions}
                  />

                  {/* APP ROUTES */}
                  <PrivateRoute exact path="/" component={AdminLayout} />
                  <PrivateRoute exact path="/cases" component={AdminLayout} />
                  <PrivateRoute exact path="/cases/:id" component={AdminLayout} />
                  <PrivateRoute exact path="/detection" component={AdminLayout} />
                  <PrivateRoute
                    exact
                    path="/detection/:id"
                    component={AdminLayout}
                  />
                  <PrivateRoute exact path="/finder" component={AdminLayout} />
                  <PrivateRoute
                    exact
                    path="/finder/:id"
                    component={AdminLayout}
                  />
                  <PrivateRoute
                    exact
                    path="/finder/:id/edit"
                    component={AdminLayout}
                  />
                  <PrivateRoute exact path="/tasks" component={AdminLayout} />
                  <PrivateRoute exact path="/jobs" component={AdminLayout} />
                  <PrivateRoute exact path="/jobs/:id" component={AdminLayout} />
                  <PrivateRoute exact path="/settings" component={AdminLayout} />
                  <PrivateRoute path="*" component={PublicLayout} />
                </Switch>
              </BrowserRouter>
            </ChatboxProvider>
          </FiltersProvider>
        </AccountProvider>
      </ApolloProvider>
    </>
  );
}
