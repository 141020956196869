import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
  CardHeader,
  Input
} from 'reactstrap';
import PropTypes from 'prop-types';
import sleep from 'utils/sleep';
import BoundingBoxSelectImage from './BoundingBoxSelectImage';
import { useTranslation } from 'react-i18next';

const tabs = ['UPLAD_IMAGE_TAB', 'SELECT_BOUNDING_BOX_TAB', 'RUN_QUERY_TAB'];

function isAllowedTab(allowedTabs, tabName) {
  return allowedTabs.includes(tabName);
}

function calculateAllowedTabs(imageUrl, isValidBoundingBox) {
  if (imageUrl === null) {
    return tabs[0];
  }
  if (!isValidBoundingBox) {
    return [tabs[0], tabs[1]];
  }
  return tabs;
}

function FinderQueryWizard({
  imageUrl,
  boundingBox,
  isValidBoundingBoxSaved,
  onFileToUploadChange,
  onFileToUploadClick,
  onBoundingBoxChange,
  onBoundingBoxSave,
  boundingBoxChanged,
  label,
  setLabel,
  onRunFinderQueryClick
}) {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(1);
  const allowedTabs = calculateAllowedTabs(imageUrl, isValidBoundingBoxSaved);
  const [toUploadFileName, setToUploadFileName] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [savingBoundingBox, setSavingBoundingBox] = useState(false);
  const [callingRunFinderQuery, setCallingRunFinderQuery] = useState(false);

  const handleOnFileUploadChange = e => {
    const file = e.target.files[0];
    onFileToUploadChange(e);
    setToUploadFileName(file.name);
  };

  const handleOnFileUploadlick = async () => {
    setUploadingImage(true);
    await sleep(3000);
    onFileToUploadClick();
    setToUploadFileName(null);
    setUploadingImage(false);
    setCurrentTab(2);
  };

  const handleOnBoundingBoxSaveClick = async () => {
    setSavingBoundingBox(true);
    await sleep(3000);
    onBoundingBoxSave();
    setSavingBoundingBox(false);
    setCurrentTab(3);
  };

  const handleLabelChange = e => {
    setLabel(e.target.value);
  };

  const handleRunFinderQueryClick = async () => {
    setCallingRunFinderQuery(true);
    await sleep(3000);
    onRunFinderQueryClick();
    setCallingRunFinderQuery(false);
  };

  return (
    <Card>
      <CardHeader className="pl-0 pr-0">
        {' '}
        <div>
          <Nav
            className="nav-fill flex-column flex-md-row flat-tabs pl-3 pr-3"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            {' '}
            <NavItem className="flat-nav-item">
              <NavLink
                aria-selected={currentTab === 1}
                className={classnames(
                  'mb-sm-3 mb-md-0 flat-tab clickable',
                  {
                    'flat-tab-active': currentTab === 1
                  },
                  {
                    disabled: !isAllowedTab(allowedTabs, 'UPLAD_IMAGE_TAB')
                  }
                )}
                onClick={() => setCurrentTab(1)}
                role="tab"
              >
                <i className="fas fa-cloud-upload-alt mr-2" />
                {t('upload picture')}
              </NavLink>
            </NavItem>
            <NavItem className="flat-nav-item" disabled>
              <NavLink
                aria-selected={currentTab === 2}
                className={classnames(
                  'mb-sm-3 mb-md-0 flat-tab clickable',
                  {
                    'flat-tab-active': currentTab === 2
                  },
                  {
                    disabled: !isAllowedTab(
                      allowedTabs,
                      'SELECT_BOUNDING_BOX_TAB'
                    )
                  }
                )}
                onClick={() => setCurrentTab(2)}
                role="tab"
              >
                <i className="far fa-object-ungroup mr-2" />
                {t('select bounding box')}
              </NavLink>
            </NavItem>
            <NavItem className="flat-nav-item" disabled>
              <NavLink
                aria-selected={currentTab === 3}
                className={classnames(
                  'mb-sm-3 mb-md-0 flat-tab clickable',
                  {
                    'flat-tab-active': currentTab === 3
                  },
                  {
                    disabled: !isAllowedTab(allowedTabs, 'RUN_QUERY_TAB')
                  }
                )}
                onClick={() => setCurrentTab(3)}
                role="tab"
              >
                <i className="fas fa-play mr-2" />
                {t('run query')}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </CardHeader>
      <CardBody>
        <TabContent activeTab={`tabs${currentTab}`}>
          <TabPane tabId="tabs1">
            <Row className="p-3">
              <Col className="d-flex flex-column  justify-content-center col-10">
                <form>
                  <div className="custom-file">
                    <Input
                      type="file"
                      name="file"
                      className="custom-file-input"
                      onChange={handleOnFileUploadChange}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      {!toUploadFileName ? t('select file') : toUploadFileName}
                    </label>
                  </div>
                </form>
              </Col>
              <Col className="d-flex flex-column align-items-center justify-content-center col-2">
                <Button
                  className={classnames('btn-icon btn-3 position-absolute', {
                    'animate-flicker': uploadingImage === true
                  })}
                  color="default"
                  type="button"
                  size="lg"
                  onClick={handleOnFileUploadlick}
                  disabled={!toUploadFileName}
                >
                  <span className="btn-inner--icon">
                    <i
                      className={classnames('fas fa-sync-alt', {
                        'fa-spin': uploadingImage === true
                      })}
                    />
                  </span>
                  <span className="btn-inner--text">
                    {!imageUrl ? t('upload') : t('reupload')}
                  </span>
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="d-flex flex-column align-items-center justify-content-center">
                {imageUrl && (
                  <>
                    <img
                      className="rounded"
                      alt="..."
                      src={imageUrl}
                      style={{
                        maxWidth: '100%'
                      }}
                    />
                  </>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="tabs2">
            <Row className="p-3">
              <Col className="d-flex flex-column align-items-center justify-content-center col-3 offset-3">
                <Input
                  type="select"
                  name="labelSelect"
                  id="labelSelect"
                  onChange={handleLabelChange}
                  value={label}
                  defaultValue={label}
                >
                  <option value="" selected={label === ''} disabled={label}>{t('select')}</option>
                  <option value="logo" selected={label === 'logo'}>{t('logo')}</option>
                  <option value="stamp" selected={label === 'stamp'}>{t('stamp')}</option>
                </Input>
              </Col>
              <Col className="d-flex flex-column align-items-center justify-content-center col-3">
                <Button
                  className={classnames('btn-icon btn-3 position-absolute', {
                    'animate-flicker': savingBoundingBox === true
                  })}
                  color="default"
                  type="button"
                  size="lg"
                  onClick={handleOnBoundingBoxSaveClick}
                  disabled={(!boundingBoxChanged || savingBoundingBox) || !label}
                >
                  <span className="btn-inner--icon">
                    <i
                      className={classnames('fas fa-sync-alt', {
                        'fa-spin': savingBoundingBox === true
                      })}
                    />
                  </span>
                  <span className="btn-inner--text">{t('apply bounding box')}</span>
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="d-flex flex-column align-items-center justify-content-center">
                {imageUrl && (
                  <BoundingBoxSelectImage
                    imageUrl={imageUrl}
                    boundingBox={boundingBox}
                    onBoundingBoxChange={onBoundingBoxChange}
                  />
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="tabs3">
            <Row className="text-center">
              <Col>
                <Button
                  className={classnames('btn-icon btn-3', {
                    'animate-flicker': callingRunFinderQuery === true
                  })}
                  disabled={callingRunFinderQuery === true}
                  color="success"
                  type="button"
                  size="lg"
                  onClick={handleRunFinderQueryClick}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-play" />
                  </span>
                  <span className="btn-inner--text">{!callingRunFinderQuery ? t('run finder query') : t('running finder query')}</span>
                </Button>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
}

FinderQueryWizard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  boundingBox: PropTypes.shape({
    x1: PropTypes.number.isRequired,
    y1: PropTypes.number.isRequired,
    x2: PropTypes.number.isRequired,
    y2: PropTypes.number.isRequired
  }),
  onBoundingBoxChange: PropTypes.func.isRequired,
  onBoundingBoxSave: PropTypes.func.isRequired,
  label: PropTypes.string,
  setLabel: PropTypes.func.isRequired,
  onFileToUploadChange: PropTypes.func.isRequired,
  onFileToUploadClick: PropTypes.func.isRequired,
  boundingBoxChanged: PropTypes.bool.isRequired,
  isValidBoundingBoxSaved: PropTypes.bool.isRequired,
  onRunFinderQueryClick: PropTypes.func.isRequired
};

FinderQueryWizard.defaultProps = {
  boundingBox: null,
  label: ''
};

export default FinderQueryWizard;
