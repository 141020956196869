import React, {
  createContext,
  useContext,
  useState
} from 'react';
import PropTypes from 'prop-types';

const chatboxContext = createContext();

function useProvideChatbox() {
  const [isOpened, setIsOpened] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const appendToInput = (txt) => {
    setIsOpened(true)
    setInputValue(prevState => prevState + " " + txt)
  }

  return {
    isOpened,
    setIsOpened,
    inputValue,
    setInputValue,
    appendToInput
  };
}

export function ChatboxProvider({ children }) {
  const chatbox = useProvideChatbox();
  return (
    <chatboxContext.Provider value={chatbox}>
      {children}
    </chatboxContext.Provider>
  );
}

ChatboxProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const useChatbox = () => {
  return useContext(chatboxContext);
};
