import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import gql from 'graphql-tag';
import SuspiciousCasesTable from 'components/Tables/SuspiciousCasesTable';
import paginationOptions from 'utils/paginationOptions';
import Pagination from 'components/Pagination';
import { useQuery } from '@apollo/react-hooks';
import Loader from './Loader';
import i18next from 'i18next';

const GET_SUSPICIOUS_CASES = gql`
  query suspiciousCases(
    $productFamily: String!,
    $filter: SuspiciousCaseFilter,
    $pagination: Pagination
  ) {
    suspiciousCases(
      productFamily: $productFamily
      filter: $filter
      pagination: $pagination
    ) {
      items {
        id
        queryCase {
          id
          claimId
        }
        matchCase {
          id
          claimId
        }
        similarity
        scenario {
          id
          title
        }
      }
    paginationInfo{
      nextToken
      previousToken
    }
    }
  }
`;

function normalizeSuspiciousCases(graphData) {
  return graphData.map(d => ({
    id: d.id,
    matchCaseId: d?.matchCase?.id,
    matchCaseClaimId: d?.matchCase?.claimId,
    similarity: d?.similarity,
    scenarioTitle: d?.scenario?.title
  }));
}

function SuspiciousCasesModal({ onClose, productFamily, filter }) {
  const { t } = useTranslation();
   const [pagination, setPagination] = useState({
    limit: paginationOptions()[0].value
  });
  const {data, loading, error} = useQuery(GET_SUSPICIOUS_CASES, {
    variables: {
      productFamily,
      filter,
      pagination
    }
  });

  const handlePaginationChange = ({ name, value }) => {
    const newPagination = {
      limit: pagination.limit,
      [name]: value
    };
    setPagination(newPagination);
  };

  const suspiciousCases = data?.suspiciousCases?.items ? normalizeSuspiciousCases(data?.suspiciousCases?.items) : [];
  const { nextToken, previousToken } = data?.suspiciousCases?.paginationInfo || {};
  const { limit } = pagination;

  return (
    <Modal
      className="modal-dialog-centered modal--fullscreen max-width-90vw"
      isOpen
      toggle={onClose}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('also found in')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden>×</span>
        </button>
      </div>
      <div className="modal-body modal-max-width modal-80-width">
        {
          error && <Loader error title={t(error.message.replace(":", ""))} />
        }
        {
          loading && <Loader title={i18next.t('loading')} />
        }
        <Row>
          <Col>
            <div style={{ overflowY: 'auto' }}>
              <SuspiciousCasesTable suspiciousCases={suspiciousCases} />
              {
                !suspiciousCases || suspiciousCases.length === 0 && (
                  <div className='w-100 d-flex justify-content-center pt-6'>
                    <h3>{t('No results')}</h3>
                  </div>
                )
              }
            </div>
          </Col>
        </Row>
      </div>
      <div className="modal-footer justify-content-center">
        <Pagination
          limit={limit}
          nextToken={nextToken}
          previousToken={previousToken}
          limitOptions={paginationOptions()}
          onChange={handlePaginationChange}
        />
      </div>
    </Modal>
  );
}

SuspiciousCasesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  productFamily: PropTypes.string,
  filter: PropTypes.string,
}

SuspiciousCasesModal.defaultProps = {
  productFamily: 'cars',
  filter: {},
}

export default SuspiciousCasesModal;