import React from 'react';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';
import 'react-image-crop/dist/ReactCrop.css';

const initCrop = {
  unit: '%'
};

function boundingBoxToCropInPercentage(boundingBox) {
  const convertedCrop = boundingBox
    ? {
      x: boundingBox.x1 * 100,
      y: boundingBox.y1 * 100,
      width: (boundingBox.x2 - boundingBox.x1) * 100,
      height: (boundingBox.y2 - boundingBox.y1) * 100
    }
    : null;

  return {
    ...initCrop,
    ...convertedCrop
  };
}

function cropInPercentageToBoundingBox(cropInPercentage) {
  return {
    x1: cropInPercentage.x / 100,
    y1: cropInPercentage.y / 100,
    x2: (cropInPercentage.x + cropInPercentage.width) / 100,
    y2: (cropInPercentage.y + cropInPercentage.height) / 100
  };
}

function BoundingBoxSelectImage({
  imageUrl,
  boundingBox,
  onBoundingBoxChange,
}) {
  const crop = boundingBoxToCropInPercentage(boundingBox);

  const handleCropChange = async (_, percentageCrop) => {
    if (Number.isNaN(percentageCrop.x)) {
      return;
    }
    const boundingBox = cropInPercentageToBoundingBox(percentageCrop);
    onBoundingBoxChange(boundingBox);
  };

  return <ReactCrop src={imageUrl} crop={crop} onChange={handleCropChange} />;
}

BoundingBoxSelectImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  boundingBox: PropTypes.shape({
    x1: PropTypes.number.isRequired,
    y1: PropTypes.number.isRequired,
    x2: PropTypes.number.isRequired,
    y2: PropTypes.number.isRequired
  }).isRequired,
  onBoundingBoxChange: PropTypes.func.isRequired
};

export default BoundingBoxSelectImage;
