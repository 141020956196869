import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import CaseNavigation from 'components/CaseNavigation';
import GenerateReport from 'components/GenerateReport';
import { useTranslation } from 'react-i18next';
import CommentsModal from 'components/CommentsModal';
import Loader from 'components/Loader';
import FormattedPercentage from 'components/FormattedPercentage';
import FoundInDetectionCard from 'components/FoundInDetectionCard';
import Skeleton from 'react-loading-skeleton';
import EditableSuspiciousCaseState from 'components/EditableSuspiciousCaseState';
import EditableFeedback from 'components/EditableFeedback';

function getCaseIdFromUrl(url) {
  const tokens = url.split('/');
  return tokens[2];
}

function DetectionDetailHeader({
  id,
  loading,
  error,
  similarity,
  bestMatch,
  matches,
  scenario,
  suspiciousCaseComments,
  onCommentSubmit,
  updateFeedbackLoading,
  generateReport,
  generateReportLoading,
  generateReportError,
  reportUrl,
  claimId,
  productFamily
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const [showComments, setShowComments] = useState(false);
  const currentCaseId = getCaseIdFromUrl(location.pathname);

  const filteredCaseIds =
    location.state && location.state.filteredCaseIds
      ? location.state.filteredCaseIds
      : [];

  const handleShowCommentsClick = () => {
    setShowComments(true);
  };

  const handleCommentSubmit = async comment => {
    onCommentSubmit(id, 'SUSPCASE', comment);
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="d-flex justify-content-between">
            <CaseNavigation
              currentCaseId={currentCaseId}
              filteredCaseIds={filteredCaseIds}
              caseDetailPathname="detection"
            />
            <GenerateReport
              reportUrl={reportUrl}
              generateReport={generateReport}
              generateReportLoading={generateReportLoading}
              generateReportError={generateReportError}
            />
          </div>
          <div className="header-body">
            <Row>
              <Col>
                <Card>
                  {(loading || updateFeedbackLoading) && (
                    <Loader title={t('loading data')} />
                  )}
                  {error && <Loader title={t('failed to load data')} error />}
                  <CardBody>
                    <Row>
                      <Col xs="2">
                        <Row>
                          <EditableSuspiciousCaseState suspiciousCaseId={id} />
                        </Row>
                      </Col>
                      <Col xs="6">
                        <Row>
                          <EditableFeedback suspiciousCaseId={id} />
                        </Row>
                      </Col>
                      <Col>
                        <div className="col">
                          <CardTitle tag="h5" className=" text-muted mb-0">
                            <span>{t('scenario')}</span>
                          </CardTitle>
                          <span
                            id="scenario"
                            className="h2 font-weight-bold mb-0"
                          >
                            {scenario?.title}
                          </span>
                          <p className="muted-text h6">
                            {scenario?.description}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col
                lg="6"
                xl="3"
                className="detection-detail__card--same-height"
              >
                <Card className="card-stats mb-4 mb-xl-0 w-100">
                  {error && <Loader title={t('failed to load data')} error />}
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className=" text-muted mb-0">
                          <span>{t('similarity')}</span>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {loading ? (
                            <Skeleton />
                          ) : (
                            <FormattedPercentage value={similarity} />
                          )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i className="fas fa-balance-scale" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col lg="6" xl="2">
                <Card className="card-stats mb-4 mb-xl-0">
                  {loading && <Loader title={t('loading data')} />}
                  {error && <Loader title={t('failed to load data')} error />}
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className=" text-muted mb-0"
                        >
                          <span>{t('best match')}</span>
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          <FormattedPercentage value={bestMatch} />
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-percent" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}
              <Col
                lg="6"
                xl="3"
                className="detection-detail__card--same-height"
              >
                <Card className="card-stats mb-4 mb-xl-0 w-100">
                  {error && <Loader title={t('failed to load data')} error />}
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className=" text-muted mb-0">
                          <span>{t('matches')}</span>
                        </CardTitle>
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <>
                            <span className="h2 font-weight-bold mb-0">
                              {matches}
                            </span>
                            <span className="h6 ml-1 text-lowercase">
                              ({t('best match')}{' '}
                              <FormattedPercentage value={bestMatch} />)
                            </span>
                          </>
                        )}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="far fa-images" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col
                lg="6"
                xl="3"
                className="detection-detail__card--same-height"
              >
                <Card className="card-stats mb-4 mb-xl-0 w-100">
                  {error && <Loader title={t('failed to load data')} error />}
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className=" text-muted mb-0">
                          <span>{t('susp case comments')}</span>
                        </CardTitle>
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <>
                            <span
                              id="scenario"
                              className="h2 font-weight-bold mb-0"
                            >
                              {suspiciousCaseComments?.length}
                            </span>
                            <span
                              className="show-comments-text ml-2"
                              onClick={handleShowCommentsClick}
                            >
                              {t('show comments')}
                            </span>
                          </>
                        )}
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="far fa-comments" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <FoundInDetectionCard
                  claimId={claimId}
                  productFamily={productFamily}
                  parentLoading={loading}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {showComments && (
        <CommentsModal
          comments={suspiciousCaseComments}
          onClose={() => setShowComments(false)}
          onCommentSubmit={handleCommentSubmit}
        />
      )}
    </>
  );
}

DetectionDetailHeader.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  similarity: PropTypes.number.isRequired,
  bestMatch: PropTypes.number.isRequired,
  matches: PropTypes.number.isRequired,
  scenario: PropTypes.string.isRequired,
  feedback: PropTypes.shape({
    falsePositive: PropTypes.string,
    toInvestigate: PropTypes.string,
    isFraud: PropTypes.string
  }),
  feedbackToggleClick: PropTypes.func,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  claimId: PropTypes.string.isRequired,
  productFamily: PropTypes.string.isRequired
};

DetectionDetailHeader.defaultProps = {
  loading: false,
  error: false
};

export default DetectionDetailHeader;
