import React, { useEffect } from 'react';
import { Button, Card, Row, Col, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { useAccount } from 'utils/useAccount';
import { useTranslation } from 'react-i18next';
import { useChatbox } from 'utils/useChatbox';
import FormattedPercentage from './FormattedPercentage';
import labelScoreToHighlightedArea from 'utils/labelScoreToHighlightedArea';
import ImageWithHighlightedArea from 'components/ImageWithHighlightedArea';
import classnames from 'classnames';



import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

const GET_MATCH_QUERY = gql`
query getMatch($id: ID!){
	match(id: $id) {
		id
    similarity
    queryImage{
      id
      imageUrl
      dimensions{
        width
        height
      }
    }
    queryImageResult{
      label
      score
      box{
        x1
        x2
        y1
        y2
      }
    }
    matchImage{
      id
      imageUrl
      dimensions{
        width
        height
      }
    }
      matchImageResult{
      label
      score
      box{
        x1
        x2
        y1
        y2
      }
    }
	}
}`

function MatchPairCard({
  id,
  leftImageUrl,
  rightImageUrl,
  similarity,
  distance,
  onClick,
  insurerName,
  counterpartyInsurerName,
  withHighlightedAreas
}) {
  const { t } = useTranslation();
  const { devMode } = useAccount();
  const { appendToInput } = useChatbox();
  const [loadHighlightedAreas, {data}] = useLazyQuery(GET_MATCH_QUERY, {
    variables: {
      id
    }
  })

  const focusedMatch = data?.match || {};
  const { matchImageResult, queryImageResult } = focusedMatch;
  const matchImageHighlightedArea = matchImageResult
    ? labelScoreToHighlightedArea(matchImageResult)
    : null;
  const queryImageHighlightedArea = queryImageResult
    ? labelScoreToHighlightedArea(queryImageResult)
    : null;

  useEffect(() => {
    if (withHighlightedAreas) {
      loadHighlightedAreas()
    }
  }, [withHighlightedAreas])



  return (
    <Card className="shadow match-pair-card" onClick={onClick} id={`${id}`}>
      <Row className="p-3 position-relative">
        <Col className="d-flex align-items-center mw-50">
        <div
              className={classnames({
                'match-pair-card__picture': !data,
                'match-pair-card__picture--no-height': data,
              })}
              style={{ backgroundImage: `url("${leftImageUrl}")` }}
            >
              {
                data && (
                  <ImageWithHighlightedArea
                  imageUrl={focusedMatch.queryImage.imageUrl}
                  originalImageWidth={focusedMatch.queryImage.dimensions.width}
                  originalImageHeight={focusedMatch.queryImage.dimensions.height}
                  highlightedAreas={
                    queryImageHighlightedArea ? [queryImageHighlightedArea] : null
                  }
                  clickable={false}
                />
                )
              }
            </div>

        </Col>
        <Col className="d-flex align-items-center mw-50">
        <div className={classnames({
                'match-pair-card__picture': !data,
                'match-pair-card__picture--no-height': data,
              })}
              style={{ backgroundImage: `url("${rightImageUrl}")` }}
            >{
              data && (
                <ImageWithHighlightedArea
                imageUrl={focusedMatch.matchImage.imageUrl}
                originalImageWidth={focusedMatch.matchImage.dimensions.width}
                originalImageHeight={focusedMatch.matchImage.dimensions.height}
                highlightedAreas={
                  matchImageHighlightedArea ? [matchImageHighlightedArea] : null
                }
                clickable={false}
              />
              )
            }
            </div>
        </Col>
        <div className="match-pair-card__percentage__wrapper">
          <div className="match-pair-card__percentage shadow-lg">
            <span className="h1 font-weight-bold mb-0 text-black">
              <FormattedPercentage value={similarity} />
              {devMode() ? (
                <div className="text-center" id="distance-tooltip">
                  <h5>
                    <i className="fas fa-ruler" /> {distance}
                  </h5>
                  <UncontrolledTooltip
                    delay={0}
                    placement="bottom"
                    target="distance-tooltip"
                  >
                    This is distance, Nikito!
                  </UncontrolledTooltip>
                </div>
              ) : null}
            </span>
          </div>
        </div>
      </Row>
      <Row className="pl-3 pr-3">
        <Col className="text-left">
          <h5>
            <span className="fw-300 text-lowercase">{t('insurer')}: </span>
            {insurerName}
          </h5>
        </Col>
        <Col className="text-center pb-2">
          <Button
            color="primary"
            size="sm"
            outline
            onClick={e => {
              e.stopPropagation();
              appendToInput(`#${id}`);
            }}
          >
            <i className="fa fa-at" aria-hidden="true" />
            {t('mention')}
          </Button>
        </Col>
        <Col className="text-right">
          <h5>
            <span className="fw-300 text-lowercase">
              {t('matched case insurer')}:{' '}
            </span>
            {counterpartyInsurerName}
          </h5>
        </Col>
      </Row>
    </Card>
  );
}

MatchPairCard.propTypes = {
  id: PropTypes.string.isRequired,
  leftImageUrl: PropTypes.string.isRequired,
  rightImageUrl: PropTypes.string.isRequired,
  similarity: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  withHighlightedAreas:PropTypes.bool
};

MatchPairCard.defaultProps = {
  withHighlightedAreas: false
}

export default MatchPairCard;
