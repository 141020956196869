function isEmpty(o) {
  if (o === null || o === undefined) {
    return true;
  }
  if ((Object.keys(o).length === 0) || (Object.keys(o).length === 0 && o.constructor === Object)) {
    return true;
  }
  return false;
}

export default isEmpty;