import React from 'react';
import { Modal, Row, Col} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FormattedTimestamp from 'components/FormattedTimestamp';
import FormattedPercentage from 'components/FormattedPercentage';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

function TaskDetailModal({isOpen, toggle, task}) {
  const { t } = useTranslation();

  return (
    <Modal
    className="modal-dialog-centered"
    isOpen={isOpen}
    toggle={toggle}
  >
    <div className="modal-header">
      <h5 className="modal-title text-capitalize" id="exampleModalLabel">
        {t('task id')}: {task.id}
      </h5>
      <button
        aria-label="Close"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={toggle}
      >
        <span aria-hidden>×</span>
      </button>
    </div>
    <div className="modal-body modal-max-width modal-80-width pl-5 pr-5">
      <Row>
        <Col>
          <table>
            <tr>
              <td>
                <p><strong>{t('service name')}:</strong></p>
              </td>
              <td>
                <p className="pl-2">{task.serviceName}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{t('status')}:</strong></p>
              </td>
              <td>
              <p className="pl-2">{task.status}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{t('progress')}:</strong></p>
              </td>
              <td>
              <p className="pl-2"><FormattedPercentage value={task.progress} /></p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>{t('updated at')}:</strong></p>
              </td>
              <td>
              <p className="pl-2"><FormattedTimestamp
                timestamp={task.updatedAt}
                format="YYYY-MM-DD hh:mm"
              /></p>
              </td>
            </tr>
          </table>
        </Col>
      </Row>
      <Row>
        <Col>
        <JSONInput
            id={task.id}
            placeholder={JSON.parse(task.body)}
            theme="light_mitsuketa_tribute"
            width="100%"
            height="350px"
            locale={locale}
            viewOnly
          />
        </Col>
      </Row>
    </div>
    <div className="modal-footer" />
  </Modal>
  );
}

TaskDetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    serviceName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    updatedAt: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  })
}


export default TaskDetailModal;