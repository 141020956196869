import React from 'react';
import classnames from 'classnames';
import Chart from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  Badge,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from 'reactstrap';
import i18next from 'i18next';

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from 'variables/charts';

import Header from 'components/Headers/Header';
import ImageWithHighlightedArea from '../components/ImageWithHighlightedArea';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: 'data1'
    };
  }

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === 'data1' ? 'data2' : 'data1'
    });
    const wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    this.chartReference.update();
  };

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        {i18next.t('overview')}
                      </h6>
                      <h2 className="text-white mb-0">{i18next.t('detected frauds')}</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={chartExample1[this.state.chartExample1Data]}
                      options={chartExample1.options}
                      getDatasetAtEvent={e => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">{i18next.t('last cases')}</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        {i18next.t('see all')}
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{i18next.t('case id')}</th>
                      <th scope="col">{i18next.t('amount')}</th>
                      <th scope="col">{i18next.t('date')}</th>
                      <th scope="col">{i18next.t('best similarity')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">012-451</th>
                      <td>4 569 CZK</td>
                      <td>2020 May 10</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">76.89%</span>
                          <div>
                            <Progress
                              max="100"
                              value="77"
                              barClassName="bg-gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">011-489</th>
                      <td>3 985 CZK</td>
                      <td>2020 May 7</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">65.89%</span>
                          <div>
                            <Progress
                              max="100"
                              value="66"
                              barClassName="bg-gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">011-489</th>
                      <td>3 513 CZK</ td>
                      <td>2020 May 5</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">30.44%</span>
                          <div>
                            <Progress
                              max="100"
                              value="30"
                              barClassName="bg-gradient-warning"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">089-186</th>
                      <td>2 050 CZK</td>
                      <td>2020 May 5</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">73.12%</span>
                          <div>
                            <Progress
                              max="100"
                              value="73"
                              barClassName="bg-gradient-success"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">{i18next.t('last finder queries')}</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        {i18next.t('see all')}
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{i18next.t('name')}</th>
                      <th scope="col">{i18next.t('status')}</th>
                      <th scope="col">{i18next.t('found')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Eon</th>
                      <td>
                        <Badge
                          color="success"
                        >
                          {i18next.t('FINISHED_enum')}
                        </Badge>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">3</span>

                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Auto Kelly stamp</th>
                      <td>
                        <Badge
                          color="primary"
                        >
                          {i18next.t('DRAFT_enum')}
                        </Badge>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">5</span>

                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Stamp query 5a</th>
                      <td>
                        <Badge
                          color="danger"
                        >
                          {i18next.t('FAILED_enum')}
                        </Badge>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">0</span>

                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Search 21/4</th>
                      <td>
                        <Badge
                          color="info"
                        >
                          {i18next.t('QUEUED_enum')}
                        </Badge>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">1</span>

                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Dashboard;
