import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ImageGalleryItem from 'components/ImageGalleryItem';
import Loader from 'components/Loader';

function CaseDetailImageGallery({ images, onThumbnailClick, maxDisplayed, loading, error }) {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  if (error) {
    return <Loader title={t(error.message.replace(":", ""))} error position="relative" />
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && images.map((image, idx) => {
        if (maxDisplayed !== null && idx >= maxDisplayed && !showMore) {
          return null
        } else {
          return (
            <ImageGalleryItem
              key={image.id}
              imageUrl={image.thumbnailUrl}
              onClick={() => onThumbnailClick(idx)}
            />
          )
        }
      }
      )}
      {
        (maxDisplayed && images.length > maxDisplayed) && (
          <div className="d-flex align-items-center">
            <span className="case-detail__image-gallery__show-more" onClick={() => setShowMore(prevState => !prevState)}>
              {
                showMore ? t('show less') : t('show more')
              }
            </span>
          </div>
        )
      }
    </>
  );
}

CaseDetailImageGallery.propTypes = {
  loading: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    onThumbnailClick: PropTypes.func.isRequired,
    container: PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  }))
}

CaseDetailImageGallery.defaultProps = {
  maxDisplayed: null,
  loading: false
}

export default CaseDetailImageGallery;