import React from 'react';
import { Table, Button, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FormattedTimestamp from 'components/FormattedTimestamp';
import { useTranslation } from 'react-i18next';

function taskColor(taskType) {
  switch (taskType) {
    case 'extractor':
      return 'default';
    case 'finder':
      return 'success';
    case 'matcher':
      return 'primary';
    case 'detector':
      return 'info';
    default:
      return 'default';
  }
}

function jobTasksPills(body) {
  const keys = ['extractor', 'finder', 'matcher', 'detector'];
  const pills = [];
  keys.forEach(k => {
    const taskGroup = body[k];
    if ((taskGroup !== null, taskGroup.length > 2)) {
      pills.push(k);
    }
  });
  return pills;
}

function JobsTable({ items }) {
  const { t } = useTranslation();

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">{t('detail')}</th>
          <th scope="col">{t('active')}</th>
          <th scope="col">{t('tasks')}</th>
          <th scope="col">{t('frequency')}</th>
          <th scope="col">{t('frequency datetime')}</th>
          <th scope="col">{t('user')}</th>
          <th scope="col">{t('updated at')}</th>
        </tr>
      </thead>
      <tbody>
        {items && items.length === 0 && (
          <tr>
            <td colSpan="7" className="text-center">
              <h4>{t('nothing to display')}</h4>
            </td>
          </tr>
        )}
        {items.map(item => (
          <tr key={item.id}>
            <td>
              <Link
                to={{
                  pathname: `/jobs/${item.id}`
                }}
              >
                <Button color="info">
                  <i className="fas fa-search text-white" />
                </Button>
              </Link>
            </td>
            <td>
              {item.active ? (
                <i className="far fa-check-circle isActive" />
              ) : (
                <i className="far fa-times-circle notActive" />
              )}
            </td>
            <td>
              {jobTasksPills(item.body).map(p => (
                <Badge color={taskColor(p)} className="mr-2">
                  {p.substring(0, 1).toUpperCase()}
                </Badge>
              ))}
            </td>
            <td>{item.frequency}</td>
            <td>{item.freqDatetime}</td>
            <td>{item.user.username}</td>
            <td>
              <FormattedTimestamp
                timestamp={item.updatedAt}
                format="YYYY-MM-DD hh:mm"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

JobsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      frequency: PropTypes.string.isRequired,
      freqDatetime: PropTypes.string.isRequired,
      user: PropTypes.shape({
        username: PropTypes.string.isRequired
      }).isRequired,
      updatedAt: PropTypes.string.isRequired
    })
  ).isRequired
};

export default JobsTable;
