import React from 'react';
import PropTypes from 'prop-types';
import picture from 'assets/img/brand/SEARPENT_animated_150px.gif';

function Loader({ title, error, position }) {
  return (
    <div
      className="dimmer--loader"
      style={{
        position
      }}
    >
      <div className="dimmer--loader__content">
        {error ? (
          <h1>
            <i className="fas fa-exclamation-triangle" />
          </h1>
        ) : (
          <img src={picture} alt="loading" style={{ width: '50px' }} />
        )}
        <h5>{title}</h5>
      </div>
    </div>
  );
}

Loader.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  position: PropTypes.string
};

Loader.defaultProps = {
  title: '',
  error: false,
  position: 'absolute'
};

export default Loader;
