import React, { useState } from 'react';
import { Form, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

const CREATE_NEW_USER_MUTATION = gql`
  mutation createUser(
    $username: String!
    $name: String!
    $surname: String!
    $companyId: String!
    $role: String!
  ) {
    createUser(
      input: {
        username: $username
        name: $name
        surnname: $surname
        companyId: $companyId
        role: $role
      }
    ) {
      id
    }
  }
`;

const GET_INSURANCE_COMPANIES_QUERY = gql`
  query getInsuranceCompanies {
    insuranceCompanies {
      items {
        id
        name
        thumbnailUrl
      }
    }
  }
`;

function AddUserForm({ toggleModal }) {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const [createNewUserMutation, { loading }] = useMutation(
    CREATE_NEW_USER_MUTATION
  );

  const { data: insuranceCompaniesData } = useQuery(
    GET_INSURANCE_COMPANIES_QUERY
  );
  const insuranceCompanies = insuranceCompaniesData?.insuranceCompanies?.items;

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleOnSubmit = async () => {
    try {
      const { errors } = await createNewUserMutation({
        variables: values
      });
      if (errors && errors.length > 0) {
        toast.error(t('Failed to create user', JSON.stringify(errors)));
      } else {
        toast.success(t('User created'));
      }
      toggleModal();
    } catch (error) {
      toast.error(t('Failed to create user {{ error.message }}', { error }));
    }
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <div className="modal-body">
        <Row>
          <Col>
            <FormGroup>
              <Input
                id="username"
                name="username"
                className="form-control-alternative"
                placeholder={t('email_placeholder')}
                type="text"
                onChange={handleInputChange}
                value={values.username}
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                id="name"
                name="name"
                className="form-control-alternative"
                placeholder={t('name')}
                type="text"
                onChange={handleInputChange}
                value={values.name}
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                id="surname"
                name="surname"
                className="form-control-alternative"
                placeholder={t('surname')}
                type="text"
                onChange={handleInputChange}
                value={values.surname}
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                className="form-control-alternative"
                type="select"
                name="companyId"
                id="companyId"
                onChange={handleInputChange}
                value={values.companyId}
              >
                <option value="" selected>
                  {t('select company', { context: 'enum' })}
                </option>
                {insuranceCompanies &&
                  insuranceCompanies.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                id="role"
                name="role"
                className="form-control-alternative"
                placeholder={t('role')}
                type="text"
                onChange={handleInputChange}
                value={values.role}
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={toggleModal}
        >
          {t('cancel')}
        </Button>
        <Button
          color="success"
          disabled={loading}
          type="button"
          onClick={handleOnSubmit}
        >
          {!loading ? t('create user') : `${t('creating')}...`}
        </Button>
      </div>
    </Form>
  );
}

export default AddUserForm;
