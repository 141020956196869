import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import PublicNavbar from 'components/Navbars/PublicNavbar';
import PublicFooter from 'components/Footers/PublicFooter';
import { Login, Register, Logout } from 'views';
import NotFound from 'views/NotFound';
import ForgottenPassword from 'views/ForgottenPassword';

function PublicLayout() {
  useEffect(() => {
    // componentDidMount
    document.body.classList.add('bg-default');

    // componentWillUnmount
    return () => {
      document.body.classList.remove('bg-default');
    };
  });

  return (
    <>
      <div className="main-content">
        <PublicNavbar />
        <div className="header bg-gradient-info py-7 py-lg-8">
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/forgotten-password" component={ForgottenPassword} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Row>
        </Container>
      </div>
      <PublicFooter />
    </>
  );
}

export default PublicLayout;
