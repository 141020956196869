import React from 'react';
import PropTypes from 'prop-types';

function AvatarImage({ className, src, alt }) {
  return (
    <img
      alt={alt}
      className={className}
      src={src}
    />
  );
}

AvatarImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
}

AvatarImage.defaultProps = {
  className: "",
  src: require('./../assets/img/icons/searpent/default-avatar.png'),
  alt: "",
}

export default AvatarImage;