import React from 'react';
import { Card, CardHeader, Container, Row } from 'reactstrap';
import JobsHeader from 'components/Headers/JobsHeader';
import JobsTable from 'components/Tables/JobsTable';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';

const GET_JOBS = gql`
  query GET_JOBS {
    jobs {
      items {
        id
        active
        frequency
        freqDatetime
        user {
          id
          username
        }
        body {
          extractor
          finder
          matcher
          detector
        }
        updatedAt
      }
    }
  }
`;

function Jobs() {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_JOBS, {
    fetchPolicy: 'no-cache'
  });

  return (
    <>
      <JobsHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0 text-capitalize">{t('jobs')}</h3>
              </CardHeader>
              {error && (
                <div className="dimmer--loader">
                  <div className="dimmer--loader__content">
                    <h5>{t('failed to load data')}</h5>
                  </div>
                </div>
              )}
              {loading && <Loader title={t('loading data')} />}
              {data && <JobsTable items={data.jobs.items} />}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Jobs;
