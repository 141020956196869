import gql from 'graphql-tag';
import jwt from 'jsonwebtoken';
import createApolloClient from './apolloClient';
import isValidJWT from './isValidJWT';

const TOKEN_KEY = 'token';

export const saveToken = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const deleteToken = () => {
  return localStorage.removeItem(TOKEN_KEY);
};

export const isAuthenticated = () => {
  const token = getToken();

  if (token === null) {
    return false;
  }
  if (!isValidJWT(token)) {
    return false;
  }
  return true;
};

export const currentUser = () => {
  const token = getToken();
  if (!token) {
    return null;
  }

  return jwt.decode(token);
};

export const isAdmin = () => {
  if (currentUser()?.role === 'admin') {
    return true;
  }
  return false;
};

export const login = async (username, password, code) => {
  const client = createApolloClient({
    uri: process.env.REACT_APP_LOGIN_API_URL,
    cache: false
  });

  const LOGIN_MUTATION = gql`
    mutation login($username: String!, $password: String!, $code: String) {
      login(username: $username, password: $password, code: $code) {
        user{
          id
          username
          name
          token
        }
        challengeName
      }
    }
  `;

  const vars = {
    username, password
  }

  if (code) {
    vars.code = code
  }

  const loginMutationResult = await client.mutate({
    mutation: LOGIN_MUTATION,
    variables: vars
  });

  if (loginMutationResult?.data?.login.challengeName) {
    return {
      challengeName: loginMutationResult?.data?.login.challengeName
    }
  }

  const user = loginMutationResult.data.login.user;

  // save JWT
  saveToken(user.token);
  return {
    user,
  }
};

export const logout = async () => {
  const client = createApolloClient({
    uri: process.env.REACT_APP_API_URL,
    cache: false
  });

  const LOGOUT_MUTATION = gql`
    mutation logout {
      logout
    }
  `;

  await client.mutate({
    mutation: LOGOUT_MUTATION
  });

  deleteToken();
};

export const changePassword = async (username, oldPassword, newPassword) => {
  const client = createApolloClient({
    uri: process.env.REACT_APP_LOGIN_API_URL,
    cache: false
  });

  const CHANGE_PASSWORD_MUTATION = gql`
    mutation changePassword(
      $username: String!
      $oldPassword: String!
      $newPassword: String!
    ) {
      changePassword(
        username: $username
        oldPassword: $oldPassword
        newPassword: $newPassword
      )
    }
  `;

  await client.mutate({
    mutation: CHANGE_PASSWORD_MUTATION,
    variables: {
      username,
      oldPassword,
      newPassword
    }
  });
};

export const sendVerificationCode = async username => {
  const client = createApolloClient({
    uri: process.env.REACT_APP_LOGIN_API_URL,
    cache: false
  });

  const SEND_VERIFICATION_CODE_MUTATION = gql`
    mutation sendVerificationCode($username: String!) {
      sendVerificationCode(username: $username)
    }
  `;

  await client.mutate({
    mutation: SEND_VERIFICATION_CODE_MUTATION,
    variables: {
      username
    }
  });
};

export const resetPassword = async (username, verificationCode, password) => {
  const client = createApolloClient({
    uri: process.env.REACT_APP_LOGIN_API_URL,
    cache: false
  });

  const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword(
      $username: String!
      $verificationCode: String!
      $password: String!
    ) {
      resetPassword(
        username: $username
        verificationCode: $verificationCode
        password: $password
      )
    }
  `;

  await client.mutate({
    mutation: RESET_PASSWORD_MUTATION,
    variables: {
      username,
      verificationCode,
      password
    }
  });
};
