import React from 'react';
import PropTypes from 'prop-types';
import FormattedPercentage from 'components/FormattedPercentage';

function colorClassByPercentage(percentage) {
  if (percentage <= 0.25) {
    return 'gauge__fill--low';
  }
  if (percentage > 0.25 && percentage <= 0.75) {
    return 'gauge__fill--medium';
  }
  return 'gauge__fill--high';
}

function Gauge({ value }) {
  return (
    <div className="gauge">
      <div className="gauge__body">
        <div
          className={`gauge__fill ${colorClassByPercentage(value)}`}
          style={{
            transform: `rotate(${value / 2}turn)`
          }}
        />
        <div className="gauge__cover">
          <FormattedPercentage value={value} />
        </div>
      </div>
    </div>
  );
}

Gauge.propTypes = {
  value: PropTypes.number.isRequired
};

export default Gauge;
