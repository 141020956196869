import React from 'react';
import { Table, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import limiString from 'utils/limitString';
import Gauge from 'components/Gauge';
import FormattedTimestamp from 'components/FormattedTimestamp';
import Feedback from 'components/Feedback';
import SuspiciousCaseState from 'components/SuspiciousCaseState';

function DetectionTable({ items, error }) {
  const { t } = useTranslation();

  const filteredCaseIds = items.map(item => item.id);
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">
            <span>{t('detail')}</span>
          </th>
          <th scope="col">
            <span>{t('suspicious case')}</span>
          </th>
          <th scope="col">
            <span>{t('match case')}</span>
          </th>
          <th scope="col">
            <span>{t('product')}</span>
          </th>
          <th scope="col">
            <span>{t('matches')}</span>
          </th>
          <th scope="col">
            <span>{t('similarity')}</span>
          </th>
          <th scope="col" className="th--logo">
            <span>{t('insurer')}</span>
          </th>
          <th scope="col" className="th--logo">
            <span>{t('matched case insurer')}</span>
          </th>
          <th scope="col">
            <span>{t('state')}</span>
          </th>
          <th scope="col">
            <span>{t('scenario')}</span>
          </th>
          <th scope="col">
            <span>{t('feedback')}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {items.length === 0 && (
          <tr>
            <td colSpan="100%" className="text-center">
              <h4>{t('nothing to display')}</h4>
            </td>
          </tr>
        )}
        {items.map(item => (
          <tr className="table__row" key={item.id}>
            <td>
              <Link to={{
                pathname: `/detection/${item.id}`,
                state: {
                  filteredCaseIds
                }
              }}>
                <Button color="info">
                  <i className="fas fa-search text-white" />
                </Button>
              </Link>
            </td>
            <td>
              <div>
                <div>
                  <strong>{item.suspiciousCaseId}</strong>
                </div>
                <div>
                  <FormattedTimestamp timestamp={item.suspiciousCaseReportedAt} />
                </div>
                <div>{item.suspiciousCaseAmount}</div>
              </div>
            </td>
            <td>
              <div>
                <div>
                  <strong>{item.matchCaseId}</strong>
                </div>
                <div>
                  <FormattedTimestamp timestamp={item.matchCaseReportedAt} />
                </div>
                <div>
                  <i>{item.matchCaseAmount}</i>
                </div>
              </div>
            </td>
            <td>{t(item.product, { context: 'enum' })}</td>
            <td>{item.matchesCount}</td>
            <td>
              <div className="d-flex align-items-center">
                <div>
                  <div style={{ width: '75px' }}>
                    <Gauge value={item.similarity} />
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div className="td--logo">
               <img
                src={item.insurerThumbnailUrl}
                alt={item.insurerName}
                className="detection-table__incurance-logo"
              />
              </div>
            </td>
            <td >
            <div className="td--logo">
                <img
                src={item.matchedCaseInsurerThumbnailUrl}
                alt={item.matchedCaseInsurerName}
                className="detection-table__incurance-logo"
              />
              </div>
            </td>
            <td><SuspiciousCaseState state={item.state}/></td>
            <td>{limiString(item.scenarioTitle, 30, '...')}</td>
            <td><Feedback feedback={item.feedback} /></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

DetectionTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      suspiciousCaseId: PropTypes.number.isRequired,
      suspiciousCaseAmount: PropTypes.number.isRequired,
      suspiciousCaseDate: PropTypes.string.isRequired,
      product: PropTypes.string.isRequired,
      matchCaseId: PropTypes.number.isRequired,
      matchCaseAmount: PropTypes.number.isRequired,
      matchCaseDate: PropTypes.string.isRequired,
      similarity: PropTypes.number.isRequired,
      insurerThumbnailUrl: PropTypes.string.isRequired,
      insurerName: PropTypes.string.isRequired,
      matchedCaseInsurerThumbnailUrl: PropTypes.string.isRequired,
      matchedCaseInsurerName: PropTypes.string.isRequired
    })
  ).isRequired
};

export default DetectionTable;
