import React, { useEffect } from 'react';
import { NavLink } from 'reactstrap';
import termsAndConditions from 'assets/files/terms-and-conditions.pdf'
import { useTranslation } from 'react-i18next';

function TermsAndConditions() {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(window.close, 0);
  }, [])

  useEffect(() => {
    const el = document.getElementById('download-button')
    el.click()
  }, [])

  return (
    <div style={{
      display: "none"
    }}>
      <NavLink
        id="download-button"
        href={termsAndConditions}
        rel="noopener noreferrer"
        target="_blank"
        download="terms-and-conditions.pdf"
      >
        {t('download')}
      </NavLink>
    </div>
  );
}

export default TermsAndConditions;