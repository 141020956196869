import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardHeader, Container, CardFooter, Row } from 'reactstrap';
import FinderHeader from 'components/Headers/FinderHeader';
import FinderQueryTable from 'components/Tables/FinderQueryTable';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loader from 'components/Loader';
import paginationOptions from 'utils/paginationOptions';
import Pagination from 'components/Pagination';
import { useFilters } from 'utils/useFilters';
import { useTranslation } from 'react-i18next';
import timezoneOffset from 'utils/timezoneOffset';
import { getQueryStringParams, setQueryStringParams } from 'utils/queryString';
import isEmpty from 'utils/isEmpty';
import filterChanged from 'utils/filterChanged';
import resetPaginationTokens from 'utils/resetPaginationTokens';
import tokensDefined from 'utils/tokensDefined';

const GET_FINDER_QUERIES = gql`
  query finderQueries($filter: FinderQueryFilter, $pagination: Pagination) {
    finderQueries(filter: $filter, pagination: $pagination) {
      items {
        id
        name
        definition {
          id
          queryBox {
            label
          }
        }
        productFamily
        status
        results {
          id
        }
        user {
          id
          username
        }
      }
      paginationInfo {
        nextToken
        previousToken
      }
    }
  }
`;

const GET_FINDER_QUERY_STATUS_ENUM = gql`
  query getFinderQueryStatusEnum($enumName: String!) {
    getEnum(enumName: $enumName) {
      enumName
      items {
        key
        displayName
        value
      }
    }
  }
`;

// normalizeFinderQueries flattens finder queries for table rows
function normalizeFinderQueries(finderQueries) {
  return finderQueries.map(finderQuery => ({
    id: finderQuery.id,
    name: finderQuery.name,
    type:
      finderQuery &&
      finderQuery.definition &&
      finderQuery.definition.queryBox &&
      finderQuery.definition.queryBox.label &&
      finderQuery.definition.queryBox.label,
    product: finderQuery.productFamily,
    status: finderQuery.status,
    found: finderQuery.results.length,
    userName: finderQuery.user.username
  }));
}

function createQueryVars(filterValues = {}) {
  return {
    filter: {
      from: filterValues.from
        ? `${filterValues.from}T:00:00:000${timezoneOffset(new Date())}`
        : undefined,
      to: filterValues.to
        ? `${filterValues.to}T:23:59:000${timezoneOffset(new Date())}`
        : undefined,
      status: filterValues.status || undefined,
      productFamily: filterValues.productFamily || undefined
    }
  };
}

function Finder() {
  const { t } = useTranslation();
  const {
    cachePagination,
    getCachedPagination,
    cacheFilter,
    getCachedFilter
  } = useFilters();
  const [pagination, setPagination] = useState(
    getCachedPagination('finder') || {
      limit: paginationOptions()[0].value
    }
  );
  const { data, loading, error, refetch } = useQuery(GET_FINDER_QUERIES, {
    variables: {
      ...createQueryVars(getQueryStringParams()),
      pagination
    }
  });
  const { data: finderQueryStatusEnumData } = useQuery(
    GET_FINDER_QUERY_STATUS_ENUM,
    {
      variables: {
        enumName: 'finderStatus'
      }
    }
  );
  const finderQueryStatuses = finderQueryStatusEnumData?.getEnum?.items;

  const cleanRefetch = useCallback(() => {
    const queryVars = createQueryVars(getQueryStringParams());
    // this checks if filter has changed, and if yes, remove tokens from pagination to assure list will start from beginning
    if (
      filterChanged(getCachedFilter('finder'), getQueryStringParams()) &&
      tokensDefined(pagination)
    ) {
      setPagination(resetPaginationTokens(pagination));
      return;
    }
    refetch({
      ...queryVars,
      pagination
    });
    cacheFilter('finder', getQueryStringParams());
    // eslint-disable-next-line
  }, [cacheFilter, pagination, refetch]);

  const handleFilterClick = () => {
    cleanRefetch();
  };

  useEffect(() => {
    cleanRefetch();
  }, [cleanRefetch, pagination]);

  useEffect(() => {
    if (
      isEmpty(getQueryStringParams()) &&
      !isEmpty(getCachedFilter('finder'))
    ) {
      setQueryStringParams(getCachedFilter('finder'));
      cleanRefetch();
    }
  });

  const handlePaginationChange = ({ name, value }) => {
    const newPagination = {
      limit: pagination.limit,
      [name]: value
    };
    cachePagination('finder', newPagination);
    setPagination(newPagination);
  };

  const { nextToken, previousToken } =
    data?.finderQueries?.paginationInfo || {};
  const { limit } = pagination;

  return (
    <>
      <FinderHeader
        handleFilterClick={handleFilterClick}
        finderQueryStatuses={finderQueryStatuses}
        loading={loading}
      />
      <Container className="mt--7" fluid>
        <Row className="mb-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0 text-capitalize">{t('finder')}</h3>
              </CardHeader>
              {error && (
                <div className="dimmer--loader">
                  <div className="dimmer--loader__content">
                    <h5>{t('failed to load data')}</h5>
                  </div>
                </div>
              )}
              {loading && <Loader title={t('loading data')} />}
              {data && (
                <FinderQueryTable
                  items={normalizeFinderQueries(data.finderQueries.items)}
                  loading={loading}
                  error={error}
                />
              )}
              <CardFooter>
                <Pagination
                  limit={limit}
                  nextToken={nextToken}
                  previousToken={previousToken}
                  limitOptions={paginationOptions()}
                  onChange={handlePaginationChange}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Finder;
