/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { isAuthenticated } from 'utils/auth';

function PrivateRoute({ component: Component, ...rest }) {
  const canAccess = isAuthenticated();
  const { pathname } = useLocation();

  return (
    <Route
      {...rest}
      render={props =>
        canAccess ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: `?ref=${pathname}`
            }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default PrivateRoute;
