function previousIndex(currentIndex) {
  if (currentIndex === null || currentIndex === undefined) {
    return null;
  }

  const idx = currentIndex - 1;
  if (idx < 0) {
    return null;
  }

  return idx;
}

export default previousIndex;
