import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import { getQueryStringParams, setQueryStringParams } from 'utils/queryString';

const productFamilyOptions = [
  {
    value: 'cars',
    label: 'cars'
  },
  {
    value: 'invoices',
    label: 'invoices'
  },
  {
    value: 'houses',
    label: 'houses'
  },
  {
    value: 'queries',
    label: 'queries'
  }
];

function displayScenario(title, description) {
  if (title && !description) {
    return title;
  }
  if (!title && description) {
    return description;
  }
  if (!title && !description) {
    return `ERROR: scenario title not defined`;
  }
  return `${title}: ${description}`;
}

function DetectionHeader({
  handleFilterClick,
  insuranceCompanies,
  scenarios,
  states,
  loading
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useState(getQueryStringParams);

  const refreshSearchParams = () => {
    setSearchParams(qs.parse(window.location.search));
  };

  const updateSearchParamsInUrl = searchParams => {
    setQueryStringParams(searchParams);
    refreshSearchParams();
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    const newSearchParams = {
      ...searchParams,
      [name]: value
    };
    updateSearchParamsInUrl(newSearchParams);
  };

  const handleResetClick = () => {
    const newSearchParams = {};
    Object.keys(searchParams).forEach(key => (newSearchParams[key] = ''));
    updateSearchParamsInUrl(newSearchParams);
    handleFilterClick();
  };

  const handleApplyClick = () => {
    handleFilterClick();
  };

  useEffect(() => {
    setTimeout(() => refreshSearchParams(), 100);
  }, []);

  const handleKeyPressed = e => {
    if (e.key === 'Enter') {
      handleApplyClick();
    }
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="12">
                <Card className="card-stats mb-4 mb-xl-0 bg-secondary">
                  <CardBody>
                    <Row onKeyPress={handleKeyPressed}>
                      <Col xs="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label h5"
                            htmlFor="from"
                          >
                            <span>{t('reported from')}</span>
                          </Label>
                          <Input
                            className="from"
                            id="from"
                            name="from"
                            type="date"
                            onChange={handleInputChange}
                            value={searchParams.from}
                            max={moment().format('YYYY-MM-DD')}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label  h5"
                            htmlFor="to"
                          >
                            <span>{t('reported to')}</span>
                          </Label>
                          <Input
                            max={moment().format('YYYY-MM-DD')}
                            className="to"
                            id="to"
                            name="to"
                            type="date"
                            onChange={handleInputChange}
                            value={searchParams.to}
                            max={moment().format('YYYY-MM-DD')}
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col xs="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label"
                            htmlFor="minDamageAmount"
                          >
                            <span>{t('min amount')}</span>
                          </Label>
                          <Input
                            className="minDamageAmount"
                            id="minDamageAmount"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label"
                            htmlFor="maxDamageAmount"
                          >
                            <span>{t('max amount')}</span>
                          </Label>
                          <Input
                            className="maxDamageAmount"
                            id="maxDamageAmount"
                            type="number"
                          />
                        </FormGroup>
                      </Col> */}
                      <Col xs="2">
                        <FormGroup>
                          <Label
                            id="similarity"
                            className="form-control-Label h5"
                            htmlFor="similarity"
                          >
                            <span>{t('similarity')}</span>
                            <span className="h6 mt-0 ml-1 text-lowercase">
                              ({t('at least')} %){' '}
                              <i
                                className="fa fa-question-circle"
                                aria-hidden="true"
                              />
                            </span>
                          </Label>
                          <UncontrolledTooltip
                            delay={0}
                            placement="bottom"
                            target="similarity"
                          >
                            <small>{t('enter_between_0_100')}</small>
                          </UncontrolledTooltip>
                          <Input
                            className="similarity"
                            id="similarity"
                            type="number"
                            name="similarity"
                            onChange={handleInputChange}
                            value={searchParams.similarity}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label h5"
                            htmlFor="status"
                          >
                            <span>{t('product family')}</span>
                          </Label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            name="productFamily"
                            id="productFamily"
                            onChange={handleInputChange}
                            value={searchParams.productFamily}
                          >
                            {productFamilyOptions &&
                              productFamilyOptions.map(item => (
                                <option key={item.value} value={item.value}>
                                  {t(item.label, { context: 'enum' })}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label h5"
                            htmlFor="state"
                          >
                            <span>{t('state')}</span>
                          </Label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            name="state"
                            id="state"
                            onChange={handleInputChange}
                            value={searchParams.state}
                          >
                            <option key="" value="" />
                            {states &&
                              states.map(s => (
                                <option key={s.key} value={s.key}>
                                  {t(
                                    `suspicious_case_state_${s.displayName.toLowerCase()}`
                                  )}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label h5"
                            htmlFor="hasFeedback"
                          >
                            <span>{t('feedback')}</span>
                          </Label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            name="hasFeedback"
                            id="hasFeedback"
                            onChange={handleInputChange}
                            value={searchParams.hasFeedback}
                          >
                            <option key="" value="" />
                            {[
                              { key: true, value: 'yes' },
                              { key: false, value: 'no' }
                            ].map(s => (
                              <option key={s.key} value={s.key}>
                                {t(s.value)}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row onKeyPress={handleKeyPressed}>
                      <Col xs="2">
                        <FormGroup>
                          <Label
                            id="claimId"
                            className="form-control-Label h5"
                            htmlFor="claimId"
                          >
                            <span>{t('claim id')}</span>
                          </Label>
                          <Input
                            id="claimId"
                            type="text"
                            name="claimId"
                            onChange={handleInputChange}
                            value={searchParams.claimId}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label h5"
                            htmlFor="insurerId"
                          >
                            <span>{t('insurer')}</span>
                          </Label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            name="insurerId"
                            id="insurerId"
                            onChange={handleInputChange}
                            value={searchParams.insurerId}
                          >
                            <option value="" selected>
                              {t('all', { context: 'enum' })}
                            </option>
                            {insuranceCompanies &&
                              insuranceCompanies.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <Label
                            className="form-control-Label h5"
                            htmlFor="matchedCaseInsurerId"
                          >
                            <span>{t('matched case insurer')}</span>
                          </Label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            name="matchedCaseInsurerId"
                            id="matchedCaseInsurerId"
                            onChange={handleInputChange}
                            value={searchParams.matchedCaseInsurerId}
                          >
                            <option value="" selected>
                              {t('all', { context: 'enum' })}
                            </option>
                            {insuranceCompanies &&
                              insuranceCompanies.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs="4">
                        <FormGroup>
                          <Label
                            className="form-control-Label h5"
                            htmlFor="scenario"
                          >
                            <span>{t('scenario')}</span>
                          </Label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            name="scenarioId"
                            id="scenarioId"
                            onChange={handleInputChange}
                            value={searchParams.scenarioId}
                          >
                            <option value="" selected>
                              {t('all', { context: 'enum' })}
                            </option>
                            {scenarios &&
                              scenarios.map(item => (
                                <option key={item.id} value={item.id}>
                                  {displayScenario(
                                    item.title,
                                    item.description
                                  )}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col
                        className="d-flex align-items-center justify-content-center"
                        xs="2"
                      >
                        <Button
                          color="primary"
                          outline
                          onClick={handleResetClick}
                        >
                          {t('reset')}
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          onClick={handleApplyClick}
                          disabled={loading}
                        >
                          <span className="text-uppercase">
                            {t('filter', { context: 'verb' })}
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

DetectionHeader.propTypes = {
  values: PropTypes.object.isRequired,
  handleFilterValueChange: PropTypes.func.isRequired,
  handleFilterClick: PropTypes.func.isRequired,
  handleClearFilterClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  insuranceCompanies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  scenarios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ),
  states: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
};

export default DetectionHeader;
