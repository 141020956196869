import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { passwordStrength } from 'utils/passwordStrength';
import { useTranslation } from 'react-i18next';

function PasswordStrength({ password }) {
  const { t } = useTranslation();
  const strength = passwordStrength(password);

  return (
    <span className={classnames(
      'font-weight-700',
      {
        'text-success': strength === 'strong'
      },
      {
        'text-danger': strength === 'weak'
      },
    )}>{t(strength)}</span>
  );
}

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired
}

export default PasswordStrength;