import React from 'react';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormattedTimestamp from 'components/FormattedTimestamp';

function CaseTable({ items }) {
  const { t } = useTranslation();

  const filteredCaseIds = items.map(item => item.id);
  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col"><span>{t('claim id')}</span></th>
          <th scope="col"><span>{t('created at')}</span></th>
          <th scope="col"><span>{t('product family')}</span></th>
          <th scope="col">#<span>{t('images')}</span></th>
          <th scope="col"><span>{t('insurer')}</span></th>
        </tr>
      </thead>
      <tbody>
        {items.length === 0 && (
          <tr>
            <td colSpan="100%" className="text-center">
              <h4>{t('nothing to display')}</h4>
            </td>
          </tr>
        )}
        {items.map(item => (
          <tr className="table__row" key={item.id}>
            <td>
              <Link to={{
                pathname: `/cases/${item.id}`,
                state: {
                  filteredCaseIds
                }
              }}>
                <span className="table__tr__td__link">{item.claimId}</span>
              </Link>
            </td>
            <td><FormattedTimestamp timestamp={item.createdAt} /></td>
            <td>{t(item.productFamily, { context: 'enum' })}</td>
            <td>{item.images.length}</td>
            <td className="td--logo">
              <img
                className="detection-table__incurance-logo"
                src={item.insuranceCompany.thumbnailUrl}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

CaseTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      claimId: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      productFamily: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired
        })
      ).isRequired,
      insuranceCompany: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          thumbnailUrl: PropTypes.string.isRequired
        })
      )
    })
  ).isRequired
};

CaseTable.defaultProps = {
  insurerLogo: 'TBD' // TODO: define placeholder
};

export default CaseTable;
