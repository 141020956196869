import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Label
} from 'reactstrap';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import { getQueryStringParams, setQueryStringParams } from 'utils/queryString';

const productFamilyOptions = [
  {
    value: 'cars',
    label: 'cars'
  },
  {
    value: 'invoices',
    label: 'invoices'
  },
  {
    value: 'houses',
    label: 'houses'
  },
  {
    value: 'queries',
    label: 'queries'
  }
];

function CasesHeader({ handleFilterClick, insuranceCompanies, loading }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useState(getQueryStringParams);

  const refreshSearchParams = () => {
    setSearchParams(qs.parse(window.location.search));
  };

  const updateSearchParamsInUrl = searchParams => {
    setQueryStringParams(searchParams);
    refreshSearchParams();
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    const newSearchParams = {
      ...searchParams,
      [name]: value
    };
    updateSearchParamsInUrl(newSearchParams);
  };

  const handleResetClick = () => {
    const newSearchParams = {};
    Object.keys(searchParams).forEach(key => (newSearchParams[key] = ''));
    updateSearchParamsInUrl(newSearchParams);
    handleFilterClick();
  };

  const handleApplyClick = () => {
    handleFilterClick();
  };

  useEffect(() => {
    setTimeout(() => refreshSearchParams(), 100);
  }, []);

  const handleKeyPressed = e => {
    if (e.key === 'Enter') {
      handleApplyClick();
    }
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="12">
                <Card className="card-stats mb-4 mb-xl-0 bg-secondary">
                  <CardBody>
                    <Row onKeyPress={handleKeyPressed}>
                      <Col>
                        <FormGroup>
                          <Label
                            className="form-control-Label"
                            htmlFor="claimId"
                          >
                            <span >{t('claim id')}</span>
                          </Label>
                          <Input
                            id="claimId"
                            name="claimId"
                            type="text"
                            onChange={handleInputChange}
                            value={searchParams.claimId}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label className="form-control-Label" htmlFor="from">
                            <span >{t('created from')}</span>
                          </Label>
                          <Input
                            className="from"
                            id="from"
                            name="from"
                            type="date"
                            onChange={handleInputChange}
                            value={searchParams.from}
                            max={moment().format('YYYY-MM-DD')}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label className="form-control-Label" htmlFor="to">
                            <span >{t('created to')}</span>
                          </Label>
                          <Input
                            max={moment().format('YYYY-MM-DD')}
                            className="to"
                            id="to"
                            name="to"
                            type="date"
                            onChange={handleInputChange}
                            value={searchParams.to}
                            max={moment().format('YYYY-MM-DD')}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label
                            className="form-control-Label"
                            htmlFor="status"
                          >
                            <span >{t('product family')}</span>
                          </Label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            name="productFamily"
                            id="productFamily"
                            onChange={handleInputChange}
                            value={searchParams.productFamily}
                          >
                            {productFamilyOptions &&
                              productFamilyOptions.map(item => (
                                <option key={item.value} value={item.value}>
                                  {t(item.label, { context: 'enum' })}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label
                            className="form-control-Label"
                            htmlFor="insurerId"
                          >
                            <span >{t('insurer')}</span>
                          </Label>
                          <Input
                            className="form-control-alternative"
                            type="select"
                            name="insurerId"
                            id="insurerId"
                            onChange={handleInputChange}
                            value={searchParams.insurerId}
                          >
                            <option value="" selected>
                              {t('all', { context: 'enum' })}
                            </option>
                            {insuranceCompanies &&
                              insuranceCompanies.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col className="d-flex align-items-center justify-content-center">
                        <Button
                          color="primary"
                          outline
                          onClick={handleResetClick}
                        >
                          {t('reset')}
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          onClick={handleApplyClick}
                          disabled={loading}
                        >
                          <span className="text-uppercase">
                            {t('filter', { context: 'verb' })}
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CasesHeader.propTypes = {
  handleFilterClick: PropTypes.func.isRequired
};

export default CasesHeader;
