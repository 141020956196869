function badgeColorByTaskStatus(status) {
  switch (status) {
    case 'NONE':
      return 'bg-default';
    case 'QUEUED':
      return 'bg-info';
    case 'REJECTED':
      return 'bg-danger';
    case 'RUNNING':
      return 'bg-warning'
    case 'FINISHED':
      return 'bg-success';
    case 'FAILED':
      return 'bg-danger'
    default:
      return 'bg-default';
  }
}

export default badgeColorByTaskStatus