import React, { useState } from 'react';
import { Form, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sendVerificationCode } from 'utils/auth';
import { toast } from 'react-toastify';

function SendVerificationCodeForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      await sendVerificationCode(values.email);
      history.push({
        pathname: '/forgotten-password',
        search: `?email=${values.email}`
      });
      toast.success(t('Verification code sent'));
    } catch (error) {
      toast.error(
        t('Failed to send verification code: {{ error.message }}', { error })
      );
    }
    setLoading(false);
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <div className="modal-body">
        <Row>
          <Col>
            <FormGroup>
              <Input
                id="email"
                name="email"
                className="form-control-alternative"
                placeholder={t('email')}
                type="email"
                onChange={handleInputChange}
                value={values.email}
                autoFocus
              />
              <p className="text-center">
                <small>
                  {t('verification code will be sent to this email')}
                </small>
              </p>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer justify-content-center">
        <Button
          color="success"
          type="button"
          onClick={handleOnSubmit}
          disabled={loading}
        >
          {t('send code')}
        </Button>
      </div>
    </Form>
  );
}

export default SendVerificationCodeForm;
