import React from 'react';
import {
  Card,
  CardBody,
  Row,
  Col
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

function NotFound() {
  const { t } = useTranslation();

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col>
                <h1 className="text-center text-uppercase">{t('Page not found')}</h1>
              </Col>
            </Row>

          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default NotFound;
