function paginationOptions() {
  return [
    {
      value: 15,
      label: '15'
    },
    {
      value: 30,
      label: '30'
    },
    {
      value: 60,
      label: '60'
    }
  ];
}

export default paginationOptions;
