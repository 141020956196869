import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { HashLink as Link } from 'react-router-hash-link';
import reactStringReplace from 'react-string-replace';
import classnames from 'classnames';
import AvatarImage from 'components/AvatarImage';

function Comment({ comment: { id, author, text, updatedAt }, orientation }) {

  return (
    <div
      className="list-group-item-action flex-column align-items-start py-3 px-3 list-group-item"
      id={id}
      key={id}
    >
      <div
        className={classnames('d-flex w-100 justify-content-between', {
          'comment__header--left': orientation === 'left',
          'comment__header--right': orientation === 'right'
        })}
      >
        <div>
          <div
            className={classnames('d-flex w-100 align-items-center', {
              'comment__header--left': orientation === 'left',
              'comment__header--right': orientation === 'right'
            })}
          >
            <div>
              <AvatarImage src={author?.avatarUrl} className="avatar avatar-xs mr-2 ml-2" />
            </div>
            <div
              className={classnames('d-flex flex-column', {
                'text-left': orientation === 'left',
                'text-right': orientation === 'right'
              })}
            >
              <h4 className={classnames('mb-0')}>{author?.name} {author?.surname}</h4>
              <small className="comment__date">
                {moment(updatedAt).format('DD-MM-YYYY HH:mm')}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classnames('comment__text_wrapper', {
          'comment__text_wrapper--left': orientation === 'left',
          'comment__text_wrapper--right': orientation === 'right'
        })}
      >
        <div className="comment__text">
          <p className="text-sm mb-0 mt-1 white-space-pre-line">
            {reactStringReplace(text, /(#[0-9]*)/g, (match, i) => (
              <Link smooth to={`${match}`}>
                {match}
              </Link>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
}

Comment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    author: PropTypes.shape({
      avatarUrl: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      surname: PropTypes.string.isRequired
    }).isRequired,
    updatedAt: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  orientation: PropTypes.string
};

Comment.defaultProps = {
  orientation: 'left'
};

export default Comment;
