/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Card } from 'reactstrap';
import ImageGalleryItem from 'components/ImageGalleryItem';
import ModalWithNavigation from 'components/ModalWithNavigation';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import stringToColor from 'utils/stringToColor';
import modelResultsToLabeledBoundingBoxes from 'utils/modelResultsToLabeledBoundingBoxes';
import FormattedPercentage from 'components/FormattedPercentage';
import ImageWithHighlightedArea from 'components/ImageWithHighlightedArea';
import classnames from 'classnames';
import { useAccount } from 'utils/useAccount';

function nextIndex(currentIndex, nOfItems) {
  if (currentIndex + 1 < nOfItems) {
    return currentIndex + 1;
  }
  return null;
}

function previousIndex(currentIndex) {
  if (currentIndex - 1 >= 0) {
    return currentIndex - 1;
  }
  return null;
}

function ImageGallery({
  items,
  className,
  error,
  loading,
  pagination,
  onShowMoreClick
}) {
  const { t } = useTranslation();
  const { devMode } = useAccount();
  const [focusedImageIndex, setFocusedImageIndex] = useState(null);
  const [highlightedAreas, setHighlightedAreas] = useState([]);

  const handleThumbnailClick = idx => {
    setFocusedImageIndex(idx);
  };

  const handleChevronClick = idx => {
    setFocusedImageIndex(idx);
  };

  useEffect(() => {
    if (!isNaN(focusedImageIndex)) {
      let sanitizedFocusedImageIndex = focusedImageIndex;
      if (sanitizedFocusedImageIndex > items.length) {
        sanitizedFocusedImageIndex = 0;
      }
      if (items.length === 0) {
        sanitizedFocusedImageIndex = null;
      }
      const focusedImage = !isNaN(sanitizedFocusedImageIndex)
        ? items[sanitizedFocusedImageIndex]
        : null;
      const bboxes = focusedImage?.modelResults
        ? modelResultsToLabeledBoundingBoxes(focusedImage.modelResults)
        : [];
      const newHighlightedAreas = bboxes
        ? bboxes.map(bbox => ({
            id: `${bbox.model}:${bbox.label}`,
            name: `${bbox.label}`,
            percentage: bbox.score,
            shape: 'rect',
            relativeCoords:
              bbox && bbox.box
                ? [bbox.box.x1, bbox.box.y1, bbox.box.x2, bbox.box.y2]
                : null,
            preFillColor: `rgba(${stringToColor(
              `${bbox.model}:${bbox.label}`
            )}, 0.5)`,
            fillColor: `rgba(${stringToColor(
              `${bbox.model}:${bbox.label}`
            )}, 0.75)`,
            strokeColor: `rgba(${stringToColor(
              `${bbox.model}:${bbox.label}`
            )})`,
            lineWidth: 1,
            enabled: false
          }))
        : [];

      setHighlightedAreas(newHighlightedAreas);
    }
  }, [focusedImageIndex, items]);

  const handleHighlightedAreaToggle = areaId => {
    const updatedAreas = highlightedAreas.map(area => ({
      ...area,
      enabled: area.id === areaId ? !area.enabled : area.enabled
    }));
    setHighlightedAreas(updatedAreas);
  };

  const handleShowAllToggle = () => {
    const visibleAreas = highlightedAreas.filter(area => area.enabled === true);
    if (visibleAreas.length === highlightedAreas.length) {
      const allAreas = highlightedAreas.map(area => ({
        ...area,
        enabled: false
      }));
      setHighlightedAreas(allAreas);
    } else {
      const allAreas = highlightedAreas.map(area => ({
        ...area,
        enabled: true
      }));
      setHighlightedAreas(allAreas);
    }
  };

  let sanitizedFocusedImageIndex = focusedImageIndex;
  if (sanitizedFocusedImageIndex > items.length) {
    sanitizedFocusedImageIndex = 0;
  }
  if (items.length === 0) {
    sanitizedFocusedImageIndex = null;
  }
  const focusedImage = !isNaN(sanitizedFocusedImageIndex)
    ? items[sanitizedFocusedImageIndex]
    : null;
  const focusedImageUrl =
    focusedImageIndex !== null ? items[focusedImageIndex].imageUrl : null;
  const focusedImageFilename =
    focusedImageIndex !== null ? items[focusedImageIndex]?.filename : null;
  const nextImageIndex = nextIndex(focusedImageIndex, items.length);
  const previousImageIndex = previousIndex(focusedImageIndex);
  const visibleAreas = highlightedAreas.filter(area => area.enabled === true);

  if (loading) {
    return <Loader title={t('loading data')} position="relative" />;
  }

  if (error) {
    return (
      <Loader title={t('failed to load data')} position="relative" error />
    );
  }

  return (
    <div>
      <div className={className}>
        {items.map((item, idx) => (
          <ImageGalleryItem
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            imageUrl={item.thumbnailUrl}
            onClick={() => handleThumbnailClick(idx)}
          />
        ))}
      </div>
      <div className="d-flex align-items-center">
        <span
          className="case-detail__image-gallery__show-more ml-auto mr-auto"
          onClick={onShowMoreClick}
        >
          {pagination === null ? t('show less') : t('show more')}
        </span>
      </div>
      {focusedImageUrl && (
        <ModalWithNavigation
          showNavigation
          onLeftClick={
            previousImageIndex !== null
              ? () => handleChevronClick(previousImageIndex)
              : null
          }
          onRightClick={
            nextImageIndex !== null
              ? () => handleChevronClick(nextImageIndex)
              : null
          }
          onClose={() => setFocusedImageIndex(null)}
          title={`${focusedImageFilename} (${focusedImageIndex + 1}/${
            items.length
          })`}
        >
          <div className="modal-body modal-max-width modal-80-width pl-5 pr-5">
            <Row
              className={classnames({
                'd-none': !devMode()
              })}
            >
              <Col>
                <div className="d-flex justify-content-between">
                  <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                    <input
                      className="custom-control-input "
                      id="customCheck5"
                      type="checkbox"
                      checked={
                        visibleAreas.length === highlightedAreas.length &&
                        highlightedAreas.length > 0
                      }
                      disabled={highlightedAreas.length < 1}
                      onClick={() => handleShowAllToggle()}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck5"
                    >
                      {t('show bounding boxes')}
                    </label>
                  </div>
                  <div>
                    <a
                      href={`${focusedImageUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="link">{t('open in new tab')}</span>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row
              className={classnames({
                'd-none': !devMode()
              })}
            >
              <Col>
                {highlightedAreas.map(area => (
                  <Button
                    type="button"
                    className="mb-2 ml-0"
                    onClick={() => handleHighlightedAreaToggle(area.id)}
                    style={{
                      backgroundColor: area.enabled && area.strokeColor
                    }}
                  >
                    {area.name} <FormattedPercentage value={area.percentage} />
                  </Button>
                ))}
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card className="shadow">
                  <ImageWithHighlightedArea
                    imageUrl={focusedImageUrl}
                    originalImageWidth={focusedImage.dimensions.width}
                    originalImageHeight={focusedImage.dimensions.height}
                    highlightedAreas={visibleAreas}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </ModalWithNavigation>
      )}
    </div>
  );
}

ImageGallery.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnailUrl: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired
    })
  ).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  pagination: PropTypes.number,
  onShowMoreClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

ImageGallery.defaultProps = {
  loading: false,
  error: false,
  pagination: null,
  className: null
};

export default ImageGallery;
