import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, concat } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import isValidJWT from './isValidJWT';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { getToken } from 'utils/auth';

const noCacheOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

function wsAddress() {
  if (process.env.REACT_APP_WS_API_URL.startsWith("ws")) {
    return process.env.REACT_APP_WS_API_URL
  }
  if (window.location.protocol.includes('https')) {
    return `wss://${window.location.host}${process.env.REACT_APP_WS_API_URL}`
  }
  return `ws://${window.location.host}${process.env.REACT_APP_WS_API_URL}`
}

function createWsLink() {
  try {
    const wsLink = new WebSocketLink({
      uri: wsAddress(),
      options: {
        reconnect: false,
        connectionParams: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    });
    return wsLink;
  } catch (error) {
    console.log(`[GraphQL error]: failed to create ws connection: ${error.message}`)
    return null
  }

}

const createApolloClient = ({ uri, cache }) => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return forward(operation);
  });

  const uploadLink = createUploadLink({
    uri: uri || process.env.REACT_APP_API_URL,
    credentials: 'same-origin',
    headers: {
      'keep-alive': 'true'
    }
  });
  const wsLink = createWsLink();

  let link = uploadLink;
  if (wsLink !== null) {
    link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      uploadLink,
    );
  }

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) {
          console.error(`[Network error]: ${networkError}`);
          if (networkError.statusCode === 401 && !isValidJWT(localStorage.getItem('token'))) {
            window.location.replace("/login");
          }
        }
      }),
      concat(authMiddleware, link)
    ]),
    cache: new InMemoryCache(),
    defaultOptions: !cache && noCacheOptions
  });

  return client;
};

export default createApolloClient;
