import React from 'react';
import PropTypes from 'prop-types';
import { useAccount } from 'utils/useAccount';
import formatTimestamp from 'utils/formatTimestamp';

function FormattedTimestamp({ timestamp, format }) {
  const { account, devMode } = useAccount();
  return <>{formatTimestamp(timestamp, devMode() ? 'YYYY-MM-DD HH:mm:ss' : format, account?.timezone)}</>;
}

FormattedTimestamp.propTypes = {
  timestamp: PropTypes.string.isRequired,
  format: PropTypes.string
};

FormattedTimestamp.defaultProps = {
  format: null
};

export default FormattedTimestamp;
