function pad(value) {
  return value < 10 ? '0' + value : value;
}
function timezoneOffset(date) {
  var sign = (date.getTimezoneOffset() > 0) ? "-" : "+";
  var offset = Math.abs(date.getTimezoneOffset());
  var hours = pad(Math.floor(offset / 60));
  var minutes = pad(offset % 60);
  return sign + hours + ":" + minutes;
}

export default timezoneOffset;