import React, { useState } from 'react';
import TaskGroup from 'components/TaskGroup';
import PropTypes from 'prop-types';
import { Row, Col, Button, Alert } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function JobEditor({
  getTaskSkeleton,
  validateJobBody,
  runJob,
  onUpdateJob,
  job: initialJob,
  loading
}) {
  const { t } = useTranslation();
  const [job, setJob] = useState(initialJob);
  const [validationErrors, setValidationErrors] = useState([]);

  const addTaskToGroup = (groupName, task) => {
    const jobBody = job.body;
    const taskGroup = jobBody[groupName];
    if (typeof taskGroup.tasks !== 'object') {
      taskGroup.tasks = [];
    }
    taskGroup.tasks.push(task);
    jobBody[groupName] = taskGroup;
    setJob({
      ...job,
      body: jobBody
    });
  };

  const addTask = async groupName => {
    try {
      const taskSkeleton = await getTaskSkeleton(groupName);
      addTaskToGroup(groupName, taskSkeleton);
    } catch (error) {
      toast.error(
        t('Add task failed : {{ errorMessage }}', {
          errorMessage: error.message
        })
      );
    }
  };

  const removeTask = (groupName, taskId) => {
    const jobBody = JSON.parse(JSON.stringify(job.body));
    const taskGroup = jobBody[groupName];
    const newTaskGroup = taskGroup.tasks.filter(task => task.id !== taskId);
    jobBody[groupName].tasks = newTaskGroup;
    setJob({
      ...job,
      body: jobBody
    });
  };

  const handleValidateJobClick = async () => {
    try {
      const { errors } = await validateJobBody(job);
      if (errors && errors.length > 0) {
        setValidationErrors(errors);
      }
    } catch (error) {
      toast.error(
        t('Validation failed: {{ errorMessage }}', {
          errorMessage: error.message
        })
      );
    }
  };

  const handleSaveJobClick = () => {
    try {
      onUpdateJob(job);
      toast.success(t('Job updated'));
    } catch (error) {
      toast.error(t('Job failed to update'));
    }
  };

  const handleRunJobClick = jobId => {
    try {
      runJob(jobId);
      toast.success(t('Job run success'));
    } catch (error) {
      toast.error(t('Job failed to run'));
    }
  };

  const handleTaskChange = (groupName, jsonBody) => {
    const taskId = jsonBody.id;
    const taskGroup = job.body[groupName];
    const foundTaskIndex = taskGroup.tasks.findIndex(
      task => task.id === taskId
    );
    if (foundTaskIndex < 0) {
      console.error('updated task not found');
      return;
    }
    taskGroup.tasks[foundTaskIndex] = jsonBody;
    setJob({
      ...job,
      body: {
        ...job.body,
        [groupName]: taskGroup
      }
    });
  };

  const taskGroups = Object.keys(job?.body).map(key => ({
    name: key,
    tasks: job.body[key]?.tasks || []
  }));

  return (
    <>
      {validationErrors.length > 0 &&
        validationErrors.map(validationError => (
          <Row className="w-100" key={validationError.message}>
            <Col className="d-flex">
              <Alert color="danger" className="w-100">
                {validationError.message}
              </Alert>
            </Col>
          </Row>
        ))}
      <Row className="w-100">
        <Col className="d-flex justify-content-end">
          <Button
            className="ml-2"
            color="info"
            onClick={handleValidateJobClick}
            disabled={loading}
          >
            {t('validate job')}
          </Button>
          <Button
            className="ml-2"
            color="primary"
            onClick={handleSaveJobClick}
            disabled={loading}
          >
            {t('update job')}
          </Button>
          <Button
            className="ml-2 btn-icon"
            color="success"
            disabled={loading}
            onClick={() => handleRunJobClick(job.id)}
          >
            <span className="btn-inner--icon">
              <i className="fas fa-play" />
            </span>
            <span className="btn-inner--text">{t('run job')}</span>
          </Button>
        </Col>
      </Row>
      {taskGroups.map(taskGroup => (
        <TaskGroup
          key={taskGroup.key}
          taskGroup={taskGroup}
          addTask={addTask}
          onTaskChange={handleTaskChange}
          removeTask={removeTask}
        />
      ))}
    </>
  );
}

JobEditor.propTypes = {
  getTaskSkeleton: PropTypes.func.isRequired,
  validateJobBody: PropTypes.func.isRequired,
  onUpdateJob: PropTypes.func.isRequired,
  runJob: PropTypes.func.isRequired
};

export default JobEditor;
