function stringToBoolean(s) {
  switch (s) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
}

export default stringToBoolean;
