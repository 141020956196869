function isNotFoundError(error) {
  if (
    error.message === 'GraphQL error: record not found' ||
    error.message === 'GraphQL error: error converting caseId'
  ) {
    return true;
  }
  return false;
}

export default isNotFoundError;
