import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import * as _ from 'lodash';
import SuspiciousCasesTableRow from './SuspiciousCasesTableRow';
import { useTranslation } from 'react-i18next';

function withMetadata(suspiciousCases) {
  return Object.keys(suspiciousCases).map(function (key, index) {
    const scenarios = suspiciousCases[key].map(suspiciousCase => suspiciousCase.scenarioTitle)
    const similarities = suspiciousCases[key].map(suspiciousCase => suspiciousCase.similarity)
    return {
      matchCaseClaimId: key,
      suspiciousCases: suspiciousCases[key],
      maxSimilarity: _.max(similarities),
      uniqueScenarios: _.uniq(scenarios)
    }
  });
}

function SuspiciousCasesTable({ suspiciousCases }) {
  const { t } = useTranslation();
  const groupedSuspiciousCases = _.groupBy(suspiciousCases, c => c.matchCaseClaimId);
  const groupedSuspiciousCasesWithMetadata = withMetadata(groupedSuspiciousCases);

  return (
    <Table className="align-items-center table-flush" style={{
      tableLayout: 'fixed',
    }}>
      <thead className="thead-semidark">
        <tr>
          <th scope="col" className='th--sticky-top' width="5%"> </th>
          <th scope="col" className='th--sticky-top' width="15%">{t('matched case')}</th>
          <th scope="col" className='th--sticky-top' width="15%">{t('scenarios')}</th>
          <th scope="col" className='th--sticky-top' width="15%">{t('max similarity')}</th>
          <th scope="col" className='th--sticky-top' width="50%">{t('max similarity susp case')}</th>
        </tr>
      </thead>
      <tbody>
        {
          groupedSuspiciousCasesWithMetadata && groupedSuspiciousCasesWithMetadata.length > 0 && groupedSuspiciousCasesWithMetadata.map((suspiciousCasesGroup) => (
            <SuspiciousCasesTableRow suspiciousCasesGroup={suspiciousCasesGroup} />
          ))
        }
      </tbody>
    </Table>
  );
}

SuspiciousCasesTable.propTypes = {
  suspiciousCases: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SuspiciousCasesTable;