import React from 'react';
import { Modal, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from './ChangePasswordForm';

function ChangePasswordModal({ onClose }) {
  const { t } = useTranslation();

  return (
    <Modal
      className="modal-dialog-centered modal--fullscreen max-width-90vw"
      isOpen
      toggle={onClose}
    >
      <div className="modal-header">
        <h5 className="modal-title text-capitalize">{t('change password')}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden>×</span>
        </button>
      </div>
      <div className="modal-body modal-max-width modal-80-width pl-5 pr-5">
        <Row>
          <Col>
            <ChangePasswordForm toggleModal={onClose} />
          </Col>
        </Row>
      </div>
      <div className="modal-footer" />
    </Modal>
  );
}

export default ChangePasswordModal;
