import React, { useState, useCallback } from 'react';
import {
  Button,
  Badge,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import MFAModal from 'components/MFAModal';
import { toast } from 'react-toastify';
import { useAccount } from 'utils/useAccount';
import MFAStatusBadge from './MFAStatusBadge';

function MFASettings() {
  const { t } = useTranslation();
  const { account, setupTOTP, verifyTOTP, disableTOTP } = useAccount();
  const [qrCode, setQrCode] = useState('');

  const generateQRCode = useCallback(async () => {
    const code = await setupTOTP();
    setQrCode(code);
  }, [setupTOTP]);

  const enableMFA = useCallback(
    async passcode => {
      try {
        await verifyTOTP(passcode);
        toast.success(t('settings_mfa_mfa_activate_success'))
        setQrCode('');
      } catch (error) {
        toast.error(`${t('settings_mfa_mfa_activate_fail')}: ${error.message}`)
      }
    },
    [t, verifyTOTP]
  );

  const disableMFA = useCallback(async () => {
    try {
      await disableTOTP();
      toast.success(t('settings_mfa_mfa_deactivate_success'))
      setQrCode('');
    } catch (error) {
      toast.error(`${t('settings_mfa_mfa_deactivate_fail')}: ${error.message}`)
    }
  }, [disableTOTP, t]);

  const mfaEnabled = account?.mfaStatus === 'ACTIVE';

  return (
    <>
      <Card className="card-stats mb-4 mb-xl-0">
        <CardHeader>
          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
            {t('security')}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <p>{t('mfa')}</p>
            </Col>
            <Col>
              <MFAStatusBadge status={account?.mfaStatus} />
            </Col>
            <Col>
              <Button
                color="success"
                type="button"
                size="sm"
                outline
                disabled={mfaEnabled}
                onClick={generateQRCode}
              >
                {t('settings_mfa_enable')}
              </Button>

              <Button
                color="danger"
                type="button"
                size="sm"
                outline
                disabled={!mfaEnabled}
                onClick={disableMFA}
              >
                {t('settings_mfa_disable')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <MFAModal
        qrCode={qrCode}
        toggleModal={() => setQrCode('')}
        onSubmit={enableMFA}
      />
    </>
  );
}

export default MFASettings;
