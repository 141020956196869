function modelResultsToTableWithCategory(modelResultsGroups) {
  const results = [];

  for (const modelResultsGroup of modelResultsGroups) {
    for (const result of modelResultsGroup.results) {
      results.push({
        model: modelResultsGroup.name,
        label: result.label,
        score: result.score,
      })
    }
  }

  return results;
}

export default modelResultsToTableWithCategory;
