import React, { useState } from 'react';
import { Button, Card, CardBody, Col, UncontrolledAlert } from 'reactstrap';
import { logout, isAuthenticated } from 'utils/auth';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Logout(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [logoutError, setLogoutError] = useState(null);
  const isLoggedIn = isAuthenticated();
  const { history } = props;

  const handleOnSubmit = async () => {
    setIsLoading(true);
    try {
      await logout();
      setIsLoading(false);
      setIsLoggedOut(true);
      setLogoutError(null);
    } catch (error) {
      setIsLoading(false);
      setLogoutError(error);
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {logoutError && (
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-circle-08" />
                </span>{' '}
                {t('failed to log out')}
              </UncontrolledAlert>
            )}
            {isLoggedOut && (
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-circle-08" />
                </span>{' '}
                {t('you have been successfully logged out')}
              </UncontrolledAlert>
            )}
            <div className="text-center text-muted mb-4">
              {isLoggedIn ? (
                <small>{t('do you really want to logout')}?</small>
              ) : (
                  <small> {t('you are not logged in')}.</small>
                )}
            </div>
            <div className="text-center">
              {isLoggedIn ? (
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={handleOnSubmit}
                  disabled={isLoading || !isAuthenticated()}
                >
                  {isLoading ? t('logging out') : t('log out')}
                </Button>
              ) : (
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={() => {
                      history.push('/login');
                    }}
                  >
                    {t('log me in')}
                  </Button>
                )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

Logout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default Logout;
