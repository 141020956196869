import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SendVerificationCodeForm from 'components/SendVerificationCodeForm';
import ForgottenPasswordForm from 'components/ForgottenPasswordForm';
import qs from 'query-string';

function getEmailFromSearchParams(search) {
  const params = qs.parse(search);
  return params.email;
}

function ForgottenPassword(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const email = getEmailFromSearchParams(history.location.search);
  const codeSent = !!email;


  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>{t('Forgotten password')}</small>
            </div>
            {!codeSent ? (
              <SendVerificationCodeForm />
            ) : (
              <ForgottenPasswordForm email={email} />
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

ForgottenPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default ForgottenPassword;
