import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ModalWithNavigation from 'components/ModalWithNavigation';
import ImageWithHighlightedArea from 'components/ImageWithHighlightedArea';
import labelScoreToHighlightedArea from 'utils/labelScoreToHighlightedArea';
import { useChatbox } from 'utils/useChatbox';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const GET_MATCH_QUERY = gql`
query getMatch($id: ID!){
	match(id: $id) {
		id
    similarity
    queryImage{
      id
      imageUrl
      dimensions{
        width
        height
      }
    }
    queryImageResult{
      label
      score
      box{
        x1
        x2
        y1
        y2
      }
    }
    matchImage{
      id
      imageUrl
      dimensions{
        width
        height
      }
    }
      matchImageResult{
      label
      score
      box{
        x1
        x2
        y1
        y2
      }
    }
	}
}`

function MatchDetail({
  showNavigation,
  onLeftClick,
  onRightClick,
  onClose,
  matchId,
  title,
  postTitle,
  clickableHighlightedArea
}) {
  const { t } = useTranslation();
  const { appendToInput } = useChatbox();
  const {data, loading, error} = useQuery(GET_MATCH_QUERY, {
    variables: {
      id: matchId,
    }
  })
  const focusedMatch = data?.match || {};
  const { matchImageResult, queryImageResult } = focusedMatch;
  const matchImageHighlightedArea = matchImageResult
    ? labelScoreToHighlightedArea(matchImageResult)
    : null;
  const queryImageHighlightedArea = queryImageResult
    ? labelScoreToHighlightedArea(queryImageResult)
    : null;
  const [showBBoxes, setShowBBoxes] = useState(true);

  return (
    <ModalWithNavigation
      showNavigation={showNavigation}
      onLeftClick={onLeftClick}
      onRightClick={onRightClick}
      onClose={onClose}
      title={title}
      postTitle={postTitle}
    >
      <div className="modal-body modal-max-width modal-80-width pl-5 pr-5">
        <Row>
          <Col>
            <div className="d-flex justify-content-between">
              <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                <input
                  className="custom-control-input"
                  id="customCheck5"
                  type="checkbox"
                  checked={showBBoxes}
                  onClick={() => setShowBBoxes(prevState => !prevState)}
                />
                <label className="custom-control-label" htmlFor="customCheck5">
                  {t('show bounding boxes')}
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <Card className="shadow">
              {
                data && (
                  <ImageWithHighlightedArea
                imageUrl={focusedMatch.queryImage.imageUrl}
                originalImageWidth={focusedMatch.queryImage.dimensions.width}
                originalImageHeight={focusedMatch.queryImage.dimensions.height}
                highlightedAreas={
                  (queryImageHighlightedArea && showBBoxes) ? [queryImageHighlightedArea] : null
                }
                clickable={clickableHighlightedArea}
              />)
              }
            </Card>
          </Col>
          <Col lg="6">
            <Card className="shadow">
              {
                data && (
                  <ImageWithHighlightedArea
                imageUrl={focusedMatch.matchImage.imageUrl}
                originalImageWidth={focusedMatch.matchImage.dimensions.width}
                originalImageHeight={focusedMatch.matchImage.dimensions.height}
                highlightedAreas={
                  (matchImageHighlightedArea && showBBoxes) ? [matchImageHighlightedArea] : null
                }
              />)
              }
            </Card>
          </Col>
        </Row>
        <Row className="pt-2">
          <Col xs="12" className="d-flex justify-content-center">
            <Button
              color="primary"
              outline
              onClick={() => appendToInput(`#${matchId}`)}
            >
              <i className="fa fa-at" aria-hidden="true" />
              {t('mention')}
            </Button>
          </Col>
        </Row>
      </div>
    </ModalWithNavigation>
  );
}

MatchDetail.propTypes = {
  showNavigation: PropTypes.bool.isRequired,
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  matchId: PropTypes.string.isRequired,
  onCommentSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  postTitle: PropTypes.string,
  clickableHighlightedArea: PropTypes.bool,
};

MatchDetail.defaultProps = {
  showNavigation: true,
  onLeftClick: () => console.log('left clicked'),
  onRightClick: () => console.log('right clicked'),
  title: null,
  clickableHighlightedArea: true,
};

export default MatchDetail;
