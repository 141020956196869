import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Table, Card } from 'reactstrap';
import Gauge from 'components/Gauge';
import { useTranslation } from 'react-i18next';

function SuspiciousCasesTableRow(
  { suspiciousCasesGroup: { matchCaseClaimId, maxSimilarity, uniqueScenarios, suspiciousCases } }
) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(preVal => setIsOpen(!preVal));
  }

  return (
    <>
      <tr key={matchCaseClaimId}>
        <td scope="row">
          <div
            style={{
              marginRight: '1rem',
              width: '1rem'
            }}
            className="table-row__chevron"
            onClick={toggle}
          >
            <i className={classnames('fas a-chevron-down', {
              'fa-chevron-down': !isOpen,
              'fa-chevron-up': isOpen
            })} />
          </div>
        </td>
        <td scope="row">
          <Link to={`/cases/${suspiciousCases[0].matchCaseId}`}>{matchCaseClaimId}</Link>
        </td>
        <td scope="row">
          {uniqueScenarios ? uniqueScenarios.length : 0}
        </td>
        <td scope="row">
          <div className="d-flex align-items-center">
            <div>
              <Link to={`/detection/${suspiciousCases[0].id}`}>
                <div style={{ width: '60px' }}>
                  <Gauge value={maxSimilarity} />
                </div>
              </Link>

            </div>
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div>
              <Link to={`/detection/${suspiciousCases[0].id}`}>
                <span style={{ width: '60px', marginRight: '1rem' }}>
                  {suspiciousCases[0].id}
                </span>
              </Link>
              <span className="font-italic">(scenario: {suspiciousCases[0].scenarioTitle})</span>
            </div>
          </div>
        </td>
      </tr>
      {
        isOpen && (
          <tr>
            <td style={{
              border: 0
            }}></td>
            <td colspan="3" style={{
              border: 0
            }}>
              <div style={{
                width: '100%'
              }}>
                <Card>
                  <Table className="align-items-center table-flush" style={{
                  }}>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className='th--sticky-top'>{t('match id')}</th>
                        <th scope="col" className='th--sticky-top'>{t('scenario')}</th>
                        <th scope="col" className='th--sticky-top'>{t('similarity')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        suspiciousCases && suspiciousCases.map(({ id, matchCaseId, matchCaseClaimId, scenarioTitle, similarity }) => (
                          <tr key={id} style={{
                            backgroundColor: 'white'
                          }}>
                            <th scope="row">
                              <Link to={`/detection/${id}`}>{id}</Link>
                            </th>
                            <td>
                              {scenarioTitle}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <div style={{ width: '60px' }}>
                                    <Gauge value={similarity} />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </Card>
              </div>
            </td>
          </tr>
        )
      }
    </>
  );
}

export default SuspiciousCasesTableRow;