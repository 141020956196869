import React from 'react';
import { Table, Badge, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import badgeColorByStatus from 'utils/badgeColorByStatus';
import { useTranslation } from 'react-i18next';

function FinderQueryTable({ items }) {
  const { t } = useTranslation();

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">{t('detail')}</th>
          <th scope="col">{t('name')}</th>
          <th scope="col">{t('type')}</th>
          <th scope="col">{t('product')}</th>
          <th scope="col">{t('status')}</th>
          <th scope="col">{t('found')}</th>
          <th scope="col">{t('user')}</th>
        </tr>
      </thead>
      <tbody>
        {items.length === 0 && (
          <tr>
            <td colSpan="100%" className="text-center">
              <h4>{t('nothing to display')}</h4>
            </td>
          </tr>
        )}
        {items.map(item => (
          <tr key={item.id} className="table__row">
            <td>
              <Link
                to={{
                  pathname: `/finder/${item.id}`
                }}
              >
                <Button color="info">
                  <i className="fas fa-search text-white" />
                </Button>
              </Link>
            </td>
            <td>{item.name}</td>
            <td>{t(item.type)}</td>
            <td>{t(item.product, { context: 'enum' })}</td>
            <td>
              <Badge
                color={badgeColorByStatus(item.status)}
                onClick={e => e.preventDefault()}
              >
                {t(item.status, { context: 'enum' })}
              </Badge>
            </td>
            <td>{item.found}</td>
            <td>{item.userName}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

FinderQueryTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      product: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      found: PropTypes.number.isRequired,
      userName: PropTypes.string.isRequired
    })
  ).isRequired
};

export default FinderQueryTable;
