import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import AdminFooter from 'components/Footers/AdminFooter';
import Sidebar from 'components/Sidebar/Sidebar';
import routes from 'routes';
import {
  Dashboard,
  Cases,
  CaseDetail,
  Detection,
  DetectionDetail,
  Finder,
  FinderDetail,
  FinderEdit,
  Tasks,
  Jobs,
  JobDetail,
  Settings
} from 'views';
import { useAccount } from 'utils/useAccount';

function Admin() {
  const { account } = useAccount();

  return (
    <>
      <Sidebar
        routes={routes}
        logo={{
          innerLink: '/',
          imgSrc: require('assets/img/brand/argon-react.png'),
          imgAlt: '...'
        }}
        role={account?.role}
      />
      <div className="main-content">
        <AdminNavbar />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/cases" component={Cases} />
          <Route exact path="/cases/:id" component={CaseDetail} />
          <Route exact path="/detection" component={Detection} />
          <Route exact path="/detection/:id" component={DetectionDetail} />
          <Route exact path="/finder" component={Finder} />
          <Route exact path="/finder/:id" component={FinderDetail} />
          <Route exact path="/finder/:id/edit" component={FinderEdit} />
          <Route exact path="/tasks" component={Tasks} />
          <Route exact path="/jobs" component={Jobs} />
          <Route exact path="/jobs/:id" component={JobDetail} />
          <Route exact path="/settings" component={Settings} />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
}

export default Admin;
