import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardHeader, Collapse, Button } from 'reactstrap';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { useTranslation } from 'react-i18next';

function TaskEditor({ id, jsonBody, onChange, groupName, onRemoveTask }) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleTask = () => {
    setIsOpen(!isOpen);
  }

  const handleChange = (o) => {
    const { jsObject } = o;
    if (jsObject) {
      onChange(groupName, jsObject);
    }
  }

  const handleDeleteTaskClick = (groupName, taskId) => {
    onRemoveTask(groupName, taskId);
  }

  const caretIcon = isOpen ? "fas fa-caret-up" : "fas fa-caret-down";

  return (
    <Card className="shadow w-100 mb-3" >
      <CardHeader onClick={toggleTask}>
        <CardTitle className="mb-0 d-flex flex-row justify-content-between">
          <h3 className="mb-0 d-inline">{id}</h3>
          <h3><i class={caretIcon}></i></h3>
        </CardTitle>
      </CardHeader>

      <Collapse isOpen={isOpen}>
        <CardBody>
          <JSONInput
            id={id}
            placeholder={jsonBody}
            theme="light_mitsuketa_tribute"
            width="100%"
            height="300px"
            locale={locale}
            onChange={handleChange}
          />
          <div className="d-flex justify-content-end pt-4">
            <Button
              className="ml-2"
              color="danger"
              size="sm"
              onClick={() => handleDeleteTaskClick(groupName, id)}
            >
              {t('delete task')}
            </Button>
          </div>
        </CardBody>
      </Collapse>
    </Card >
  );
}

TaskEditor.propTypes = {
  id: PropTypes.string.isRequired,
  jsonBody: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TaskEditor;