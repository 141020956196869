import React, { useState } from 'react';
import {
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  UncontrolledTooltip,
  Label
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'utils/useAccount';
import { changePassword } from 'utils/auth';
import { isPasswordStrong } from 'utils/passwordStrength';
import { toast } from 'react-toastify';
import PasswordStrength from './PasswordStrength';

function ChangePasswordForm({ toggleModal }) {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const {
    account: { username }
  } = useAccount();

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      if (!values.oldPassword || !values.password || !values.passwordCheck) {
        throw new Error(t('all passwords must be filled'));
      }
      if (values.password !== values.passwordCheck) {
        throw new Error(t('passwords are not same'));
      }
      if (!isPasswordStrong(values.password)) {
        throw new Error(t('password is not strong'));
      }
      await changePassword(username, values.oldPassword, values.password);
      toast.success(t('Password changed'));
      toggleModal();
    } catch (error) {
      toast.error(
        t('Failed to change password: {{ error.message }}', { error })
      );
    }
    setLoading(false);
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <div className="modal-body">
        <Row>
          <Col>
            <Label>{t('old_password')}</Label>
            <FormGroup>
              <Input
                id="oldPassword"
                name="oldPassword"
                className="form-control-alternative"
                placeholder={t('old_password_placeholder')}
                type="password"
                onChange={handleInputChange}
                value={values.oldPassword}
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>{t('new_password')}</Label>
            <FormGroup>
              <Input
                id="password"
                name="password"
                className="form-control-alternative"
                placeholder={t('password_placeholder')}
                type="password"
                onChange={handleInputChange}
                value={values.password}
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>{t('new_password_check')}</Label>
            <FormGroup>
              <Input
                id="passwordCheck"
                name="passwordCheck"
                className="form-control-alternative"
                placeholder={t('password_check_placeholder')}
                type="password"
                onChange={handleInputChange}
                value={values.passwordCheck}
                autoFocus
              />
            </FormGroup>
            <div id="password-strength" className="text-muted font-italic">
              <small>
                {t('password strength')}:{' '}
                <PasswordStrength password={values.password} />{' '}
                <i
                  id="password-strength-tooltip"
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </small>
            </div>
            <UncontrolledTooltip
              delay={0}
              placement="bottom"
              target="password-strength-tooltip"
            >
              <small>{t('password requirements')}</small>
            </UncontrolledTooltip>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button
          color="success"
          disabled={loading}
          type="button"
          onClick={handleOnSubmit}
        >
          {!loading ? t('change password') : `${t('changing')}...`}
        </Button>
      </div>
    </Form>
  );
}

export default ChangePasswordForm;
