import React from 'react';
import PropTypes from 'prop-types';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Input
} from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

function PaginationBar({ limit, nextToken, previousToken, limitOptions, onChange }) {
  const { t } = useTranslation();
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    onChange({ name, value })
  }

  return (
    <nav aria-label="Page navigation example">
      <Pagination
        className="pagination justify-content-center"
        listClassName="justify-content-center align-items-center"
      >
        <PaginationItem className={classnames({
          'disabled': !previousToken
        })}>
          <PaginationLink onClick={(e) => handleOnChange({
            target: {
              name: "previousToken",
              value: previousToken
            }
          })}>
            <i className="fa fa-angle-left" />
            <span className="sr-only">{t('previous')}</span>
          </PaginationLink>

        </PaginationItem>
        <Input
          className="form-control-alternative ml-3 mr-3"
          type="select"
          name="limit"
          id="limit"
          value={limit}
          onChange={(e) => handleOnChange(e)}
        >
          {
            limitOptions.map(({ value, label }) => (
              <option key={value} value={value}>{label}</option>
            ))
          }
        </Input>

        <PaginationItem className={classnames({
          'disabled': !nextToken
        })}>
          <PaginationLink onClick={(e) => handleOnChange({
            target: {
              name: "nextToken",
              value: nextToken
            }
          })}>
            <i className="fa fa-angle-right" />
            <span className="sr-only">{t('next')}</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </nav>
  );
}

PaginationBar.propTypes = {
  limit: PropTypes.number.isRequired,
  nextToken: PropTypes.string,
  previousToken: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  limitOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }))

}

export default PaginationBar;