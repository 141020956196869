import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardHeader, CardFooter, Container, Row } from 'reactstrap';
import DetectionHeader from 'components/Headers/DetectionHeader';
import DetectionTable from 'components/Tables/DetectionTable';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loader from 'components/Loader';
import paginationOptions from 'utils/paginationOptions';
import Pagination from 'components/Pagination';
import { useFilters } from 'utils/useFilters';
import { useTranslation } from 'react-i18next';
import timezoneOffset from 'utils/timezoneOffset';
import { getQueryStringParams, setQueryStringParams } from 'utils/queryString';
import isEmpty from 'utils/isEmpty';
import filterChanged from 'utils/filterChanged';
import resetPaginationTokens from 'utils/resetPaginationTokens';
import tokensDefined from 'utils/tokensDefined';
import stringToBoolean from 'utils/stringToBoolean';

const GET_SUSPICIOUS_CASES = gql`
  query suspiciousCases(
    $productFamily: String!
    $filter: SuspiciousCaseFilter
    $pagination: Pagination
  ) {
    suspiciousCases(
      productFamily: $productFamily
      filter: $filter
      pagination: $pagination
    ) {
      items {
        id
        feedback {
          falsePositive
          toInvestigate
          isFraud
        }
        foundMatches
        scenario {
          id
          title
          description
        }
        queryCase {
          id
          claimId
          reportedAt
          accidentAt
          damageAmount
          productFamily
          insuranceCompany {
            id
            name
            thumbnailUrl
          }
        }
        matchCase {
          id
          claimId
          reportedAt
          damageAmount
          insuranceCompany {
            id
            name
            thumbnailUrl
          }
        }
        similarity
        state
      }
      paginationInfo {
        nextToken
        previousToken
      }
    }
  }
`;

const GET_INSURANCE_COMPANIES = gql`
  query getInsuranceCompanies {
    insuranceCompanies {
      items {
        id
        name
        thumbnailUrl
      }
    }
  }
`;

const GET_SCENARIOS = gql`
  query {
    scenarios {
      items {
        id
        title
        description
      }
    }
  }
`;

const GET_STATES = gql`
  query {
    getEnum(enumName: "states") {
      enumName
      items {
        key
        displayName
        value
      }
    }
  }
`;

// normalizeSuspiciousCases flattens suspicious cases for table rows
function normalizeSuspiciousCases(suspiciousCases) {
  return suspiciousCases.map(suspiciousCase => ({
    id: suspiciousCase.id,
    suspiciousCaseId: suspiciousCase.queryCase.claimId,
    suspiciousCaseAmount: suspiciousCase.queryCase.damageAmount,
    suspiciousCaseReportedAt: suspiciousCase.queryCase.reportedAt,
    suspiciousCaseAccidentAt: suspiciousCase.queryCase.accidentAt,
    product: suspiciousCase.queryCase.productFamily,
    matchCaseId: suspiciousCase.matchCase.claimId,
    matchCaseAmount: suspiciousCase.matchCase.damageAmount,
    matchCaseReportedAt: suspiciousCase.matchCase.reportedAt,
    similarity: suspiciousCase.similarity,
    insurerThumbnailUrl: suspiciousCase.queryCase.insuranceCompany.thumbnailUrl,
    insurerName: suspiciousCase.queryCase.insuranceCompany.name,
    matchedCaseInsurerThumbnailUrl:
      suspiciousCase.matchCase.insuranceCompany.thumbnailUrl,
    matchedCaseInsurerName: suspiciousCase.matchCase.insuranceCompany.name,
    scenarioTitle: suspiciousCase.scenario.title,
    matchesCount: suspiciousCase?.foundMatches || '-',
    feedback: suspiciousCase.feedback,
    state: suspiciousCase.state
  }));
}

function createQueryVars(filterValues = {}) {
  return {
    productFamily: filterValues.productFamily || 'cars',
    filter: {
      from: filterValues.from
        ? `${filterValues.from}T:00:00:000${timezoneOffset(new Date())}`
        : undefined,
      to: filterValues.to
        ? `${filterValues.to}T:23:59:000${timezoneOffset(new Date())}`
        : undefined,
      claimId: filterValues.claimId || undefined,
      insurerId: filterValues.insurerId || undefined,
      matchedCaseInsurerId: filterValues.matchedCaseInsurerId || undefined,
      state: filterValues?.state || undefined,
      // minDamageAmount: filterValues.minDamageAmount || undefined,
      // maxDamageAmount: filterValues.maxDamageAmount || undefined,
      scenarioId: filterValues.scenarioId || undefined,
      similarity: parseInt(filterValues.similarity, 10) || undefined,
      hasFeedback: stringToBoolean(filterValues.hasFeedback)
    }
  };
}

function Detection() {
  const { t } = useTranslation();
  const {
    cachePagination,
    getCachedPagination,
    cacheFilter,
    getCachedFilter
  } = useFilters();
  const [pagination, setPagination] = useState(
    getCachedPagination('detection') || {
      limit: paginationOptions()[0].value
    }
  );
  const { data, loading, error, refetch } = useQuery(GET_SUSPICIOUS_CASES, {
    variables: {
      ...createQueryVars(getQueryStringParams()),
      pagination
    }
  });
  const { data: insuranceCompaniesData } = useQuery(GET_INSURANCE_COMPANIES);
  const insuranceCompanies = insuranceCompaniesData?.insuranceCompanies?.items;

  const { data: scenariosData } = useQuery(GET_SCENARIOS);
  const scenarios = scenariosData?.scenarios?.items;

  const { data: statesData } = useQuery(GET_STATES);
  const states = statesData?.getEnum?.items;

  const cleanRefetch = useCallback(() => {
    const queryVars = createQueryVars(getQueryStringParams());
    // this checks if filter has changed, and if yes, remove tokens from pagination to assure list will start from beginning
    if (
      filterChanged(getCachedFilter('detection'), getQueryStringParams()) &&
      tokensDefined(pagination)
    ) {
      setPagination(resetPaginationTokens(pagination));
      return;
    }
    refetch({
      ...queryVars,
      pagination
    });
    cacheFilter('detection', getQueryStringParams());
    // eslint-disable-next-line
  }, [cacheFilter, pagination, refetch]);

  const handleFilterClick = () => {
    cleanRefetch();
  };

  useEffect(() => {
    cleanRefetch();
  }, [cleanRefetch, pagination]);

  useEffect(() => {
    if (
      isEmpty(getQueryStringParams()) &&
      !isEmpty(getCachedFilter('detection'))
    ) {
      setQueryStringParams(getCachedFilter('detection'));
      cleanRefetch();
    }
  });

  const handlePaginationChange = ({ name, value }) => {
    const newPagination = {
      limit: pagination.limit,
      [name]: value
    };
    cachePagination('detection', newPagination);
    setPagination(newPagination);
  };

  const { nextToken, previousToken } =
    data?.suspiciousCases?.paginationInfo || {};
  const { limit } = pagination;

  return (
    <>
      <DetectionHeader
        handleFilterClick={handleFilterClick}
        insuranceCompanies={insuranceCompanies}
        scenarios={scenarios}
        states={states}
        loading={loading}
      />
      <Container className="mt--7" fluid>
        <Row className="mb-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0 text-capitalize">{t('detection')}</h3>
              </CardHeader>
              {error && (
                <div className="dimmer--loader">
                  <div className="dimmer--loader__content">
                    <h5>{t('failed to load data')}</h5>
                  </div>
                </div>
              )}
              {loading && <Loader title={t('loading data')} />}
              {data && (
                <DetectionTable
                  items={normalizeSuspiciousCases(data.suspiciousCases.items)}
                  loading={loading}
                  error={error}
                />
              )}
              <CardFooter>
                <Pagination
                  limit={limit}
                  nextToken={nextToken}
                  previousToken={previousToken}
                  limitOptions={paginationOptions()}
                  onChange={handlePaginationChange}
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Detection;
