/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import SuspiciousCasesModal from 'components/SuspiciousCasesModal';

const GET_SUSPICIOUS_CASES = gql`
  query suspiciousCases(
    $productFamily: String!
    $filter: SuspiciousCaseFilter
    $pagination: Pagination
  ) {
    suspiciousCases(
      productFamily: $productFamily
      filter: $filter
      pagination: $pagination
    ) {
      items {
        id
      }
    }
  }
`;

function FoundInDetection({ claimId, productFamily, parentLoading }) {
  const { t } = useTranslation();
  const [showSuspiciousCases, setShowSuspiciousCases] = useState(false);
  const [getSuspiciousCases, { data, loading }] = useLazyQuery(
    GET_SUSPICIOUS_CASES
  );

  // when component rendered and claimId defined, check if at least one suspiciousCase available
  useEffect(() => {
    if (claimId && productFamily && !parentLoading) {
      getSuspiciousCases({
        variables: {
          filter: {
            claimId
          },
          productFamily,
          pagination: {
            limit: 1
          }
        }
      });
    }
  }, [claimId, productFamily, parentLoading, getSuspiciousCases]);

  return (
    <>
      <span>
        {parentLoading || loading ? (
          <Skeleton />
        ) : (
          <>
            {data?.suspiciousCases?.items?.length > 0 ? (
              <span
                className="show-comments-text text-lowercase"
                onClick={() => setShowSuspiciousCases(true)}
              >
                {t('show comments')}
              </span>
            ) : (
              <span className="h5">{t('nothing to display')}</span>
            )}
          </>
        )}
      </span>
      {showSuspiciousCases && (
        <SuspiciousCasesModal
          onClose={() => setShowSuspiciousCases(false)}
          productFamily={productFamily}
          filter={{
            claimId
          }}
        />
      )}
    </>
  );
}

FoundInDetection.propTypes = {
  claimId: PropTypes.string,
  productFamily: PropTypes.string,
  parentLoading: PropTypes.bool
};

FoundInDetection.defaultProps = {
  claimId: null,
  productFamily: 'cars',
  parentLoading: false
};

export default FoundInDetection;
