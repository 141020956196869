import moment from 'moment-timezone';

const DEFAULT_FORMATTING = 'YYYY MMM DD'
const DEFAULT_TIMEZONE = 'Etc/UTC'

export default function formatTimestamp(timestamp, formatting, timezone) {
  let format = formatting || DEFAULT_FORMATTING
  let tz = timezone || DEFAULT_TIMEZONE
  return moment(timestamp).tz(tz).format(format);
}
