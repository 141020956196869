function badgeColorByStatus(status) {
  switch (status) {
    case 'DRAFT':
      return 'primary'
    case 'RUNNING':
      return 'warning'
    case 'QUEUED':
      return 'info'
    case 'FINISHED':
      return 'success'
    case 'FAILED':
      return 'danger'
    default:
      return 'secondary'
  }
}

export default badgeColorByStatus;