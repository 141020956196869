import React from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function JobDetailHeader({
  jobId,
  active,
  frequency,
  frequencyDatetime }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="header bg-gradient-info pb-6 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <h1 className="d-inline mr-5 line">{jobId}</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <div className="d-flex">
                          <div className="d-flex flex-column align-items-center p-4">
                            <i className="far fa-file-alt mb-3 text--15" />
                            <h2>{
                              active ? t('yes') : t('no')
                            }</h2>
                            <h6>{t('active')}</h6>
                          </div>
                          <div className="d-flex flex-column align-items-center p-4">
                            <i className="far fa-calendar-alt mb-3 text--15" />
                            <h2>{moment(frequencyDatetime).format("YYYY-MM-DD HH:mm:ss")}</h2>
                            <h6>{t('frequency datetime')}</h6>
                          </div>
                          <div className="d-flex flex-column align-items-center p-4">
                            <i className="far fa-money-bill-alt mb-3 text--15" />
                            <h2>{frequency}</h2>
                            <h6>{t('frequency')}</h6>
                          </div>

                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

JobDetailHeader.propTypes = {
  jobId: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  frequency: PropTypes.string.isRequired,
  frequencyDatetime: PropTypes.string.isRequired,
};

export default JobDetailHeader;
