function limitString(s, limit, suffix) {
  if (!s || !limit) {
    return s
  }
  if (s.length < limit) {
    return s
  }
  const sub = s.substring(0, limit);
  if (suffix !== undefined) {
    return `${sub}${suffix}`
  }
  return sub
}

export default limitString;