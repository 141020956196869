import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import PropTypes from 'prop-types';
import { Button, CardTitle } from 'reactstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const keyMap = {
  FALSE_POSITIVE: 'f',
  TO_INVESTIGATE: 'i'
};

const GET_SUSPICIOUS_CASE_FEEDBACK_QUERY = gql`
  query getFeedback($suspiciousCaseId: ID!) {
    suspiciousCase(id: $suspiciousCaseId) {
      id
      feedback {
        falsePositive
        toInvestigate
        isFraud
      }
    }
  }
`;

const UPDATE_FEEDBACK_MUTATION = gql`
  mutation updateFeedback($updateFeedbackInput: UpdateFeedbackInput!) {
    updateFeedback(input: $updateFeedbackInput) {
      falsePositive
      toInvestigate
      isFraud
    }
  }
`;

function EditableFeedback({ suspiciousCaseId }) {
  const { t } = useTranslation();
  const { loading, error, data, refetch } = useQuery(
    GET_SUSPICIOUS_CASE_FEEDBACK_QUERY,
    {
      variables: { suspiciousCaseId }
    }
  );
  const [updateFeedback, { loading: updateFeedbackLoading }] = useMutation(
    UPDATE_FEEDBACK_MUTATION
  );
  const { feedback } = data?.suspiciousCase || {};

  // handlers
  const feedbackToggleClick = async name => {
    // calculate new feedback
    const newFeedbackValue = !feedback[name];
    const newFeedback = {};
    const feedbackKeys = Object.keys(feedback).filter(
      key => key !== '__typename'
    );
    for (const feedbackKey of feedbackKeys) {
      newFeedback[feedbackKey] = false;
    }
    newFeedback[name] = newFeedbackValue;
    const inputValues = {
      id: suspiciousCaseId,
      entityType: 'SUSPCASE',
      ...newFeedback
    };
    try {
      await updateFeedback({
        variables: {
          updateFeedbackInput: {
            ...inputValues
          }
        }
      });
      toast.success(t('Case updated'));
      refetch();
    } catch (err) {
      toast.error(t('Case update failed'));
    }
  };

  const hotKeysHandlers = {
    FALSE_POSITIVE: () => feedbackToggleClick('falsePositive'),
    TO_INVESTIGATE: () => feedbackToggleClick('toInvestigate')
  };

  return (
    <div>
      <GlobalHotKeys keyMap={keyMap} handlers={hotKeysHandlers} allowChanges>
        <CardTitle tag="h5" className=" text-muted">
          <span>{t('mark suspicious case as')}</span>
        </CardTitle>
        <Button
          color={feedback?.falsePositive ? 'info' : 'secondary'}
          type="button"
          id="falsePositive"
          name="falsePositive"
          disabled={updateFeedbackLoading}
          onClick={() => feedbackToggleClick('falsePositive')}
        >
          <span>{t('false positive')}</span>
        </Button>
        <Button
          color={feedback?.toInvestigate ? 'info' : 'secondary'}
          type="button"
          id="toInvestigate"
          name="toInvestigate"
          disabled={updateFeedbackLoading}
          onClick={() => feedbackToggleClick('toInvestigate')}
        >
          <span>{t('to investigate')}</span>
        </Button>
        <Button
          color={feedback?.isFraud ? 'info' : 'secondary'}
          type="button"
          id="isFraud"
          name="isFraud"
          disabled={updateFeedbackLoading}
          onClick={() => feedbackToggleClick('isFraud')}
        >
          <span>{t('is fraud')}</span>
        </Button>
        <Button
          color={false ? 'info' : 'secondary'}
          type="button"
          name="exiferror"
          disabled
        >
          <span>{t('share')}</span>
        </Button>
      </GlobalHotKeys>
    </div>
  );
}

EditableFeedback.propTypes = {
  suspiciousCaseId: PropTypes.string.isRequired
};

export default EditableFeedback;
