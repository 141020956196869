import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from 'reactstrap';
import LanguageSelector from 'components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'utils/useAccount';
import { isAdmin } from 'utils/auth';
import AddUserModal from 'components/AddUserModal';
import ChangePasswordModal from 'components/ChangePasswordModal';

import AvatarImage from 'components/AvatarImage';
import BreadCrumbs from './BreadCrumbs';

function AdminNavbar() {
  const { t } = useTranslation();
  const { account } = useAccount();
  const { name, surname } = account;
  const admin = isAdmin();
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { push } = useHistory();

  const toggleNewUserModal = () => {
    setShowNewUserModal(prevState => setShowNewUserModal(!prevState));
  };

  const toggleChangePasswordModal = () => {
    setShowChangePasswordModal(prevState =>
      setShowChangePasswordModal(!prevState)
    );
  };

  const redirectTo = link => {
    push(link);
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <BreadCrumbs />
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <LanguageSelector />
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <AvatarImage src={account?.avatarUrl} />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {name} {surname}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {admin ? (
                  <DropdownItem onClick={toggleNewUserModal}>
                    <i className="fas fa-user-plus" />
                    <span>
                      <span>{t('add user')}</span>
                    </span>
                  </DropdownItem>
                ) : null}
                <DropdownItem onClick={toggleChangePasswordModal}>
                  <i className="fas fa-lock" />
                  <span>
                    <span>{t('change password')}</span>
                  </span>
                </DropdownItem>
                <DropdownItem onClick={() => redirectTo('/settings')}>
                  <i className="fa fa-cog" aria-hidden="true" />
                  <span>{t('settings')}</span>
                </DropdownItem>
                <DropdownItem onClick={() => redirectTo('/logout')}>
                  <i className="ni ni-user-run" />
                  <span>
                    <span>{t('logout')}</span>
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
      {showNewUserModal && <AddUserModal onClose={toggleNewUserModal} />}
      {showChangePasswordModal && (
        <ChangePasswordModal onClose={toggleChangePasswordModal} />
      )}
    </>
  );
}

export default AdminNavbar;
