function nextIndex(currentIndex, nOfItems) {
  if (currentIndex === null || currentIndex === undefined) {
    return null;
  }

  const idx = currentIndex + 1;
  if (idx + 1 >= nOfItems) {
    return null;
  }

  return idx;
}

export default nextIndex;
