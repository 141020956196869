
import React from 'react';
import { Card, CardHeader, Container, Row } from 'reactstrap';
import TasksHeader from 'components/Headers/TasksHeader';
import TasksTable from 'components/Tables/TasksTable';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';

const GET_TASKS = gql`
  query getTasks {
    tasks {
      items {
        id
        serviceName
        status
        progress
        updatedAt
        groupId
        nextGroupId
        jobId
        body
      }
    }
  }
`;

function Tasks() {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_TASKS, {
    fetchPolicy: 'no-cache'
  });

  return (
    <>
      <TasksHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0 text-capitalize">{t('tasks')}</h3>
              </CardHeader>
              {error && (
                <div className="dimmer--loader">
                  <div className="dimmer--loader__content">
                    <h5>{t('failed to load data')}</h5>
                  </div>
                </div>
              )}
              {loading && <Loader title={t('loading data')} />}
              {data && (
                <TasksTable
                  items={data.tasks.items}
                />
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Tasks;
