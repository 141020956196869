import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Row, Col } from 'reactstrap';
import MatchPairCard from 'components/MatchPairCard';
import MatchDetail from 'components/MatchDetail';
import nextIndex from 'utils/nextIndex';
import previousIndex from 'utils/previousIndex';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function MatchGallery({
  matches,
  nOfColumns,
  onCommentSubmit,
  loading,
  error,
  insurerName,
  counterpartyInsurerName
}) {
  const { t } = useTranslation();
  const indexedMatches = matches.map((match, idx) => ({
    ...match,
    index: idx
  }));
  const groupsOfMatches = _.chunk(indexedMatches, nOfColumns);
  const [focusedMatchIndex, setFocusedMatchIndex] = useState(null);

  const handleMatchClick = idx => {
    setFocusedMatchIndex(idx);
  };

  const handleChevronClick = idx => {
    setFocusedMatchIndex(idx);
  };

  const focusedMatch =
    focusedMatchIndex !== null ? matches[focusedMatchIndex] : null;
  const previousMatchIndex = previousIndex(focusedMatchIndex);
  const nextMatchIndex = nextIndex(focusedMatchIndex, matches.length);

  if (loading) {
    return <Loader title={t('loading data')} position="relative" />;
  }

  if (error) {
    return (
      <Loader title={t('failed to load data')} position="relative" error />
    );
  }

  return (
    <div>
      <div>
        {groupsOfMatches.map((groupOfMatches, idx) => (
          <Row className="mt-4" key={idx}>
            {groupOfMatches.map(match => (
              <Col key={match.id}>
                <Row className="mt-4">
                  <Col>
                    <MatchPairCard
                      id={match.id}
                      leftImageUrl={match.queryImage.thumbnailUrl}
                      rightImageUrl={match.matchImage.thumbnailUrl}
                      similarity={match?.similarity}
                      distance={match?.distance}
                      onClick={() => handleMatchClick(match.index)}
                      insurerName={insurerName}
                      counterpartyInsurerName={counterpartyInsurerName}
                      withHighlightedAreas={!idx}
                    />
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        ))}
      </div>
      {focusedMatchIndex !== null && (
        <MatchDetail
          showNavigation
          onLeftClick={
            previousMatchIndex !== null
              ? () => handleChevronClick(previousMatchIndex)
              : null
          }
          onRightClick={
            nextMatchIndex !== null
              ? () => handleChevronClick(nextMatchIndex)
              : null
          }
          matchId={focusedMatch.id}
          onClose={() => setFocusedMatchIndex(null)}
          onCommentSubmit={onCommentSubmit}
          title={`(${focusedMatchIndex + 1}/${matches.length})`}
          postTitle={`${i18next.t('id')}: ${focusedMatch.id}`}
        />
      )}
    </div>
  );
}

MatchGallery.propTypes = {
  matches: PropTypes.arrayOf(PropTypes.object).isRequired,
  nOfColumns: PropTypes.number,
  onCommentSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  insurerName: PropTypes.string.isRequired,
  counterpartyInsurerName: PropTypes.string.isRequired
};

MatchGallery.defaultProps = {
  nOfColumns: 1,
  loading: false,
  error: false
};

export default MatchGallery;
