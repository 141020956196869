import React from 'react';
import { Card, Container, Row, Col, Modal } from 'reactstrap';
import JobDetailHeader from 'components/Headers/JobDetailHeader';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import JobEditor from 'components/JobEditor';

const GET_JOB_QUERY = gql`
  query getJob($id: ID!) {
    job(id: $id) {
      id
      frequency
      freqDatetime
      body {
        extractor
        finder
        matcher
        detector
      }
      params
      active
    }
  }
`;

const GET_TASK_SKELETON_MUTATION = gql`
  mutation getTaskSkeleton($serviceName: String!) {
    taskSkeleton(serviceName: $serviceName) {
      body
      hint
    }
  }
`;

const VALIDATE_JOB_BODY_MUTATION = gql`
  mutation validateJob($validateJobInput: ValidateJobBodyInput!) {
    validateJobBody(input: $validateJobInput)
  }
`;

const UPDATE_JOB_MUTATION = gql`
  mutation updateJob($updateJobInput: UpdateJobInput!) {
    updateJob(input: $updateJobInput) {
      id
      frequency
      freqDatetime
      body {
        extractor
        finder
        matcher
        detector
      }
    }
  }
`;

const RUN_JOB_MUTATION = gql`
  mutation runJob($jobId: ID!) {
    runJob(id: $jobId) {
      id
    }
  }
`;

function stringifyJobBody(job) {
  const jobCopy = JSON.parse(JSON.stringify(job));
  const bodyKeys = Object.keys(jobCopy.body);
  const stringifiedBody = {};
  bodyKeys.map(key => {
    stringifiedBody[key] = JSON.stringify(jobCopy.body[key]);
  });

  delete jobCopy.freqDatetime;
  delete jobCopy.params;
  delete jobCopy.__typename;

  return {
    ...jobCopy,
    body: stringifiedBody
  };
}

function parseJobBody(job) {
  const parsedBody = {};
  const keys = Object.keys(job.body);
  keys.splice(keys.indexOf('__typename'), 1);
  for (const key of keys) {
    parsedBody[key] = JSON.parse(job.body[key]);
  }
  return {
    ...job,
    body: parsedBody
  };
}

function JobDetail() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_JOB_QUERY, {
    variables: { id }
  });
  const [getTaskSkeletonMutation] = useMutation(GET_TASK_SKELETON_MUTATION);
  const [
    validateJobBodyMutation,
    { loading: validateJobBodyLoading }
  ] = useMutation(VALIDATE_JOB_BODY_MUTATION);
  const [updateJobMutation, { loading: updateJobLoading }] = useMutation(
    UPDATE_JOB_MUTATION
  );
  const [runJobMutation, { loading: runJobLoading }] = useMutation(
    RUN_JOB_MUTATION
  );

  const getTaskSkeleton = async taskGroup => {
    const { data: getTaskSkeletonData } = await getTaskSkeletonMutation({
      variables: {
        serviceName: taskGroup
      }
    });
    return JSON.parse(getTaskSkeletonData?.taskSkeleton.body);
  };

  const validateJobBody = async job => {
    const { body } = stringifyJobBody(job);
    const { data: validateJobBodyData, errors } = await validateJobBodyMutation(
      {
        errorPolicy: 'all',
        variables: {
          validateJobInput: {
            jobBody: body
          }
        }
      }
    );
    return { data: validateJobBodyData, errors };
  };

  const updateJob = async job => {
    try {
      const { data: updateJobData } = await updateJobMutation({
        errorPolicy: 'all',
        variables: {
          updateJobInput: stringifyJobBody(job)
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const runJob = async jobId => {
    try {
      await runJobMutation({
        variables: {
          jobId
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) return <h1>Loading data</h1>;
  if (error) return <h1>Error occured</h1>;

  const {
    id: jobId,
    active,
    frequency,
    freqDatetime: frequencyDatetime
  } = data?.job;
  const parsedJob = parseJobBody(data?.job);

  return (
    <>
      <JobDetailHeader
        jobId={jobId}
        active={active}
        frequency={frequency}
        frequencyDatetime={frequencyDatetime}
      />
      <Container className="mt--5" fluid>
        <Row className="mt-4">
          <Col>
            <Row>
              <Col>
                <Card className="p-3">
                  <Row>
                    <Col className="d-flex flex-row flex-wrap justify-content-center">
                      <JobEditor
                        job={parsedJob}
                        getTaskSkeleton={getTaskSkeleton}
                        validateJobBody={validateJobBody}
                        onUpdateJob={updateJob}
                        runJob={runJob}
                        loading={
                          validateJobBodyLoading ||
                          updateJobLoading ||
                          runJobLoading
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default JobDetail;
