import React, { useState } from 'react';
import {
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  UncontrolledTooltip
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { resetPassword } from 'utils/auth';
import { useHistory } from 'react-router-dom';
import { isPasswordStrong } from 'utils/passwordStrength';
import PasswordStrength from './PasswordStrength';

function ForgottenPasswordForm({ email }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      if (values.password !== values.passwordCheck) {
        throw new Error(t('passwords are not same'));
      }
      if (!isPasswordStrong(values.password)) {
        throw new Error(t('password is not strong'));
      }
      await resetPassword(email, values.verificationCode, values.password);
      history.push({
        pathname: '/login'
      });
      toast.success(t('Password changed'));
    } catch (error) {
      toast.error(
        t('Failed to reset password: {{ error.message }}', { error })
      );
    }
    setLoading(false);
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <div className="modal-body">
        <Row>
          <Col>
            <FormGroup>
              <Input
                id="email"
                name="email"
                className="form-control-alternative"
                placeholder={t('email_placeholder')}
                type="email"
                disabled
                onChange={handleInputChange}
                value={email}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                id="verificationCode"
                name="verificationCode"
                className="form-control-alternative"
                placeholder={t('verification code')}
                type="text"
                onChange={handleInputChange}
                value={values.verificationCode}
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                id="password"
                name="password"
                className="form-control-alternative"
                placeholder={t('password_placeholder')}
                type="password"
                onChange={handleInputChange}
                value={values.password}
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input
                id="passwordCheck"
                name="passwordCheck"
                className="form-control-alternative"
                placeholder={t('password_placeholder')}
                type="password"
                onChange={handleInputChange}
                value={values.passwordCheck}
                autoFocus
              />
            </FormGroup>
            <div id="password-strength" className="text-muted font-italic">
              <small>
                {t('password strength')}:{' '}
                <PasswordStrength password={values.password} />{' '}
                <i
                  id="password-strength-tooltip"
                  className="fa fa-info-circle"
                  aria-hidden="true"
                />
              </small>
            </div>
            <UncontrolledTooltip
              delay={0}
              placement="bottom"
              target="password-strength-tooltip"
            >
              <small>{t('password requirements')}</small>
            </UncontrolledTooltip>
          </Col>
        </Row>
      </div>
      <div className="modal-footer justify-content-center">
        <Button
          color="success"
          type="button"
          onClick={handleOnSubmit}
          disabled={loading}
        >
          {t('change password')}
        </Button>
      </div>
    </Form>
  );
}

export default ForgottenPasswordForm;
