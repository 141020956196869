function modelResultsToLabeledBoundingBoxes(modelResults) {
  const labeledBoundigBoxes = [];

  for (const modelResult of modelResults) {
    for (const result of modelResult.results) {
      labeledBoundigBoxes.push({
        model: modelResult.name,
        label: result.label,
        score: result.score,
        box:result.box
      })
    }
  }

  return labeledBoundigBoxes;

}

export default modelResultsToLabeledBoundingBoxes;