import stringToColor from 'utils/stringToColor';
import numberToPercentage from 'utils/numberToPercentage';

function labelScoreToHighlightedArea(ls) {
  return {
    id: `${ls.label}`,
    name: `${ls.label} ${numberToPercentage(ls.score, 0)}%`,
    shape: 'rect',
    relativeCoords:
      ls && ls.box ? [ls.box.x1, ls.box.y1, ls.box.x2, ls.box.y2] : null,
    preFillColor: `rgba(${stringToColor(`${ls.label}:${ls.label}`)}, 0.15)`,
    fillColor: `rgba(${stringToColor(`${ls.label}:${ls.label}`)}, 0.15)`,
    strokeColor: `rgba(57, 255, 20)`,
    lineWidth: 2,
    enabled: true
  };
}

export default labelScoreToHighlightedArea;
