import React from 'react';
import { Card, CardHeader, Table, Progress, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import numberToPercentage from 'utils/numberToPercentage';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import FormattedPercentage from './FormattedPercentage';

function barClassNameByScore(score) {
  switch (true) {
    case score < 50:
      return 'bg-gradient-danger';
    case score > 50 && score < 75:
      return 'bg-gradient-info';
    case score >= 75:
      return 'bg-gradient-success';
    default:
      return 'bg-gradient-info';
  }
}

function ModelResultsTable({
  modelFamilyName,
  icon,
  iconColor,
  modelResults,
  loading,
  error
}) {
  const { t } = useTranslation();

  return (
    <Card
      className="shadow"
      style={{
        height: '100%',
        width: '98%',
        position: 'absolute',
        left: 0,
        top: 0
      }}
    >
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">{t(modelFamilyName)}</h3>
          </div>
          <div
            className={`icon icon-shape ${iconColor} text-white rounded-circle shadow mr-2`}
          >
            <i className={icon} />
          </div>
        </Row>
      </CardHeader>
      {modelResults.length === 0 && (
        <div className="mb-2">
          <h4 className="text-center">{t('No results')}</h4>
        </div>
      )}
      {modelResults.length !== 0 && (
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">{t('model')}</th>
              <th scope="col">{t('label')}</th>
              <th scope="col">{t('score')}</th>
            </tr>
          </thead>
          <tbody>
            {loading && <Loader title={t('loading data')} />}
            {error && <Loader title={t('failed to load data')} error />}
            {!loading &&
              modelResults.map(modelResult => {
                return (
                  <tr key={`${modelResult.label}-${modelResult.score}`}>
                    <th scope="row">{modelResult.model}</th>
                    <th scope="row">{modelResult.label}</th>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">
                          <FormattedPercentage value={modelResult.score} />
                        </span>
                        <div>
                          <Progress
                            max="100"
                            value={numberToPercentage(modelResult.score)}
                            barClassName={barClassNameByScore(
                              numberToPercentage(modelResult.score)
                            )}
                            className="max-width-40"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
    </Card>
  );
}

ModelResultsTable.propTypes = {
  modelFamilyName: PropTypes.string.isRequired,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  iconColor: PropTypes.string,
  modelResults: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
      model: PropTypes.number.isRequired
    })
  ).isRequired,
  error: PropTypes.bool
};

ModelResultsTable.defaultProps = {
  icon: 'fas fa-car',
  iconColor: 'bg-info',
  loading: false,
  error: false
};

export default ModelResultsTable;
