import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const filtersContext = createContext();

function useProvideFilters() {
  const [cachedFilters, setCachedFilters] = useState({});
  const [cachedPaginations, setCachedPaginations] = useState({});

  const cacheFilter = useCallback((filterFor, filter) => {
    setCachedFilters(prevFilter => {
      return {
        ...prevFilter,
        [filterFor]: filter
      };
    });
  }, []);

  const cachePagination = (paginationFor, pagination) => {
    setCachedPaginations({ ...cachedPaginations, [paginationFor]: pagination });
  };

  const getCachedFilter = useCallback(
    filterFor => {
      return cachedFilters[filterFor];
    },
    [cachedFilters]
  );

  const getCachedPagination = paginationFor => {
    return cachedPaginations[paginationFor];
  };

  return {
    cachedFilters,
    cacheFilter,
    cachedPaginations,
    cachePagination,
    getCachedPagination,
    getCachedFilter
  };
}

export function FiltersProvider({ children }) {
  const filters = useProvideFilters();
  return (
    <filtersContext.Provider value={filters}>
      {children}
    </filtersContext.Provider>
  );
}

FiltersProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const useFilters = () => {
  return useContext(filtersContext);
};
