import React from 'react';

function FixedContainer({ children }) {
  return (
    <div
      id="fixed-container--bottom-right"
      className="fixed-container--bottom-right"
    >
      {children}
    </div>
  );
}

export default FixedContainer;
