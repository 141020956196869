import * as _ from 'lodash';

function isObject(o) {
  if (typeof o === 'object' && o !== null) {
    return true
  } else {
    return false
  }
}

function filterChanged(oldFilter, newFilter) {
  // unable to compare - usually when one of variables undefined/null
  if (!isObject(oldFilter) || !isObject(newFilter)){
    return false
  }

  if (!_.isEqual(oldFilter, newFilter)) {
    return true
  }

  return false;
}

export default filterChanged;