import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'reactstrap';

function AddCommentForm({ onSubmit }) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  const handleValueChange = (e) => {
    setValue(e.target.value);
  }

  const handleSubmitClick = async () => {
    try {
      await onSubmit(value);
      setValue("");
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setValue(null);
  }, [])

  return (
    <div>
      <div className="mt-2">
        <Form>
          <Input
            id="add-new-comment"
            placeholder={`${t('write comment here')}...`}
            rows="3"
            type="textarea"
            className="form-control-alternative"
            value={value}
            onChange={handleValueChange}
          />
        </Form>
      </div>
      <div className="d-flex justify-content-end pt-4">
        <Button className="btn-icon btn-3" color="primary" type="button" onClick={handleSubmitClick}>
          <div className="d-flex">
            <span className="btn-inner--text text-capitalize">{t('send')}</span>
          </div>
        </Button>
      </div>
    </div>

  );
}

AddCommentForm.propTypes = {
  onSubmit: PropTypes.func
}

AddCommentForm.defaultProps = {
  onSubmit: () => console.log('onSubmit clicked')
}

export default AddCommentForm;