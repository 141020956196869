import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import pathnameToBreadcrumbs from 'utils/breadcrumbs';
import { useTranslation } from 'react-i18next';
import isLastPathTokenNumber from 'utils/isLastTokenNumber';

const GET_CASE_CLAIM_ID = gql`
  query case($id: ID!) {
    case(id: $id) {
      id
      claimId
    }
  }
`;

function getCaseIdFromBreadcrumb(breadcrumb) {
  const { path } = breadcrumb;
  if (!path) {
    return null;
  }
  const tokens = breadcrumb.path.split('/');
  if (tokens.length === 3) {
    return tokens[2];
  }
  return null;
}

function BreadCrumbs() {
  const location = useLocation();
  const { t } = useTranslation();
  const [breadcrumbs, setBreadcrumbs] = useState(null);

  const [getCaseClaimId, { data: getCaseClaimIdData }] = useLazyQuery(GET_CASE_CLAIM_ID, {
    fetchPolicy: 'cache-only'
  });

  useEffect(() => {
    const addCaseClaimId = async (bcs, bci, caseId) => {
      await getCaseClaimId({ variables: { id: caseId } });
      const claimId =
        getCaseClaimIdData &&
          getCaseClaimIdData.case &&
          getCaseClaimIdData.case.claimId
          ? getCaseClaimIdData.case.claimId
          : null;
      if (claimId) {
        const newBcs = [...bcs];
        newBcs[bci].name = claimId;
        setBreadcrumbs(newBcs);
      }
    };

    // calculate basic breadcrumbs
    const bcs = pathnameToBreadcrumbs(location.pathname);
    setBreadcrumbs(bcs);

    // find caseDetail breadcrumb if exists
    const caseDetailPathRegexp = /\/cases\/[0-9].*/;
    const caseDetailBreadcrumbIndex = bcs.findIndex(bc =>
      bc.path.match(caseDetailPathRegexp)
    );

    // if casedetail breadcrumb exists, then apply caseName
    if (caseDetailBreadcrumbIndex !== -1) {
      const caseId = getCaseIdFromBreadcrumb(bcs[caseDetailBreadcrumbIndex]);
      addCaseClaimId(bcs, caseDetailBreadcrumbIndex, caseId);
    } else {
      setBreadcrumbs(bcs);
    }
  }, [getCaseClaimId, getCaseClaimIdData, location]);

  return (
    <>
      <div className="col-7 col-lg-6 pl-0">
        <nav
          className="d-none d-md-inline-block ml-md-4"
          aria-label="breadcrumb"
        >
          <ol className="breadcrumb breadcrumb-links breadcrumb-dark">
            {breadcrumbs && breadcrumbs.map(breadcrumb => (
              <li key={breadcrumb.path} className="breadcrumb-item">
                <Link to={breadcrumb.path}>
                  {breadcrumb.faIcon && <i className={breadcrumb.faIcon} />}
                  {
                    isLastPathTokenNumber(breadcrumb.path) ? breadcrumb.name : t(breadcrumb.name)
                  }
                </Link>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </>
  );
}

export default BreadCrumbs;
