import React from 'react';
import PropTypes from 'prop-types';

function ImageGalleryItem({ key, imageUrl, width, height, onClick }) {
  return (
    <div
      key={key}
      className="gallery-thumbnail-image position-relative"
      style={{
        backgroundImage: `url("${imageUrl}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width,
        height
      }}
      onClick={onClick}
    />
  );
}

ImageGalleryItem.propTypes = {
  key: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func
};

ImageGalleryItem.defaultProps = {
  width: '160px',
  height: '160px',
  onClick: () => console.log('gallery item clicked')
};

export default ImageGalleryItem;
