import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap';
import { login } from 'utils/auth';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'utils/useAccount';
import { Link, useLocation } from 'react-router-dom';
import qs from 'query-string';

function Login(props) {
  const { search } = useLocation();
  const { t } = useTranslation();
  const { loadAccount } = useAccount();
  const defaultValues = {
    username: '',
    password: '',
    code: '',
  };

  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [challengeName, setChallengeName] = useState(null);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const resetValues = () => {
    setValues(defaultValues);
  };

  const handleOnSubmit = async e => {
    if (e) {
      e.preventDefault();
    }
    const { username, password, code } = values;
    const { history } = props;

    setIsLoading(true);
    try {
      const res = await login(username, password, code);
      if (!res.user && res.challengeName) {
        setChallengeName(res.challengeName)
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      setLoginError(null);
      loadAccount();
      resetValues();
      history.push(qs.parse(search).ref || '/');
      toast.success(t('You have been successfully logged in!'));
    } catch (error) {
      setIsLoading(false);
      setLoginError(error);
    }
  };

  useEffect(() => {
    if (values?.code && values.code.length === 6) {
      handleOnSubmit();
    }
  }, [values]);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {loginError && (
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-circle-08" />
                </span>{' '}
                {t('failed to login')} {loginError.message}
              </UncontrolledAlert>
            )}

            <div className="text-center text-muted mb-4">
              <small>{t('sign in with credentials')}</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="username"
                    name="username"
                    placeholder={t('email_placeholder')}
                    type="text"
                    disabled={challengeName}
                    value={values.username}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="password"
                    name="password"
                    placeholder={t('password_placeholder')}
                    type="password"
                    autoComplete="new-password"
                    disabled={challengeName}
                    value={values.password}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>
              {
                challengeName && (
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-mobile" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="code"
                        name="code"
                        type="text"
                        value={values.code}
                        onChange={handleInputChange}
                        placeholder={t('verification_code_placeholder')}
                      />
                    </InputGroup>
                  </FormGroup>
                )
              }
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  onClick={e => handleOnSubmit(e)}
                  disabled={isLoading}
                >
                  {isLoading ? t('logging in') : t('log in')}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link
              to={{
                pathname: '/forgotten-password'
              }}
            >
              <small className="text-light">{t('forgot password')}?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default Login;
