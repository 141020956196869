import React, { useState } from 'react';
import { Button } from 'reactstrap';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CREATE_NEW_JOB = gql`
  mutation createNewJob {
    newJob {
      id
      frequency
      body {
        extractor
        finder
        matcher
        detector
      }
      params
      active
    }
  }
`;

function AddJobButton() {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [createNewJobMutation] = useMutation(CREATE_NEW_JOB);

  const handleButtonClick = async () => {
    setIsLoading(true);

    try {
      const { data } = await createNewJobMutation();
      const newJobId = data?.newJob?.id;
      setIsLoading(false);
      history.push(`/jobs/${newJobId}`);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Button color="success" onClick={handleButtonClick} disabled={isLoading}>
      {t('new job')}
    </Button>
  );
}

export default AddJobButton;
