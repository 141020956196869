import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';

function ImagePreview({
  imageUrl,
  showNavigation,
  onLeftClick,
  onRightClick,
  loading,
  error,
  errorMinHeight,
  ...props
}) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  if (loading) {
    return (
      <Skeleton height={400}/>
    )
  }

  if (error) {
    return (
      <div className="image-preview__error_placeholder" style={{
        minHeight: errorMinHeight
      }}>
        <i class="far fa-image"></i>
      </div>
    )
  }



  return (
    <div
      className={classnames('image-preview', {
        pulse: !imageLoaded
      })}
      {...props}
    >
      <img
        className={classnames('image-preview__image', {
          'd-none': !imageLoaded,
          'fade-in': imageLoaded
        })}
        src={imageUrl}
        onLoad={() => handleImageLoaded()}
      />
      {showNavigation && (
        <div className="image-preview__navigation">
          {onLeftClick && (
            <div
              className="image-preview__navigation__left"
              onClick={e => {
                e.stopPropagation();
                onLeftClick();
              }}
            >
              <i className="fas fa-chevron-left image-preview__navigation__chevron" />
            </div>
          )}
          {onRightClick && (
            <div
              className="image-preview__navigation__right"
              onClick={e => {
                e.stopPropagation();
                onRightClick();
              }}
            >
              <i className="fas fa-chevron-right image-preview__navigation__chevron" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

ImagePreview.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  errorMinHeight: PropTypes.string
};

ImagePreview.defaultProps = {
  errorMinHeight: '400px'
};

export default ImagePreview;
