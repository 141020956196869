import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import TaskEditor from 'components/TaskEditor';
import { useTranslation } from 'react-i18next';

function TaskGroup({ taskGroup: { name, tasks }, addTask, onTaskChange, removeTask }) {
  const { t } = useTranslation();

  const handleAddNewTaskClick = async () => {
    await addTask(name);
  }

  return (
    <Row className="w-100 mb-4">
      <Col>
        <Row>
          <Col>
            <h1 className="display-3 mt-4 mb-4 ml-1 text-capitalize">{name}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              tasks?.length < 1 && <h2>{t('no task defined', { taskType: name })}</h2>
            }
            {
              tasks.map((jsonBody) => (
                <Row key={jsonBody.id} >
                  <Col>
                    <TaskEditor id={jsonBody.id} jsonBody={jsonBody} onChange={onTaskChange} groupName={name} onRemoveTask={removeTask} />
                  </Col>
                </Row>
              ))
            }
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-row">
            <Button
              className="mt-2"
              color="info"
              onClick={handleAddNewTaskClick}
              size="sm"
            >
              {t('add new task', { taskType: name })}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row >
  );
}

TaskGroup.propTypes = {
  taskGroup: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.shape({
      node_id: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      profileName: PropTypes.string.isRequired,
      productFamily: PropTypes.string.isRequired,
    })).isRequired
  }).isRequired
};

export default TaskGroup;