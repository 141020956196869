import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function badgeColor(status) {
  switch (status) {
    case 'NOMFA':
      return 'secondary'
    case 'PENDING':
      return 'warning'
    case 'ACTIVE':
      return 'success'
    default:
      return 'secondary'
  }
}

function MFAStatusBadge({ status }) {
  const { t } = useTranslation();

  return (
    <Badge color={badgeColor(status)}>{t(`mfa_status_${status.toLowerCase()}`)}</Badge>
  );
}

MFAStatusBadge.propTypes = {
  status: PropTypes.string
};

MFAStatusBadge.defaultProps = {
  status: ""
}

export default MFAStatusBadge;