import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import SuspiciousCasesModal from 'components/SuspiciousCasesModal';

const GET_SUSPICIOUS_CASES = gql`
  query suspiciousCases(
    $productFamily: String!
    $filter: SuspiciousCaseFilter
    $pagination: Pagination
  ) {
    suspiciousCases(
      productFamily: $productFamily
      filter: $filter
      pagination: $pagination
    ) {
      items {
        id
      }
    }
  }
`;

function FoundInDetectionCard({ claimId, productFamily, parentLoading }) {
  const { t } = useTranslation();
  const [showSuspiciousCases, setShowSuspiciousCases] = useState(false);
  const [getSuspiciousCases, { data, loading }] = useLazyQuery(
    GET_SUSPICIOUS_CASES
  );

  // when component rendered and claimId defined, check if at least one suspiciousCase available
  useEffect(() => {
    if (claimId && productFamily && !parentLoading) {
      getSuspiciousCases({
        variables: {
          filter: {
            claimId
          },
          productFamily,
          pagination: {
            limit: 1
          }
        }
      });
    }
  }, [claimId, productFamily, parentLoading, getSuspiciousCases]);

  return (
    <>
      <Card className="card-stats mb-4 mb-xl-0">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle tag="h5" className=" text-muted mb-0">
                <span>{t('found in detection')}</span>
              </CardTitle>
              {parentLoading || loading ? (
                <Skeleton />
              ) : (
                <>
                  {data?.suspiciousCases?.items?.length > 0 ? (
                    <span
                      className="show-comments-text"
                      onClick={() => setShowSuspiciousCases(true)}
                    >
                      {t('show comments')}
                    </span>
                  ) : (
                    <span className="h5">{t('nothing to display')}</span>
                  )}
                </>
              )}
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                <i className="fas fa-search" />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {showSuspiciousCases && (
        <SuspiciousCasesModal
          onClose={() => setShowSuspiciousCases(false)}
          productFamily={productFamily}
          filter={{
            claimId
          }}
        />
      )}
    </>
  );
}

FoundInDetectionCard.propTypes = {
  claimId: PropTypes.string,
  productFamily: PropTypes.string,
  parentLoading: PropTypes.bool
};

FoundInDetectionCard.defaultProps = {
  claimId: null,
  productFamily: 'cars',
  parentLoading: false
};

export default FoundInDetectionCard;
