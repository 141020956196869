import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function GenerateReport({ reportUrl, generateReport, generateReportLoading }) {
  const { t } = useTranslation();

  return (
    <div className="pb-3 pl-3">
      <Button
        color="success"
        type="button"
        disabled={generateReportLoading}
        className={classnames('btn-icon btn-3', {
          'disabled-link': generateReportLoading
        })}
        onClick={generateReport}
      >
        <span className="btn-inner--text">
          {!generateReportLoading ? t('generate_report') : t('generating')}{' '}
        </span>
      </Button>
      <Link to={{
        pathname: reportUrl
      }} target="_blank" download className="ml-2">
        <Button
          color="info"
          type="button"
          disabled={!reportUrl}
          className={classnames('btn-icon btn-3', {
            'disabled-link': !reportUrl
          })}
        >
          <span className="btn-inner--text">{t('download_report')}</span>
        </Button>
      </Link>
    </div>
  );
}

GenerateReport.propTypes = {
  reportUrl: PropTypes.string,
  generateReport: PropTypes.func.isRequired,
  generateReportLoading: PropTypes.bool
};

GenerateReport.defaultProps = {
  reportUrl: null,
  generateReportLoading: false
};

export default GenerateReport;
