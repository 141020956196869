import React from 'react';
import { Card, Row, Col, CardBody, Collapse, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import FormattedTimestamp from './FormattedTimestamp';

function CaseMedallion({ id, claimId, damageAmount, reportedAt, accidentAt, logoUrl, extraAttributes, loading, error, metadata, subjectId, insuranceType }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Card className="card-profile shadow">
      {
        error && <Loader title={t('failed to load data')} error />
      }
      {
        loading && <Loader title={t('loading data')} />
      }
      <CardBody className="pt-0 pt-md-4 pb-1">
        {
          logoUrl && (
            <Row className="case-medallion__logo mr-0 ml-0">
              <Col className="d-flex justify-content-center">
                <div className="medallion-logo">
                  <img src={logoUrl} alt="logo" />
                </div>
              </Col>
            </Row>
          )
        }
        <Row>
          <div className="col p-0">
            <div className="card-profile-stats d-flex justify-content-center p-0">
              {/* <div>
                <Link to={{
                  pathname: `/cases/${id}`
                }}>
                  <span className="h5 d-inline mb-0">{claimId}</span>
                </Link>
                <Link to={{
                  pathname: `/cases/${id}`,
                }}
                  target="_blank">
                  <span className="ml-2"><i class="fas fa-external-link-alt"></i></span>
                </Link>
                <span className="description d-block">{t('case id')}</span>
              </div> */}
              <div className="mr-0 pl-2 pr-2">
                <span className="h5 d-block mb-0">
                  {claimId || t('n/a')}
                </span>
                <span className="h6 text-lowercase text-muted">{t('case id')}</span>
              </div>
              <div className="mr-0 pl-2 pr-2">
                <span className="h5 d-block mb-0">
                  {subjectId || t('n/a')}
                </span>
                <span className="h6 text-lowercase text-muted text-uppercase">{t('vin')}</span>
              </div>
              <div className="mr-0 pl-2 pr-2">
                <span className="h5 d-block mb-0">
                  {insuranceType || t('n/a')}
                </span>
                <span className="h6 text-lowercase text-muted">{t('risk_type')}</span>
              </div>
              <div className="mr-0 pl-2 pr-2">
                <span className="h5 d-block mb-0">
                <FormattedTimestamp timestamp={reportedAt} format="DD.MM.YYYY"/>
                </span>
                <span className="h6 text-lowercase text-muted">{t('reported_at')}</span>
              </div>
              <div className="mr-0 pl-2 pr-2">
                <span className="h5 d-block mb-0">
                <FormattedTimestamp timestamp={accidentAt} format="DD.MM.YYYY"/>
                </span>
                <span className="h6 text-lowercase text-muted">{t('accident_at')}</span>
              </div>
            </div>
          </div>
        </Row>
        {
          extraAttributes && extraAttributes.length > 0 && (
            <Row className="mt-4">
              <Col>
                <div className="text-center">
                  <Collapse isOpen={isOpen}>
                    <Table className="align-items-left table-flush mb-4">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" className="text-left text-capitalize">{t('name')}</th>
                          <th scope="col" className="text-left text-capitalize">{t('value')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {extraAttributes.map((extraAttribute, i) => {
                          return (
                            <tr key={`${extraAttribute.name}-${extraAttribute.value}`}>
                              <td>
                                <div className="d-flex align-items-left">
                                  <span >{extraAttribute.name}</span>
                                </div>
                              </td>
                              <td className="d-flex align-items-left">
                                {extraAttribute.value}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Collapse>
                  <p
                    className="card__collapse__toggle text-center"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {!isOpen ? t('show more') : t('show less')}
                  </p>
                </div>
              </Col>
            </Row>
          )
        }
          {
          metadata && metadata.length > 0 && (
            <Row className="mt-4">
              <Col>
                <div className="text-center">
                  <Collapse isOpen={isOpen}>
                    <hr/>
                    <h4 className="text-capitalize">{t('metadata')}</h4>
                    <Table className="align-items-left table-flush mb-4">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" className="text-left text-capitalize">{t('name')}</th>
                          <th scope="col" className="text-left text-capitalize">{t('value')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {metadata.map((m, i) => {
                          return (
                            <tr key={`${m.name}-${m.value}`}>
                              <td>
                                <div className="d-flex align-items-left">
                                  <span >{m.name}</span>
                                </div>
                              </td>
                              <td className="d-flex align-items-left">
                                {m.value}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Collapse>
                  <p
                    className="card__collapse__toggle text-center"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {!isOpen ? t('show metadata') : t('hide metadata')}
                  </p>
                </div>
              </Col>
            </Row>
          )
        }
      </CardBody>
    </Card >
  );
}

CaseMedallion.propTypes = {
  id: PropTypes.string.isRequired,
  claimId: PropTypes.string.isRequired,
  damageAmount: PropTypes.string.isRequired,
  reportedAt: PropTypes.string.isRequired,
  accidentAt: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  extraAttributes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  loading: PropTypes.bool,
  error: PropTypes.bool,
  metadata: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  subjectId: PropTypes.string,
  insuranceType: PropTypes.string
};

CaseMedallion.defaultProps = {
  loading: false,
  error: false,
  subjectId: 'n/a',
  insuranceType: 'n/a'
}

export default CaseMedallion;
