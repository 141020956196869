import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import FinderDetailHeader from 'components/Headers/FinderDetailHeader';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import FinderQueryWizard from 'components/FinderQueryWizard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function isValidBoundingBoxCheck(bb) {
  if (!bb) {
    return false;
  }
  if (!bb.x1 || !bb.y1 || !bb.x2 || !bb.y2) {
    return false;
  }
  if (bb.x2 <= bb.x1 || bb.y2 <= bb.y1) {
    return false;
  }
  return true;
}

const GET_FINDER_QUERY = gql`
  query finderQuery($id: ID!) {
    finderQuery(id: $id) {
      id
      name
      status
      productFamily
      definition {
        id
        image {
          imageUrl
        }
        queryBox {
          label
          score
          box {
            x1
            y1
            x2
            y2
          }
        }
      }
      results {
        id
        matchCase {
          claimId
        }
        similarity
        matchImage {
          imageUrl
        }
      }
    }
  }
`;

const UPLOAD_FILE_TO_FINDER_QEURY = gql`
  mutation uploadFileToFinderQuery($finderQueryId: String!, $file: Upload!) {
    uploadFileToFinderQuery(finderQueryId: $finderQueryId, file: $file) {
      id
      name
      status
      definition {
        id
        image {
          imageUrl
        }
        queryBox {
          label
          score
        }
      }
      results {
        id
        matchCase {
          id
          claimId
        }
        similarity
        matchImage {
          imageUrl
        }
      }
    }
  }
`;

const UPDATE_BOUNDING_BOX = gql`
  mutation updateBoundingBox(
    $id: ID!
    $label: String!
    $boundingBox: InputBox!
  ) {
    updateFinderQuery(
      input: { id: $id, labelBox: { label: $label, box: $boundingBox } }
    ) {
      id
      productFamily
      definition {
        id
        queryBox {
          label
          box {
            x1
            x2
            y1
            y2
          }
        }
      }
    }
  }
`;

const RUN_FINDER_QUERY = gql`
  mutation runQuery($id: ID!) {
    runFinderQuery(id: $id) {
      id
      status
    }
  }
`;

const FinderEdit = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [uploadFileToFinderQuery] = useMutation(UPLOAD_FILE_TO_FINDER_QEURY);
  const [updateBoundingBox] = useMutation(UPDATE_BOUNDING_BOX);
  const [runFinderQuery] = useMutation(RUN_FINDER_QUERY);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [boundingBox, setBoundingBox] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const { loading, error, data, refetch } = useQuery(GET_FINDER_QUERY, {
    variables: { id },
    onCompleted: newData => {
      const box =
        newData &&
        newData.finderQuery &&
        newData.finderQuery.definition &&
        newData.finderQuery.definition.queryBox
          ? newData.finderQuery.definition.queryBox.box
          : null;
      setBoundingBox(box);
      setSelectedLabel(
        newData &&
          newData.finderQuery &&
          newData.finderQuery.definition &&
          newData.finderQuery.definition.queryBox
          ? newData.finderQuery.definition.queryBox.label
          : null
      );
    }
  });
  const [boundingBoxChanged, setBoundingBoxChanged] = useState(false);

  const onFileToUploadChangeHandler = e => {
    setFileToUpload(e.target.files[0]);
  };

  const onFileToUploadClickHandler = async () => {
    try {
      await uploadFileToFinderQuery({
        variables: {
          finderQueryId: id,
          file: fileToUpload
        }
      });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  const onBoundingBoxChangeHandler = newBoundingBox => {
    setBoundingBoxChanged(true);
    setBoundingBox(newBoundingBox);
  };

  const onBoundingBoxSaveHandler = async () => {
    try {
      await updateBoundingBox({
        variables: {
          id,
          boundingBox,
          label: selectedLabel
        }
      });
      refetch();
      setBoundingBoxChanged(false);
    } catch (err) {
      console.error(err);
    }
  };

  const onRunFinderQueryClickHandler = async () => {
    try {
      await runFinderQuery({
        variables: {
          id
        }
      });
      history.push(`/finder/${id}`);
      toast.success(t('Finder query is running!'));
    } catch (err) {
      console.error(err);
      toast.error(`${err.message}`);
    }
  };

  if (loading) return <h1>Loading data</h1>;
  if (error) return <h1>Error occured</h1>;

  return (
    <>
      <FinderDetailHeader />
      <Container className="mt--5" fluid>
        <Row>
          <Col className="col-xl-8 offset-xl-2">
            {data && (
              <FinderQueryWizard
                imageUrl={
                  data &&
                  data.finderQuery &&
                  data.finderQuery.definition &&
                  data.finderQuery.definition.image
                    ? data.finderQuery.definition.image.imageUrl
                    : null
                }
                boundingBox={boundingBox}
                onBoundingBoxChange={onBoundingBoxChangeHandler}
                onBoundingBoxSave={onBoundingBoxSaveHandler}
                boundingBoxChanged={boundingBoxChanged}
                onFileToUploadChange={onFileToUploadChangeHandler}
                onFileToUploadClick={onFileToUploadClickHandler}
                onRunFinderQueryClick={onRunFinderQueryClickHandler}
                label={selectedLabel}
                setLabel={setSelectedLabel}
                isValidBoundingBoxSaved={
                  data &&
                  data.finderQuery &&
                  data.finderQuery.definition &&
                  data.finderQuery.definition.queryBox &&
                  data.finderQuery.definition.queryBox.box
                    ? isValidBoundingBoxCheck(
                        data.finderQuery.definition.queryBox.box
                      )
                    : false
                }
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FinderEdit;
