import React from 'react';
import PropTypes from 'prop-types';
import ImageMapper from 'components/ImageMapper';
import uuid from 'uuid';
import * as _ from 'lodash';
import calculateAbsoluteBoundingBoxCoordinates from 'utils/calculateAbsoluteBoundingBoxCoordinates';
import { Row, Col, Button } from 'reactstrap';
import relativeArea from 'utils/relativeArea';
import DimmerArea from './DimmerArea';
import CroppedImage from './CroppedImage';
import i18next from 'i18next';

function isValidCoords(coords) {
  if (!coords || coords.length !== 4) {
    return false;
  }

  for (const coord of coords) {
    if (isNaN(coord)) {
      return false;
    }
  }

  return true;
}

// ImageWithHighlightedArea is wrapper for ImageMapper
//
// Component ImageWrapper needs prop `width` as absolute value, to get this value and stay responsive,
// first we need to render `ImageWithHighlightedArea` component to full width of its parent element. Then
// at `componentDidMount` we can access its width and pass it to `ImageMapper`.
class ImageWithHighlightedArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dimmerOpen: false,
      focusedAreaIndex: undefined,
      hoveredArea: null,
      contrast: 1
    };
  }

  componentDidMount() {
    // after rendered, set wrapper dimensions
    this.setState({
      wrapperDimensions: {
        width: this.wrapperElement.offsetWidth,
        height: this.wrapperElement.offsetHeight
      }
    });
  }

  toggleDimmer = e => {
    if (!this.props.clickable) {
      return
    }
    this.setState(state => {
      return {
        ...state,
        dimmerOpen: !state.dimmerOpen,
        focusedAreaIndex: e.index,
        imageWidth: this.imageElement.img.clientWidth,
        imageHeight: this.imageElement.img.clientHeight
      };
    });
  };

  handleContrastChange  = (e) => {
    this.setState({
      contrast: e.target.value
    })
  }

  resetContrast = () => {
    this.setState({
      contrast: 1
    })
  }

  render() {
    const {
      imageUrl,
      originalImageWidth,
      originalImageHeight,
      highlightedAreas
    } = this.props;
    const { wrapperDimensions } = this.state;
    const imageMapperWidth = wrapperDimensions ? wrapperDimensions.width : 100;
    const { dimmerOpen } = this.state;
    let highlightedAreasWithAbsoluteCoords = highlightedAreas
      ? highlightedAreas.map(highlightedArea => {
          return {
            ...highlightedArea,
            absoluteCoords: highlightedArea.relativeCoords
              ? calculateAbsoluteBoundingBoxCoordinates(
                  highlightedArea.relativeCoords,
                  originalImageWidth,
                  originalImageHeight
                )
              : null,
            relativeArea: relativeArea(highlightedArea.relativeCoords)
          };
        })
      : [];
    highlightedAreasWithAbsoluteCoords = _.orderBy(
      highlightedAreasWithAbsoluteCoords,
      ['relativeArea'],
      ['asc']
    );

    const map = {
      name: uuid.v4(),
      areas: highlightedAreasWithAbsoluteCoords.map((area, idx) => ({
        name: area.name,
        index: idx,
        shape: 'rect',
        coords: isValidCoords(area.absoluteCoords) ? area.absoluteCoords : [],
        preFillColor: area.preFillColor,
        fillColor: area.fillColor,
        strokeColor: area.strokeColor,
        lineWidth: area.lineWidth
      }))
    };

    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          position: 'relative'
        }}
        ref={el => {
          this.wrapperElement = el;
        }}
      >
        {
          this.props.showControls && (
            <div
              class="image-with-highlighted-area__controls"
              >
                <div>
                <label for="contrast" className="h5 mb-0">{i18next.t('contrast')}</label>
                </div>
                <div class="image-with-highlighted-area__controls__slider_wrapper">
                <input
                  type="range"
                  min="0"
                  max="6"
                  step="0.5"
                  onChange={this.handleContrastChange}
                  value={this.state.contrast}
                  className="slider"
                  id="contrast"
                />
                <Button size="sm" className="contrast-reset-button" onClick={() => this.resetContrast()}>X</Button>
                </div>

              </div>
          )
        }
        {wrapperDimensions && (
          <>
            {
              <ImageMapper
                shouldComponentUpdate={() => {
                  return true;
                }}
                active
                src={imageUrl}
                map={map}
                width={imageMapperWidth}
                imgWidth={originalImageWidth}
                onClick={this.toggleDimmer}
                ref={el => {
                  this.imageElement = el;
                }}
                onMouseEnter={area =>
                  this.setState({
                    hoveredArea: area
                  })
                }
                onMouseLeave={() =>
                  this.setState({
                    hoveredArea: null
                  })
                }
                imageStyle={{
                  filter: `contrast(${this.state.contrast})`
                }}
              />
            }
            {this.state.hoveredArea && this.state.hoveredArea.name && (
              <div
                className="image-with-highlighted-area__hovered-area d-flex align-items-center justify-content-center"
                style={{
                  position: 'absolute',
                  left: this.state.hoveredArea.scaledCoords[0],
                  top: this.state.hoveredArea.scaledCoords[1],
                  width:
                    this.state.hoveredArea.scaledCoords[2] -
                    this.state.hoveredArea.scaledCoords[0],
                  height:
                    this.state.hoveredArea.scaledCoords[3] -
                    this.state.hoveredArea.scaledCoords[1]
                }}
              >
                <h1>{this.state.hoveredArea?.name}</h1>
              </div>
            )}
            {dimmerOpen &&
              highlightedAreasWithAbsoluteCoords[
                this.state.focusedAreaIndex
              ] && (
                <DimmerArea onAreaClick={this.toggleDimmer} noPadding>
                  <Row className="h-100 d-flex align-items-center">
                    <Col className="d-flex justify-content-center zoom-out">
                      <CroppedImage
                        contrast={this.state.contrast}
                        maxWidth={this.state.imageWidth}
                        maxHeight={this.state.imageHeight}
                        imageUrl={imageUrl}
                        originalImageWidth={originalImageWidth}
                        originalImageHeight={originalImageHeight}
                        croppedArea={{
                          x1:
                            highlightedAreasWithAbsoluteCoords[
                              this.state.focusedAreaIndex
                            ].relativeCoords[0],
                          y1:
                            highlightedAreasWithAbsoluteCoords[
                              this.state.focusedAreaIndex
                            ].relativeCoords[1],
                          x2:
                            highlightedAreasWithAbsoluteCoords[
                              this.state.focusedAreaIndex
                            ].relativeCoords[2],
                          y2:
                            highlightedAreasWithAbsoluteCoords[
                              this.state.focusedAreaIndex
                            ].relativeCoords[3]
                        }}
                      />
                    </Col>
                  </Row>
                </DimmerArea>
              )}
          </>
        )}
      </div>
    );
  }
}

ImageWithHighlightedArea.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  clickable: PropTypes.bool,
  originalImageWidth: PropTypes.number.isRequired,
  originalImageHeight: PropTypes.number.isRequired,
  highlightedAreas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      shape: PropTypes.oneOf(['rect']).isRequired,
      relativeCoords: PropTypes.arrayOf(PropTypes.number).isRequired
    }).isRequired
  ),
  showControls: PropTypes.bool
};

ImageWithHighlightedArea.defaultProps = {
  clickable: true,
  showControls: false,
};

export default ImageWithHighlightedArea;
