import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { isAuthenticated } from 'utils/auth';

const accountContext = createContext();

const GET_ACCOUNT = gql`
  query getAccount {
    me {
      id
      username
      name
      role
      surname
      thumbnailUrl
      avatarUrl
      mfaStatus
      settings {
        timezone
        devMode
      }
    }
  }
`;

const SETUP_TOTP_MUTATION = gql`
  mutation setupTOTP {
    setupTOTP
  }
`;

const VERIFY_TOTP_MUTATION = gql`
  mutation verifyTOTP($passcode: String!) {
    verifyTOTP(passcode: $passcode)
  }
`;

const DISABLE_TOTP_MUTATION = gql`
  mutation disableTOTP {
    disableTOTP
  }
`;

function useProvideAccount() {
  const client = useApolloClient();
  const [account, setAccount] = useState({});
  const [setupTOTPMutation] = useMutation(SETUP_TOTP_MUTATION);
  const [verifyTOTPMutation] = useMutation(VERIFY_TOTP_MUTATION);
  const [disableTOTPMutation] = useMutation(DISABLE_TOTP_MUTATION);

  const loadAccount = useCallback(async () => {
    try {
      const res = await client.query({
        query: GET_ACCOUNT,
        fetchPolicy: 'no-cache'
      });
      setAccount(res.data.me);
    } catch (error) {
      console.error(error);
    }
  }, [client]);

  const devMode = useCallback(() => {
    return account?.settings?.devMode;
  }, [account]);

  const setupTOTP = useCallback(async () => {
    const { data } = await setupTOTPMutation();
    loadAccount();
    return data?.setupTOTP;
  }, [loadAccount, setupTOTPMutation]);

  const verifyTOTP = useCallback(
    async passcode => {
      await verifyTOTPMutation({
        variables: {
          passcode
        }
      });
      loadAccount();
    },
    [loadAccount, verifyTOTPMutation]
  );

  const disableTOTP = useCallback(async () => {
    await disableTOTPMutation();
    loadAccount();
  }, [disableTOTPMutation, loadAccount]);

  useEffect(() => {
    if (isAuthenticated()) {
      loadAccount();
    }
  }, [client, loadAccount]);

  return {
    account,
    loadAccount,
    devMode,
    setupTOTP,
    verifyTOTP,
    disableTOTP
  };
}

export function AccountProvider({ children }) {
  const account = useProvideAccount();
  return (
    <accountContext.Provider value={account}>
      {children}
    </accountContext.Provider>
  );
}

AccountProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export const useAccount = () => {
  return useContext(accountContext);
};
