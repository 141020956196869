import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';


function AggregationModal({ aggregation, onClose }) {
  const { t } = useTranslation();

  return (
    <Modal
      className="modal-dialog-centered modal--fullscreen max-width-90vw"
      isOpen
      toggle={onClose}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {t('aggregation')}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden>×</span>
        </button>
      </div>
      <div className="modal-body modal-max-width modal-80-width">
        <Row>
          <Col>
            <Table className="align-items-center table-flush" style={{
            }}>
              <thead className="thead-light">
                <tr>
                  <th scope="col" className='th--sticky-top'>{t('name')}</th>
                  <th scope="col" className='th--sticky-top'>{t('value')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  aggregation && aggregation.length > 0 && aggregation.map(({ name, value }) => (
                    <tr key={name}>
                      <td>
                        {name}
                      </td>
                      <td>
                        {value}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <div className="modal-footer" />
    </Modal>
  );
}

AggregationModal.propTypes = {
  aggregation: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired
}

export default AggregationModal;